import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Button,
Table,
TableHead,
TableBody,
TableRow,
TableCell,
Typography,
Input,
Hidden,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import SelectPass from '../Elements/select_pass';
import formatExpiration from '../../../utils/format_expiration';
import getPassData from '../Utils/getPassData';
import createNewFormattedExpiration from '../../../utils/create_new_formatted_expiration';
import useHasScheduledPass from '../Hooks/use_has_scheduled_pass';

// Date and Time
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function SchedulePass(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPass, setSelectedPass] = useState('');

  // Display
  const [startDate, setStartDate] = useState(dayjs(Date.now()).local().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(
    dayjs(Date.now()).add(1, 'minute').local().format('HH:mm')
  );
  const [beginsDate, setBeginsDate] = useState(new Date());
  const [endsDate, setEndsDate] = useState('-');
  const [maxViewers, setMaxViewers] = useState('-');

  // Current Pass
  const [selectedPassData, setSelectedPassData] = useState();
  const hasScheduledPass = useHasScheduledPass(props.scheduledPasses);

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});
  const [overlaps, setOverlaps] = useState(false);

  // Effects

  // Update selected pass and default start time
  useEffect(() => {
    let newStart = dayjs();

    if (props.activePasses[0] && props.activePasses[0].expires_on) {
      newStart = dayjs(props.activePasses[0].expires_on);
    }

    // Start after last scheduled pass
    if (selectedPassData && props.scheduledPasses && props.scheduledPasses.length > 0) {
      let lastScheduled = props.scheduledPasses
        .filter(pass => (
          pass._id !== selectedPassData._id &&
          dayjs(pass.expires_on).isAfter(newStart)
        ))
        .reduce((p,c) => (
          dayjs(c.expires_on).isAfter(dayjs(p.expires_on)) ?
          c : p
        ), {expires_on: newStart.toDate()});
      if (dayjs(lastScheduled.expires_on).isAfter(newStart)) {
        newStart = dayjs(lastScheduled.expires_on);
      }
    }

    setStartDate(newStart.local().format('YYYY-MM-DD'));
    setStartTime(newStart.local().format('HH:mm'));

  }, [props.activePasses, selectedPassData]);

  // Update selected pass data
  useEffect(() => {
    setSelectedPassData(getPassData(props.availablePasses, selectedPass))
  }, [selectedPass]);

  // Update schedule start time and date
  useEffect(() => {
    // Ensure we have pass data to work with
    if (selectedPassData) {

      let begins = dayjs(startDate + 'T' + startTime, 'YYYY-MM-DDTHH:mm');

      // Ensure date is in the future
      if (dayjs(begins).isBefore(dayjs().subtract(1, 'minute'))) {
        if (props.activePasses[0]?.expires_on) { // Default to active pass expiration
          begins = dayjs(props.activePasses[0].expires_on);
        } else { // Fall back to current time
          begins = dayjs(Date.now()).add(1, 'minute');
        }

        setStartDate(begins.local().format('YYYY-MM-DD'));
        setStartTime(begins.local().format('HH:mm'));
        enqueueSnackbar('Scheduled date and time must be in the future.', {variant:'warning'})
      }
      setBeginsDate(begins.toDate());
    }

  }, [startDate, startTime, endsDate, hasScheduledPass]);

  // Update new pass starting date and time
  useEffect(() => {
    if (
      selectedPassData &&
      selectedPassData.max_allowed_users &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units
    ) {
      setMaxViewers(selectedPassData.max_allowed_users);
      let expires = newExpiration();
      setEndsDate(expires);

      let newDisplayData = {}
      newDisplayData[selectedPass] = {
        pass: selectedPassData.pass_type,
        Viewers: selectedPassData.max_allowed_users,
        willBegin: formatExpiration(beginsDate),
        willExpire: expires,
      }
      setDisplayData(newDisplayData);
      setActionData({
        url:'/api/access_passes/',
        postData: {
          pass_id: selectedPass,
          to_schedule: true,
          begins_on: beginsDate
        },
        actionText: 'schedule pass'
      })
    }
  }, [beginsDate, selectedPassData]);

  // Actions
  const newExpiration = () => {
    // let begins = beginsDate - Date.now();
    let begins = dayjs(beginsDate).diff(dayjs(), 'ms')
    if (Math.sign(begins) === -1) { begins = 0 }
    return createNewFormattedExpiration(selectedPassData, begins);
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        {/* Desktop */}
        <Hidden mdDown>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Pass to Schedule
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Will Begin
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              At
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Will Expire
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              Activate
            </Grid>

          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <SelectPass
                passes={props.availablePasses}
                cssClasses={props.cssClasses}
                selectedPass={selectedPass}
                setSelectedPass={setSelectedPass}
              />
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <form>
                <Input
                  type="date"
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                  label="Choose Start Date"
                  required
                  color="primary"
                />
              </form>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <form>
                <Input
                  type="time"
                  value={startTime}
                  onChange={e => setStartTime(e.target.value)}
                  label="Choose Start Time"
                  required
                  color="primary"
                />
              </form>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>{endsDate}</Typography>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Typography>{maxViewers}</Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
              >
                Schedule
              </Button>
            </Grid>

          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Pass to Schedule
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <SelectPass
                passes={props.availablePasses}
                cssClasses={props.cssClasses}
                selectedPass={selectedPass}
                setSelectedPass={setSelectedPass}
              />
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Will Begin
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <form>
                <Input
                  type="date"
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                  label="Choose Start Date"
                  required
                  color="primary"
                />
              </form>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              At
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <form>
                <Input
                  type="time"
                  value={startTime}
                  onChange={e => setStartTime(e.target.value)}
                  label="Choose Start Time"
                  required
                  color="primary"
                />
              </form>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Will Expire
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{endsDate}</Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>{maxViewers}</Typography>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Activate
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
              >
                Schedule
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        {/* Error messages */}
        {
          overlaps &&
          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Starting this pass at this time overlaps a currently active pass.  This will modify the expiration of the active pass to be the start of this scheduled pass.
              </Typography>
            </Grid>
          </Grid>
        }

      </Grid>
    </Grid>
  );

}
