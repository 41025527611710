interface ResizeHTMLElement extends HTMLElement {
    width: number,
    height: number
}

// Keep the canvas size locked to the video
export default function resizeElement(element: ResizeHTMLElement) {

  // Size the canvas
  let parentEl: HTMLElement = element!.parentNode!.parentNode as ResizeHTMLElement;
  let parentWidth = parentEl.clientWidth;
  let parentHeight = parentEl.clientHeight;

  if (element.width !== parentWidth) {
    element.width = parentWidth
  }
  if (element.height !== parentHeight) {
    element.height = parentHeight
  }
}
