import React, { FormEvent, SetStateAction, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@mui/styles";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import BrandLogo from "../Branding/BrandLogo";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
  })
);

interface TvOSCheckCodeProps { 
  setTvUuid: React.Dispatch<SetStateAction<string | undefined>>; 
  cssClasses: any; 
}

export default function TvOSCheckCode(props: TvOSCheckCodeProps) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [inProgress, setInProgress] = useState(false);
  const [tvOSCode, setTvOSCode] = useState<string>("");

  // Effects
  useEffect(() => {
    // Get code from URL if present
    let params = new URLSearchParams(document.location.search);
    let code = params.get("code");
    
    if (code) {
      let match = code.match(/^[a-zA-Z0-9]+$/g);
      if (match && match[0]?.length == 8) {
        code = match.join("")
        setTvOSCode(code);
      }
    }
  }, []);

  // Actions
  const checkTvOSCode = async (e: FormEvent) => {
    e.preventDefault();
    if (inProgress) { return }
    if (!tvOSCode) { return }
    setInProgress(true)

    try {
      let fetchUrl = process.env.REACT_APP_HOST_URL + "/api/tvos/checkcode";
      let fetchData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: tvOSCode,
        }),
      };
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        console.log("Code Check Response", response);
        if (response.uuid) {
          props.setTvUuid(response.uuid);
          enqueueSnackbar("Code Validated");
        } else {
          enqueueSnackbar(response.message);
        }
      } else {
        enqueueSnackbar("Error Validating Code, please try again");
        console.error("Could not retrieve data ", res.status);
      }
    } catch (error) {
      console.error("Network error, please try again ", error);
    } finally {
      setInProgress(false);
    }
  };

  // Render
  return (
    <Paper className={props.cssClasses.signInPaper}>
      <Grid
        container
        alignItems="center"
        className={props.cssClasses.signInBox}
        style={{ margin: "auto" }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignItems="center" style={{ margin: "auto" }}>
              <BrandLogo size="medium" showName={false} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={props.cssClasses.signInTitle} variant="h5">
            Enter Apple TV Code
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={checkTvOSCode}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="tv"
              label="TV Code"
              type="password"
              id="TV Code"
              value={tvOSCode}
              onChange={(e) => setTvOSCode(e.target?.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>

            <Grid container className={props.cssClasses.tableFooter}>
              <Grid
                item
                xs={12}
                className={props.cssClasses.tableCell}
                style={{textAlign:"right"}}
              >
                By signing in you agree to our
                <br />
                <Link
                  href="https://setstream.io/terms-and-conditions/"
                  target="_blank"
                >
                  {" Terms and Conditions"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Paper>
  );
}
