import { ReactNode, useCallback, useMemo } from "react";
import {
  Tooltip,
  Button,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";

import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";
import { VideoCameraFront, VideocamOff, ReportOff } from "@mui/icons-material";
import useCamera from "../../../Context/CameraProvider/useCamera";
import { DevicePermission } from "../../../Context/CameraProvider/Hooks/useMediaDevices";

export default function ToggleVideo({
  variant = "button",
}: {
  variant?: "icon" | "button";
}) {
  const { 
    setIsActive, 
    withVideo, 
    setWithVideo 
  } = useVideoChat();
  const { 
    videoInputPermission, 
    gettingVideoMedia, 
    gettingDevices,
    videoTrack,
    stopVideoMedia
  } = useCamera();

  const toggleVideo = useCallback(() => {
    if (withVideo) {
      setWithVideo(false);
      if (videoTrack) {
        stopVideoMedia()
      }
    } else {
      setIsActive(true);
      setWithVideo(true);
    }
  }, [withVideo, videoTrack, stopVideoMedia]);

  const icon = useMemo(() => {
    if (gettingDevices || (withVideo && gettingVideoMedia)) {
      const color = variant === "button" ? "inherit" : "primary";

      return (
        <CircularProgress variant="indeterminate" size="18px" color={color} />
      );
    }

    if (videoInputPermission === DevicePermission.allowed) {
      if (withVideo) {
        return <VideoCameraFront />;
      } else {
        return <VideocamOff color="error" />;
      }
    }

    return <ReportOff color="error" />;
  }, [
    videoInputPermission,
    withVideo,
    gettingVideoMedia,
    gettingDevices,
    variant,
  ]);

  const button = useMemo(() => {
    let disabled = false;
    if (
      videoInputPermission !== DevicePermission.allowed ||
      gettingVideoMedia ||
      gettingDevices
    ) {
      disabled = true;
    }

    switch (variant) {
      case "icon":
        return (
          <IconButton
            color={withVideo ? "primary" : "inherit"}
            onClick={toggleVideo}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        );

      case "button":
      default:
        return (
          <Button
            color={withVideo ? "primary" : "inherit"}
            onClick={toggleVideo}
            disabled={disabled}
            variant="contained"
          >
            {icon}
          </Button>
        );
    }
  }, [
    videoInputPermission,
    withVideo,
    gettingVideoMedia,
    gettingDevices,
    variant,
  ]);

  const renderer = useMemo(() => {
    return (
      <Tooltip
        title={
          videoInputPermission === DevicePermission.allowed ? (
            "Toggle Video"
          ) : (
            <Alert severity="error" icon={<ReportOff color="error" />}>
              Video Permission Denied. Please check your browser settings.
            </Alert>
          )
        }
        placement={variant === "button" ? "top" : "right-start"}
      >
        <span>{button}</span>
      </Tooltip>
    );
  }, [withVideo, videoInputPermission, icon, variant]);

  return renderer;
}
