import React, { Component } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { Card, CardMedia } from "@mui/material";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { withSnackbar } from "notistack";
import VolumeUp from "@mui/icons-material/VolumeUp";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import ScreenFull from "screenfull";
import { isMobile } from "../../../utils";
import AudioOutputSelect from "../audio_output_select";
import Sketches from "../../Sketches/sketches";
import GestureIcon from "@mui/icons-material/Gesture";
import Tooltip from "@mui/material/Tooltip";
import { isTouchCapable } from "../../../utils/isTouchCapable";
import PlayCircleOutlineOutlined from "@mui/icons-material/PlayCircleOutlineOutlined";
import { PictureInPicture } from "@mui/icons-material";
import logToServer from "../../../utils/log_to_server";
import updateSdpAudioParameters from "../../../utils/Camera/updateSdpAudioParameters";

const axios = require("axios").default;

const useStyles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(1),
  },
  content: {
    marginRight: "362px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "287px",
    },
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  video: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
    paddingTop: "55.4%",
    position: "relative",
  },
  videoPlayer: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    overflow: "auto",
    resize: "both",
    width: "100%",
    position: "absolute",
    top: 0,
  },
  videoCard: {
    [theme.breakpoints.up("sm")]: {
      "&:hover div": {
        visibility: "visible",
      },
    },
    backgroundColor: theme.palette.background.paper,
    paddingRight: "1px",
  },
  videoCardMobile: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: "1px",
  },
  videoControlsHidden: {
    display: "flex",
    position: "absolute",
    zIndex: 20,
    top: 0,
    left: 0,
    width: "100%",
    opacity: 1.0,
    visibility: "collapse",
  },
  videoControlsShown: {
    display: "flex",
    position: "absolute",
    zIndex: 20,
    top: 0,
    left: 0,
    width: "100%",
    opacity: 1.0,
    visibility: "visible",
  },
  videoControlsMobile: {
    display: "flex",
    width: "100%",
  },
  videoControlsMobileFooter: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.white,
    zIndex: 999,
    marginTop: theme.spacing(2),
    boxShadow:
      "0px -6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
  },
  videoControlText: {
    display: "block",
  },
  switcherControls: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2px",
    marginBottom: "4px",
    width: "100%",
    textAlign: "center",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      zIndex: 1,
    },
    transition: "padding 600ms",
  },
  switcherControlsNoChat: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2px",
    marginBottom: "4px",
    width: "100%",
    textAlign: "center",
    position: "fixed",
    top: "4px",
    left: 0,
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      padding: 0,
      zIndex: 1,
      top: 0,
    },
    transition: "padding 600ms",
  },
  iconButton: {
    width: "32px",
    height: "32px",
    padding: "4px",
  },
  paper: {
    margin: 0,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    // height: '100%'
  },
  avatar: {
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
  },
  signInTitle: {
    margin: "auto",
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  chatWindow: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "fixed",
    bottom: 8,
    right: 8,
    height: "calc(100vh - 72px)",
    width: "350px",
    padding: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.down("lg")]: {
      width: "275px",
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
      width: "100%",
      height: "calc(100vh - (100vw / 1.5) - 34px)",
      right: 0,
      padding: theme.spacing(4, 0, 1, 0),
    },
  },
  chatPiece2: {
    display: "flex",
    flex: 1,
  },
  signInBox: {
    width: "calc(100% - 24px)",
    maxWidth: "400px !important",
    justifyContent: "center",
    alignContent: "center",
  },
  signInPaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(4),
  },
  statusDisplay: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    borderRadius: "4px",
  },
  sectionTitle: {
    width: "100%",
    maxWidth: "100%",
    padding: "8px",
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "4px",
    color: theme.palette.text.primary,
  },
});

class ConfidenceMonitor extends Component {
  constructor(props) {
    super(props);

    // Get stored camera display selection
    let previous_select = {};
    for (let i = 0; i < this.props.enabledCameras; i++) {
      previous_select[i] = true;
    }

    try {
      let stored_item = JSON.parse(
        localStorage.getItem(props.publisher_uuid + "_cams")
      );

      // Enable all cameras by default
      if (stored_item) {
        previous_select = stored_item;
      }
    } catch (e) {
      console.error("Error retreving stored camera selection. %s", e);
    }

    // Create array of available cameras
    let camera_colors = [
      "rgba(226, 29, 29, .7)",
      "rgba(50, 171, 223, .7)",
      "rgba(226, 226, 29, .7)",
      "rgba(71, 185, 105, .7)",
    ];
    let camera_labels = ["A", "B", "C", "D"];

    let cameras = [];
    // Add camera data
    for (let i = 0; i < this.props.enabledCameras; i++) {
      cameras.push({
        label: camera_labels[i],
        color: camera_colors[i],
        enabled: previous_select[i] ? true : false,
        videoSource: React.createRef(),
        videoBox: React.createRef(),
        videoStream: new MediaStream(),
        rtcStreamName: null,
        rtcConnection: null,
        isConnected: false,
        isStreamActive: false,
        poppedOut: false,
        supportsPip: false,
        fullScreen: false,
      });
    }

    this.state = {
      cookies: Cookies.get(props.publisher_uuid),
      is_active: false,
      rtcUrl: null,
      rtcJwt: null,
      rtcTokenId: null,
      rtcToken: null,
      rtcAccountID: null,
      iceServers: null,
      quadIsFullScreen: false,
      show_multicam_borders: true,
      showOutSelect: false,
      showSketches: true,
      shownCameras: 1,
      selectedCam: 0,
      showSketchControls: false,
      sinkId: "default",
      camera_labels: ["A", "B", "C", "D"],
      camera_colors: [
        "rgba(226, 29, 29, .7)",
        "rgba(50, 171, 223, .7)",
        "rgba(226, 226, 29, .7)",
        "rgba(71, 185, 105, .7)",
      ],
      cameras: cameras,
    };

    this.updateCameras = this.updateCameras.bind(this);
    this.setSinkId = this.setSinkId.bind(this);
  }

  // Update state
  setFormState(event) {
    const name = event.target.name;
    let value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    this.setState({ ...this.state, [name]: value });
  }

  // Update cameras array
  updateCameras(cam_index, cam_key, cam_value) {
    if (this.state.cameras[cam_index]) {
      let new_cameras = this.state.cameras;
      new_cameras[cam_index][cam_key] = cam_value;
      this.setState({
        cameras: new_cameras,
        shownCameras: new_cameras.filter((cam) => cam.enabled).length,
      });
    }
  }

  // Update Camera Audio Out
  setSinkId(sinkId) {
    if (
      this.state.cameras[0]?.videoSource?.current &&
      typeof this.state.cameras[0].videoSource.current.setSinkId === "function"
    ) {
      this.state.cameras[0].videoSource.current.setSinkId(sinkId);
    }
    this.setState(
      {
        sinkId: sinkId,
      },
      () => {
        // this.props.setSwitcherControls(this.switcherPieces(this.props.cssClasses));
      }
    );
  }

  // RTC Info
  getRtcInfo() {
    // This api call begins the entire connection process
    let cookie = Cookies.get(this.props.publisher_uuid);
    if (cookie) {
      // First close existing connections if they exist
      this.state.cameras.forEach((camera, i) => {
        if (camera.rtcConnection) {
          this.close_rtc_conn(i);
          // console.log('Closed existing RTC connnection for ', camera.label, ' Cam');
        }
      });

      // console.log('GET rtc info ', this.props.publisher_uuid);

      // Get sub token
      let url =
        process.env.REACT_APP_API_URL +
        "/api/monitor/" +
        (this.props.is_broadcast_only ? "Broadcast/" : "Project/") +
        this.props.publisher_uuid;
      axios
        .get(url, { withCredentials: true })
        .then((res) => {
          // console.log('Got RTC info? ', res.data.type);

          switch (res.data.type) {
            case "success":
              // let cameras = this.state.cameras;
              let previous_select = JSON.parse(
                localStorage.getItem(this.props.publisher_uuid + "_cams")
              );

              // Enable all displays by default
              if (!previous_select) {
                previous_select = {};
                for (let i = 0; i < this.props.enabledCameras; i++) {
                  previous_select[i] = true;
                }
              }

              // Create array of available cameras
              let cameras = [];
              // Add camera data
              for (let i = 0; i < res.data.enabled_cameras; i++) {
                cameras.push({
                  label: this.state.camera_labels[i],
                  color: this.state.camera_colors[i],
                  enabled: previous_select[i] ? true : false,
                  videoSource: React.createRef(),
                  videoBox: React.createRef(),
                  videoStream: new MediaStream(),
                  rtcStreamName: null,
                  rtcConnection: null,
                  isConnected: false,
                  isStreamActive: false,
                  poppedOut: false,
                  supportsPip: false,
                  fullScreen: false,
                });
              }

              // Store
              this.setState({
                cameras: cameras,
                shownCameras: cameras.filter((cam) => cam.enabled).length,
              });

              // Update each camera record and create a peer connection and video event listeners
              cameras.forEach((camera, cam_index) => {
                // Store reference to html element if it doesn't exist
                if (!camera.video_display) {
                  // Get html video display element
                  let video = document.getElementById("display_" + cam_index);

                  // Store video display element
                  this.updateCameras(cam_index, "video_display", video);
                }

                // Find this cameras stream name
                let streamName = res.data.streams.filter(
                  (stream) =>
                    stream.streamName.split("_")[0] === cameras[cam_index].label
                );
                if (streamName.length > 0 && streamName[0].streamName) {
                  cameras[cam_index].rtcStreamName = streamName[0].streamName;
                } else {
                  console.error("Could not find stream name ", streamName);
                }

                // Get html elements
                let video_display = document.getElementById(
                  "display_" + cam_index
                );
                cameras[cam_index].video_display = video_display;

                // ***** Add video player event listeners *****

                // Volume / mute changes
                video_display.addEventListener("volumechange", (e) => {
                  // Force an update to display muted status
                  this.forceUpdate();
                  // console.log(camera.label, '-Cam ', this.state.cameras[cam_index]?.label, ' volume change');
                });

                // Video is playing
                video_display.addEventListener("play", (e) => {
                  // Check if PIP is supported
                  let pip = this.state.cameras[cam_index]?.supportsPip;

                  // Check for chromium / gecko pip
                  if (document.pictureInPictureEnabled) {
                    pip = document.pictureInPictureEnabled ? true : false;
                  }

                  // Check for webkit Support iOS
                  if (video_display.webkitSupportsPresentationMode) {
                    pip = video_display.webkitSupportsPresentationMode(
                      "picture-in-picture"
                    )
                      ? true
                      : pip;
                  }

                  // Store
                  this.updateCameras(cam_index, "supportsPip", pip);
                });

                video_display.addEventListener("pause", async (e) => {
                  // Restart playing automatically
                  try {
                    await video_display.play();
                  } catch (error) {
                    /* Ignore */
                  }
                });

                // Chromium Picture in Picture activated
                video_display.addEventListener("enterpictureinpicture", (e) => {
                  this.updateCameras(cam_index, "poppedOut", true);
                  // console.log(camera.label, '-Cam ', this.state.cameras[cam_index]?.label, ' Entered PIP');
                });

                // Chromium Left Picture in picture
                video_display.addEventListener("leavepictureinpicture", (e) => {
                  this.updateCameras(cam_index, "poppedOut", false);
                  // console.log(camera.label, '-Cam ', this.state.cameras[cam_index]?.label, ' Left PIP');
                });

                // Webkit Picture in Picture - NOT WORKING
                video_display.addEventListener(
                  "onwebkitpresentationmode",
                  (e) => {
                    switch (video_display.webkitPresentationMode) {
                      case "picture-in-picture":
                        this.updateCameras(cam_index, "poppedOut", true);
                        break;
                      default:
                        this.updateCameras(cam_index, "poppedOut", false);
                    }
                    // console.log(camera.label, '-Cam ', this.state.cameras[cam_index]?.label, ' Presentation Mode changed to ', video_display.webkitPresentationMode);
                  }
                );

                // Entering Full Screen - Chromium
                video_display.addEventListener(
                  "onfullscreenchange",
                  async (e) => {
                    // Keep video playing on fullscreen change
                    if (video_display.paused) {
                      try {
                        await video_display.play();
                      } catch (error) {
                        /* Ignore */
                      }
                    }
                    // Store fullscreen status
                    this.updatePicture(
                      cam_index,
                      "fullscreen",
                      document.fullScreenElement
                    );
                    // console.log('Full Screen ', document.fullScreenElement);
                  }
                );

                // Entering Full Screen Webkit
                video_display.addEventListener(
                  "onwebkitfullscreenchange",
                  async (e) => {
                    // Keep video playing on fullscreen change
                    if (video_display.paused) {
                      try {
                        await video_display.play();
                      } catch (error) {
                        /* Ignore */
                      }
                    }
                    // Store fullscreen status
                    this.updatePicture(
                      cam_index,
                      "fullscreen",
                      video_display.webkitIsFullScreen
                    );
                    // console.log('Full Screen ', video_display.webkitIsFullScreen);
                  }
                );

                // Metadata loaded
                video_display.addEventListener("loadedmetadata", (e) => {
                  // console.log('Cam ', cam_index, ' Metadata loaded ');
                });
              });

              // Store then create connections
              this.setState(
                {
                  status: res.data.status,
                  is_active: true,
                  rtcTokenId: res.data.tokenId,
                  rtcToken: res.data.token,
                  rtcAccountID: res.data.accountId,
                  cameras: cameras,
                },
                () => {
                  // Set camera sizes
                  this.switchDisplay(res.data.enabled_cameras - 1, false); // Setup multicam display

                  // Create RTC Peer Connections
                  this.state.cameras.forEach((camera, i) => {
                    if (this.state.cameras[i].enabled) {
                      this.getSubscribe(i);
                    }
                  });
                }
              );
              break;

            case "inactive":
              this.props.enqueueSnackbar(res.data.message, { variant: "info" });
              console.log("Web No active pass");
              break;

            case "logged_out":
              Cookies.remove(this.props.publisher_uuid, {
                domain: process.env.REACT_APP_DOMAIN,
                path: "/",
              });
              this.props.enqueueSnackbar(res.data.message, {
                variant: "warning",
              });
              window.location.reload(false);
              break;

            case "error":
              this.props.enqueueSnackbar(res.data.message, {
                variant: "error",
              });
              break;
          }
        })
        .catch((err) => {
          this.props.enqueueSnackbar(
            "Looks like we had an issue connecting.  -RTC URL Error-  Please refresh the page or contact support.",
            { variant: "error" }
          );
          console.error("Error - Could not get rtc URL", err);
        });
    } else {
      this.props.enqueueSnackbar("Please authenticate", {
        variatnt: "warning",
      });
      // console.log('Please authenticate');
    }
  }

  getSubscribe(cam_index) {
    // Make sure it's appropriate to connect
    if (!this.props.tab) {
      console.log("Monitor not in view.  Not connecting");
      return;
    }
    if (!this.state.cameras[cam_index]) {
      console.log("Camera ", cam_index, " does not exist.  Not connecting");
      return;
    }

    // Cancel existing auto reconnect attempts since we are trying to connect
    if (this.state.cameras[cam_index]?.reconnectTimer) {
      clearTimeout(this.state.cameras[cam_index].reconnectTimer);
      this.updateCameras(cam_index, "reconnectTimer", null);
    }

    let opts = {
      url: "https://director.millicast.com/api/director/subscribe",
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.state.rtcToken,
        "Content-Type": "application/json",
      },
      data: {
        streamAccountId: this.state.rtcAccountID,
        streamName: this.state.cameras[cam_index]?.rtcStreamName,
        unauthorizedSubscribe: false,
      },
    };

    // Get subscribe info and connect
    axios(opts)
      .then((list) => {
        if (list.data.status === "success") {
          if (this.state.iceServers) {
            this.setState(
              {
                rtcUrl: list.data.data.wsUrl,
                rtcJwt: list.data.data.jwt,
              },
              () => {
                this.connectRTC(cam_index);
                // console.log('Connecting RTC for Cam ', this.state.cameras[cam_index]?.label);
              }
            );
          } else {
            this.getIceServers()
              .then((ice) => {
                this.setState(
                  {
                    rtcUrl: list.data.data.wsUrl,
                    rtcJwt: list.data.data.jwt,
                    iceServers: ice,
                  },
                  () => {
                    this.connectRTC(cam_index);
                    // console.log('Connecting RTC 2 for Cam ', this.state.cameras[cam_index]?.label);
                  }
                );
              })
              .catch((err) => {
                console.error("ICE Error - Could not get ICE servers ", err);
              });
          }
          // console.log('SUB Token received');
        } else {
          console.error("Could not get VV2 sub info ", list.data.type);
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 400
        ) {
          // Stream has not begun.  Check back in 3 sec.
          if (this.state.cameras[cam_index]?.reconnectTimer) {
            clearTimeout(this.state.cameras[cam_index].reconnectTimer);
          }
          let timeout = setTimeout(() => {
            this.getSubscribe(cam_index);
          }, 3000);
          this.updateCameras(cam_index, "reconnectTimer", timeout);
        } else {
          // An error occured.  Check back in 3 sec in case it resolves itself
          if (this.state.cameras[cam_index]?.reconnectTimer) {
            clearTimeout(this.state.cameras[cam_index].reconnectTimer);
          }
          // let timeout = setTimeout(() => {this.getSubscribe(cam_index)}, 3000);
          // this.updateCameras(cam_index, "reconnectTimer", timeout)
          logToServer({
            section: "Monitor",
            action: "Error Connecting",
            tokenId: this.state.rtcTokenId,
            message: err.message,
            name: err.name,
            stack: err.stack,
          });
          console.error("Error - Could not get connect info", err);
          this.props.enqueueSnackbar(
            "Connection failed " +
              err.message +
              " Please refresh the page and try again.",
            { variant: "error" }
          );
        }
      });
  }

  // *************************************************************************

  getIceServers() {
    return new Promise(function (resolve, reject) {
      let iceServers = [];
      axios
        .put("https://turn.millicast.com/webrtc/_turn")
        .then((ice) => {
          // console.log('Received ICE ', ice.data.s);
          if (ice.status === 200) {
            if (ice.data.s !== "ok") {
              console.log("ICE return is not OK ", ice.data);
              resolve(iceServers);
            }
            let list = ice.data.v.iceServers;
            list.forEach((cred, i) => {
              let v = cred.url;
              if (!!v) {
                cred.urls = v;
                delete cred.url;
              }
              iceServers.push(cred);
            });
            resolve(iceServers);
          } else {
            console.log("Could not retreive ICE servers ", ice);
            resolve(iceServers);
          }
        })
        .catch((err) => {
          console.log("Error - Could not get ICE servers", err);
          resolve(iceServers);
        });
    });
    // console.log('Getting ICE');
  }

  connectRTC(cam_index) {
    // Do connection
    if (
      this.state.rtcUrl &&
      this.state.rtcJwt &&
      this.state.cameras[cam_index]?.rtcStreamName &&
      this.state.iceServers
    ) {
      // console.log('Creating a connection for Cam ', this.state.cameras[cam_index]?.label);

      // Close existing connections first
      if (this.state.cameras[cam_index]?.rtcConnection) {
        this.close_rtc_conn(cam_index);
      }

      // Create remote connection
      let rtcConf = {
        iceServers: this.state.iceServers,
        rtcpMuxPolicy: "require",
        bundlePolicy: "max-bundle",
      };
      let pc = new RTCPeerConnection(rtcConf);

      // Add received tracks to video ref
      pc.ontrack = (event) => {
        // Check to prevent error when stream is not present
        if (this.state.cameras[cam_index]?.videoSource.current !== null) {
          this.state.cameras[cam_index].videoSource.current.srcObject =
            event.streams[0];
        }
      };

      // Create Signalling connection
      let ws = new WebSocket(this.state.rtcUrl + "?token=" + this.state.rtcJwt);

      // Store WS reference
      this.updateCameras(cam_index, "ws", ws);

      // WebSocket is connected
      ws.onopen = () => {
        // console.log('Opened WS connection for Cam ', this.state.cameras[cam_index]?.label);

        //if this is supported
        if (pc.addTransceiver) {
          // console.log('transceiver!  for Cam ', this.state.cameras[cam_index]?.label);

          //Create all the receiver tracks
          pc.addTransceiver("audio", {
            direction: "recvonly",
            // streams : [stream]
            streams: [this.state.cameras[cam_index]?.videoStream],
          });
          pc.addTransceiver("video", {
            direction: "recvonly",
            // streams : [stream]
            streams: [this.state.cameras[cam_index]?.videoStream],
          });
        } else {
          console.error("transceiver not supported");
          this.props.enqueueSnackbar(
            "Transceiver not found, your browser may be out of date.",
            { variant: "error" }
          );
        }

        // Create RTC offer
        pc.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
          .then((desc) => {
            // console.log('Offer created for Cam ', this.state.cameras[cam_index]?.label);

            // Allow Stereo and Surround
            desc.sdp = updateSdpAudioParameters(desc.sdp, 2);
            desc.sdp = updateSdpAudioParameters(desc.sdp, 6);

            pc.setLocalDescription(desc)
              .then(() => {
                // console.log('Local description set for Cam ', this.state.cameras[cam_index]?.label);
                //set required information for media server.
                let data = {
                  streamId: this.state.cameras[cam_index]?.rtcStreamName,
                  sdp: desc.sdp,
                };
                //create payload
                let payload = {
                  type: "cmd",
                  transId: Math.random() * 10000,
                  name: "view",
                  data: data,
                };

                // Send answer
                ws.send(JSON.stringify(payload));

                // Store connection
                this.updateCameras(cam_index, "rtcConnection", pc);

                // console.log('WS Payload sent for Cam ', this.state.cameras[cam_index]?.label);
              })
              .catch((err) => {
                this.props.enqueueSnackbar(
                  "Looks like we had an issue connecting Cam " +
                    this.state.cameras[cam_index]?.label +
                    err,
                  { variant: "error" }
                );
                console.error(
                  "Error - Could not set local description for Cam ",
                  this.state.cameras[cam_index]?.label,
                  " - ",
                  err
                );
              });
          })
          .catch((err) => {
            this.props.enqueueSnackbar(
              "Looks like we had an issue connecting for Cam " +
                this.state.cameras[cam_index]?.label +
                err,
              { variant: "error" }
            );
            console.error(
              "Error - Could not create offer for Cam ",
              this.state.cameras[cam_index]?.label,
              " - ",
              err
            );
          });
      };

      // Listen for response
      ws.addEventListener("message", (evt) => {
        // console.log('Received Socket message ');

        let msg = JSON.parse(evt.data);
        switch (msg.type) {
          case "response":
            let data = msg.data;
            let answer = new RTCSessionDescription({
              type: "answer",
              sdp: data.sdp,
            });

            pc.setRemoteDescription(answer)
              .then((d) => {
                // Store connection
                this.updateCameras(cam_index, "rtcConnection", pc);

                // RTC Connection state change events (no firefox support)
                pc.addEventListener("connectionstatechange", (e) => {
                  console.log(
                    "Cam %s RTC Connection State Changed to %s",
                    cam_index,
                    pc.connectionState
                  );

                  if (pc.connectionState === "failed") {
                    let timeout = setTimeout(() => {
                      this.getSubscribe(cam_index);
                    }, 1000);
                    this.updateCameras(cam_index, "reconnectTimer", timeout);
                  }
                });

                // console.log('Remote description set for Cam ', this.state.cameras[cam_index]?.label);
              })
              .catch((err) => {
                console.error("Error - ", err);
                this.props.enqueueSnackbar(
                  "Looks like we had an issue connecting. " + err,
                  { variant: "error" }
                );
                console.error(
                  "Could not set remote description for Cam ",
                  this.state.cameras[cam_index]?.label,
                  " - ",
                  err
                );
              });

            break;
          case "event":
            switch (msg.name) {
              case "active":
                // RTC Connection is active
                // this.props.enqueueSnackbar('Connection Active', { variant: 'info' });
                // console.log('RTC Connection Active for Cam ', this.state.cameras[cam_index]?.label);
                this.updateCameras(cam_index, "isStreamActive", true);

                break;
              case "inactive":
                // RTC Connection is inactive
                // console.log('RTC Connection Inactive for Cam ', this.state.cameras[cam_index]?.label);
                this.updateCameras(cam_index, "isStreamActive", false);

                // // Return to logo when stream is inactive
                // if (this.state.cameras[cam_index]?.videoSource?.current?.srcObject) {
                //   this.state.cameras[cam_index]?.videoSource.current.srcObject = undefined;
                // }
                break;
              default:
            }
            break;
          default:
          // console.log('WS message for Cam ', this.state.cameras[cam_index]?.label, ' - ', msg);
        }
      });

      // Listen for WebSocket Closed events
      ws.addEventListener("close", (evt) => {
        // Turning off a multi cam display closes a socket connection. Don't resubscribe unless we had an issue
        // this.getSubscribe(cam_index);
        // console.log('WS closed for Cam ', cam_index, '\n', evt);
      });

      ws.addEventListener("error", (evt) => {
        // console.log('WS ERROR for Cam ', cam_index, '\n', evt);
        // Try reconnecting
        this.getSubscribe(cam_index);
      });
    } else {
      console.error(
        "RTC No information to connect with for Cam ",
        this.state.cameras[cam_index]?.label
      );
    }
    // console.log('Creating RTC connection for Cam ', this.state.cameras[cam_index]?.label);
  }

  // *************************************************************************

  // Picture in Picture handler
  popOutVideo(cam_index) {
    let player = this.state.cameras[cam_index]?.video_display;
    if (!player) {
      player = document.getElementById("display_" + cam_index);
      // Store html element
      this.updateCameras(cam_index, "video_display", player);
    }

    // Chromium based
    if (
      document.pictureInPictureEnabled &&
      !player.disablePictureInPicture &&
      typeof player.requestPictureInPicture === "function"
    ) {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture().catch((err) => {
          console.error("Error - Could not close PIP window", err);
        });
      } else {
        player.requestPictureInPicture().catch((err) => {
          console.error("Error - Could not request PIP", err);
        });
      }
    }
    // Webkit based
    else if (
      player.webkitSupportsPresentationMode &&
      player.webkitSupportsPresentationMode("picture-in-picture") &&
      typeof player.webkitSetPresentationMode === "function"
    ) {
      // Check PIP state
      let out = player.webkitPresentationMode === "inline" ? true : false;
      // Change PIP state
      player.webkitSetPresentationMode(
        player.webkitPresentationMode === "picture-in-picture"
          ? "inline"
          : "picture-in-picture"
      );
      // Store
      this.updateCameras(cam_index, "poppedOut", out);
    }
  }

  // Full screen handler
  goFullScreen(cam_index) {
    let player;
    // Check if we should use the individual player or the container
    if (cam_index < 4) {
      // Get video display element
      player = this.state.cameras[cam_index]?.video_display;
      if (!player) {
        player = document.getElementById("display_" + cam_index);
        // Store html element
        this.updateCameras(cam_index, "video_display", player);
      }
    } else {
      // Use container for fullscreen
      player = document.getElementById("confidence_monitors");
    }

    // Try screenfull first
    if (ScreenFull.isEnabled) {
      // console.log('ScreenFull');
      if (cam_index < 4) {
        ScreenFull.toggle(player); // Request single element
      } else {
        ScreenFull.toggle(); // Entire document fullscreen
      }
    } else {
      // console.log('Not ScreenFull');

      // Chromium in full screen
      if (document.fullScreenElement === true) {
        document
          .exitFullscreen()
          .then((result) => {
            // Store fullscreen state if index is not quad split
            if (cam_index < 4) {
              this.updateCameras(cam_index, "fullscreen", false);
            }
          })
          .catch((err) => {
            console.error("Error - Could not exit full screen", err);
          });

        // Webkit in full screen
      } else if (player.webkitIsFullScreen === true) {
        // Exit fullscreen
        player.webkitExitFullscreen();
        // Store fullscreen state if index is not quad split
        if (cam_index < 4) {
          this.updateCameras(
            cam_index,
            "fullscreen",
            player.webkitIsFullScreen
          );
        }

        // Chromium out of full screen
      } else if (typeof player.requestFullscreen === "function") {
        // Enter fullscreen
        player
          .requestFullscreen()
          .then((result) => {
            // Store fullscreen state if index is not quad split
            if (cam_index < 4) {
              this.updateCameras(
                cam_index,
                "fullscreen",
                document.fullscreenElement
              );
            }
          })
          .catch((err) => {
            console.error("Error - Could not request Full screen", err);
          });

        // Webkit out of full screen
      } else if (typeof player.webkitEnterFullscreen === "function") {
        // Enter fullscreen
        player.webkitEnterFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        // Store fullscreen state if index is not quad split
        if (cam_index < 4) {
          this.updateCameras(
            cam_index,
            "fullscreen",
            player.webkitIsFullScreen
          );
        }
      } else if (typeof player.webkitSupportsPresentationMode === "function") {
        player.webkitSetPresentationMode("fullscreen");
      } else {
        // console.log('No Full Screen capabilities');
      }
    }
  }

  // Mute / Un Mute button actions
  muteVideo(cam_index) {
    // Toggle mute state of video display
    if (this.state.cameras[cam_index]?.video_display) {
      if (this.state.cameras[cam_index]?.video_display.muted) {
        // Change element mute state
        this.state.cameras[cam_index].video_display.muted = false;
      } else {
        // Change element mute state
        this.state.cameras[cam_index].video_display.muted = true;
      }
      this.forceUpdate();
    }
  }

  // Play the viewer if paused
  async playViewer(cam_index) {
    if (this.state.cameras[cam_index]?.video_display?.paused) {
      try {
        await this.state.cameras[cam_index]?.video_display.play();
      } catch (error) {
        /* Ignore */
      }
    }
  }

  // Switch video displays
  switchDisplay(cam_index, to_connect) {
    // Individual Camera Selectors
    if (cam_index < 4) {
      // Check the number of enabled cameras
      let shownCameras = this.state.cameras.filter((camera) => camera.enabled);
      let numberOfCameras = shownCameras.length;

      // If track is enabled close rtc connection before hiding element
      if (
        this.state.cameras[cam_index]?.rtcConnection &&
        this.state.cameras[cam_index]?.enabled
      ) {
        // Disconnect unless it's A cam and the only cam enabled
        if (cam_index !== 0 || (cam_index === 0 && numberOfCameras !== 1)) {
          // Close RTC Connection
          this.close_rtc_conn(cam_index);
        }

        // Create new rtc connection before turning on display
      } else if (
        this.state.iceServers &&
        !this.state.cameras[cam_index]?.rtcConnection &&
        !this.state.cameras[cam_index]?.enabled &&
        to_connect
      ) {
        this.getSubscribe(cam_index);
      }

      // Turn a viewer on or off
      if (to_connect) {
        this.updateCameras(
          cam_index,
          "enabled",
          this.state.cameras[cam_index]?.enabled ? false : true
        );
      }

      // Get updated list of cameras to set correct sizing for - Watch out for timing bug is setState takes too long
      shownCameras = this.state.cameras.filter((camera) => camera.enabled);
      numberOfCameras = shownCameras.length;

      let store_cam_select = {};
      this.state.cameras.forEach((camera, i) => {
        store_cam_select[i] = this.state.cameras[i].enabled;
      });
      localStorage.setItem(
        this.props.publisher_uuid + "_cams",
        JSON.stringify(store_cam_select)
      );

      // Set display order
      switch (numberOfCameras) {
        case 0:
          // Always show A Camera

          // Display A Camera
          this.updateCameras(0, "enabled", true);

          // Reconnect if needed
          if (!this.state.cameras[cam_index]?.rtcConnection && to_connect) {
            this.getSubscribe(0);
          }

          this.state.cameras[0].video_display.parentNode.parentNode.style.order = 0;
          this.setState({
            shownCameras: 1,
          });

          break;

        case 1:
          // 1 Camera - Reset order and sizing

          // Set camera order to default and size to fit
          shownCameras.forEach((camera, i) => {
            camera.video_display.parentNode.parentNode.style.order = i;
          });
          this.setState({
            shownCameras: 1,
          });
          break;

        case 2:
          // 2 Cameras - Stack top and bottom

          // Set camera order to default and size to fit vertical height
          shownCameras.forEach((camera, i) => {
            camera.video_display.parentNode.parentNode.style.order = i;
          });
          this.setState({
            shownCameras: 2,
          });
          break;

        case 3:
        case 4:
          // 4 Cameras - Reset order and sizing

          // Set camera order to default and sizing to 50%
          shownCameras.forEach((camera, i) => {
            // Reset order
            camera.video_display.parentNode.parentNode.style.order = i;
          });
          this.setState({
            shownCameras: 4,
          });
          break;
      }

      // Quad Split
    } else if (cam_index === 4) {
      // Turn all viewers on (Quad Split)
      let new_cameras = [];

      this.state.cameras.forEach((camera, i) => {
        // Show all cameras
        camera.enabled = true;
        new_cameras.push(camera);
      });
      this.setState({
        cameras: new_cameras,
        shownCameras: 4,
      });
    }
  }

  // Enable all cameras as default when # of cams selected changes
  enable_cameras(enabled_cameras) {
    // First close existing connections
    this.state.cameras.forEach((camera, i) => {
      if (camera.rtcConnection) {
        this.close_rtc_conn(i);
        // console.log('ConfMon Closed RTC Conn ', i);
      }
    });

    // ** Default newly enabled cameras to displayed **

    // Create array of available cameras
    let cameras = [];
    let previousEnabled;

    try {
      previousEnabled = JSON.parse(
        localStorage.getItem(this.props.publisher_uuid + "_cams")
      );
    } catch (e) {
      console.error("Could not retrieve cam selection");
    }

    // Create blank object if nothing is return from localStorage
    if (!previousEnabled) {
      previousEnabled = {};
    }

    // Add camera data
    for (let i = 0; i < enabled_cameras; i++) {
      // Enable cameras in localStorage

      if (typeof previousEnabled[i] !== "boolean") {
        previousEnabled[i] = true;
      }

      // Create available cameras array
      cameras.push({
        label: this.state.camera_labels[i],
        color: this.state.camera_colors[i],
        enabled: previousEnabled[i],
        videoSource: React.createRef(),
        videoBox: React.createRef(),
        videoStream: new MediaStream(),
        rtcStreamName: null,
        rtcConnection: null,
        isConnected: false,
        isStreamActive: false,
        poppedOut: false,
        supportsPip: false,
        fullScreen: false,
        connectionState: null,
      });
    }

    // Store updated camera enables
    localStorage.setItem(
      this.props.publisher_uuid + "_cams",
      JSON.stringify(previousEnabled)
    );

    // Store enabled cameras and create connections
    this.setState(
      {
        cameras: cameras,
        shownCameras: cameras.filter((cam) => cam.enabled).length,
      },
      () => {
        // Restart connection process if selected tab
        if (this.props.tab) {
          this.getRtcInfo();
        }
      }
    );
  }

  // Toggle video element borders
  toggleBorders() {
    this.setState({
      show_multicam_borders: this.state.show_multicam_borders ? false : true,
    });
  }

  // Stop RTC Peer Connection Tracks when not displayed
  close_rtc_conn(cam_index) {
    // console.log('Closing Connection for Cam %s', cam_index);

    // If the connection exists then close it
    if (this.state.cameras[cam_index]?.rtcConnection) {
      this.state.cameras[cam_index].rtcConnection.close();
      // delete this.state.cameras[cam_index]?.rtcConnection;
      this.updateCameras(cam_index, "rtcConnection", null);
    }

    // If we have a WebSocket, close it
    if (this.state.cameras[cam_index]?.ws) {
      this.state.cameras[cam_index].ws.close();
      this.updateCameras(cam_index, "ws", null);
    }

    if (this.state.cameras[cam_index]?.videoDisplay?.current?.srcObject) {
      // this.state.cameras[cam_index]?.videoDisplay.current.srcObject = undefined;
      // this.state.cameras[cam_index]?.videoDisplay.current.src = "/videos/LogoLoop_Icon.mp4";
    }
  }

  // Fix for Safari using callbacks instead of promises for Notification permissions
  checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  }

  // Toggle which camera is currently selected
  toggleSelectedCam(cam_index) {
    if (this.state.selectedCam !== cam_index) {
      this.setState({
        selectedCam: cam_index,
        showSketchControls: true,
      });
    } else {
      this.setState({
        showSketchControls: !this.state.showSketchControls,
      });
    }
  }

  // *********** Lifecycle ***********

  componentDidMount() {
    // Load if selected tab
    if (this.props.tab) {
      // this.getRtcInfo();
    }

    // Check PIP ability for each viewer
    this.state.cameras.forEach((camera, cam_index) => {
      // Check for chromium / gecko PIP support
      let pip = document.pictureInPictureEnabled ? true : false;
      // Get html video display element
      let video = document.getElementById("display_" + cam_index);

      // Store video display element
      this.updateCameras(cam_index, "video_display", video);

      // Check for webkit iOS PIP suppport
      if (video && video.webkitSupportsPresentationMode) {
        pip = video.webkitSupportsPresentationMode("picture-in-picture")
          ? true
          : pip;
      }

      // Store pip support
      this.updateCameras(cam_index, "supportsPip", pip);
    });

    // Listen for page focus events
    document.addEventListener("visibilitychange", () => {
      // Only handle visibility on mobile to save battery
      if (isMobile) {
        if (document.visibilityState === "hidden") {
          this.state.cameras.forEach((camera, cam_index) => {
            if (
              camera.rtcConnection &&
              !this.state.cameras[cam_index]?.poppedOut
            ) {
              this.close_rtc_conn(cam_index);
            }
          });
        } else {
          if (this.props.tab) {
            // Only recconnect if tab is in view
            this.state.cameras.forEach((camera, cam_index) => {
              if (
                // Only reconnect if we are not connected
                !camera.rtcConnection ||
                (camera.rtcConnection &&
                  camera.rtcConnection.connectionState !== "connected")
              ) {
                this.getSubscribe(cam_index);
              }
            });
          }
        }
      }
    });
  }

  componentWillUnmount() {
    // Cleanup RTC Connections
    this.state.cameras.forEach((camera, i) => {
      this.close_rtc_conn(i);
    });
  }

  // Create viewer
  viewerPieces(classes, cam_index) {
    if (this.state.cookies) {
      return (
        <Grid
          id={`monitor_${cam_index}`}
          className={
            this.state.shownCameras === 1
              ? this.props.cssClasses.oneCamera
              : this.state.shownCameras === 2
              ? this.props.cssClasses.twoCameras
              : this.state.shownCameras > 2
              ? this.props.cssClasses.fourCameras
              : this.props.cssClasses.oneCamera
          }
          sx={{
            display: this.state.cameras[cam_index]?.enabled ? "block" : "none",
            order: cam_index,
          }}
          key={"SIUF" + cam_index}
          onClick={(e) => {
            this.toggleSelectedCam(cam_index);
          }}
        >
          <Card
            elevation={4}
            className={
              isTouchCapable ? classes.videoCardMobile : classes.videoCard
            }
            ref={this.state.cameras[cam_index]?.videoBox}
            sx={{
              width: "100%",
              paddingTop: "56.25%",
              position: "relative",
              margin: "auto",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor:
                isTouchCapable && this.state.selectedCam === cam_index
                  ? "#b3b3b3 #b3b3b3 " +
                    this.state.cameras[cam_index]?.color +
                    " #b3b3b3"
                  : this.state.show_multicam_borders
                  ? this.state.cameras[cam_index]?.color
                  : "#000000",
            }}
            key={"CARD" + cam_index}
          >
            <CardMedia
              component="video"
              id={"display_" + cam_index}
              ref={this.state.cameras[cam_index]?.videoSource}
              // controls
              autoPlay
              muted
              loop
              poster=""
              autopictureinpicture="true"
              playsInline
              src="/videos/LogoLoop_Icon.mp4"
              style={{
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "#121212",
              }}
              key={"CARDMEDIA" + cam_index}
            />
            <Grid
              style={{
                background: `linear-gradient(
                      0deg,
                      rgba(21,21,21,0) 0%,
                      rgba(21,21,21,0.6) 30%,
                      rgba(21,21,21,1) 80%
                    )`,
                boxShadow: "-4px 4px 12px -2px rgba(0,0,0,0.5)",
              }}
              className={
                isTouchCapable &&
                this.state.showSketchControls &&
                this.state.selectedCam === cam_index
                  ? classes.videoControlsShown
                  : classes.videoControlsHidden
              }
              key={"GRID" + cam_index}
            >
              {this.viewerControlPieces(classes, cam_index)}
            </Grid>
            {this.props.tab && (
              <Sketches
                enabled={this.state.showSketches}
                disable={() => this.setState({ showSketches: false })}
                enable={() => this.setState({ showSketches: true })}
                cam_index={cam_index}
                viewerUuid={this.props.userUuid}
                authUuid={this.props.publisher_uuid}
                room={this.props.room}
                pusher={this.props.pusher}
                presenceRoom={this.props.presenceRoom}
                members={this.props.members}
                viewer_color={this.props.viewer_color}
                selectedCam={this.state.selectedCam}
                showControls={this.state.showSketchControls}
                key={"SKETCHES" + cam_index}
              />
            )}
            {this.state.cameras[cam_index]?.isStreamActive &&
              this.state.cameras[cam_index]?.videoSource?.current?.paused && (
                <Grid
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                  key={"GRID2" + cam_index}
                >
                  <IconButton
                    onClick={() => this.playViewer(cam_index)}
                    size="large"
                    key={"BUTTON" + cam_index}
                  >
                    <PlayCircleOutlineOutlined key={"PLAY" + cam_index} />
                  </IconButton>
                </Grid>
              )}
          </Card>
        </Grid>
      );
    }
  }

  // Create viewer controls
  viewerControlPieces(classes, cam_index) {
    if (this.state.cookies) {
      return (
        <Grid
          className={classes.videoControlsMobile}
          style={{
            borderTop: "2px solid " + this.state.cameras[cam_index]?.color,
          }}
          key={"VGRID" + cam_index}
        >
          <Grid key={"VGRID2" + cam_index}>
            <Grid key={"VGRID2ITEM" + cam_index}>
              <Tooltip
                title={
                  <span key={"SPAN2" + cam_index}>
                    {"Connection: " +
                      this.state.cameras[
                        cam_index
                      ]?.rtcConnection?.connectionState?.toUpperCase()}
                    <br key={"BR2" + cam_index} />
                    {"Stream: " +
                      (this.state.cameras[cam_index]?.isStreamActive
                        ? "LIVE"
                        : "NOT LIVE")}
                  </span>
                }
                placement="bottom"
                key={"TOOLTIP" + cam_index}
              >
                <Badge
                  variant="dot"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  color={
                    this.state.cameras[cam_index]?.rtcConnection
                      ?.connectionState === "connected"
                      ? "primary"
                      : this.state.cameras[cam_index]?.rtcConnection
                          ?.connectionState === "new" ||
                        this.state.cameras[cam_index]?.rtcConnection
                          ?.connectionState === "connecting"
                      ? "secondary"
                      : this.state.cameras[cam_index]?.rtcConnection
                          ?.connectionState === "failed"
                      ? "error"
                      : "default" // Defaults for "disconnected" & "closed"
                  }
                  key={"BADGE" + cam_index}
                >
                  <Typography
                    variant="h5"
                    style={{
                      color: this.state.cameras[cam_index]?.color,
                      paddingLeft: "6px",
                      marginRight: "8px",
                    }}
                    key={"TEXT" + cam_index}
                  >
                    {this.state.cameras[cam_index]?.label}
                  </Typography>
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid key={"GRID3" + cam_index}>
            {this.state.cameras[cam_index]?.rtcConnection?.connectionState &&
              this.state.cameras[cam_index]?.rtcConnection?.connectionState !==
                "failed" &&
              this.state.cameras[cam_index]?.rtcConnection?.connectionState !==
                "disconnected" &&
              this.state.cameras[cam_index]?.rtcConnection?.connectionState !==
                "closed" && (
                <Grid key={"GRID3ITEM" + cam_index}>
                  <Typography
                    style={{
                      color: this.state.cameras[cam_index]?.isStreamActive
                        ? "#238e4d"
                        : "#787878",
                      padding: "6px",
                    }}
                    key={"TEXT2" + cam_index}
                  >
                    {this.state.cameras[cam_index]?.isStreamActive ? (
                      "LIVE"
                    ) : (
                      <span
                        style={{
                          textDecoration: "line-through",
                          textDecorationThickness: "1px",
                          textDecorationStyle: "double",
                        }}
                        key={"SPAN3" + cam_index}
                      >
                        LIVE
                      </span>
                    )}
                  </Typography>
                </Grid>
              )}
          </Grid>

          <Grid key={"GRID4" + cam_index}>
            {(this.state.cameras[cam_index]?.rtcConnection?.connectionState ===
              "failed" ||
              this.state.cameras[cam_index]?.rtcConnection?.connectionState ===
                "disconnected" ||
              this.state.cameras[cam_index]?.rtcConnection?.connectionState ===
                "closed" ||
              !this.state.cameras[cam_index]?.rtcConnection
                ?.connectionState) && (
              <Grid key={"GRID4ITEM" + cam_index}>
                <Button
                  onClick={() => this.getSubscribe(cam_index)}
                  variant="contained"
                  color="primary"
                  key={"BUTTON4" + cam_index}
                >
                  RECONNECT
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid
            style={{ flex: "1", textAlign: "center" }}
            key={"GRID5" + cam_index}
          ></Grid>

          <Grid key={"GRID6" + cam_index}>
            <Grid key={"GRID6ITEM" + cam_index}>
              {this.state.cameras[cam_index]?.supportsPip ? (
                <Tooltip
                  title={
                    this.state.cameras[cam_index]?.poppedOut
                      ? "Pop In"
                      : "Pop Out"
                  }
                  placement="bottom"
                  arrow
                  key={"TOOLTIP6" + cam_index}
                >
                  <IconButton
                    onClick={(e) => this.popOutVideo(cam_index)}
                    color={
                      this.state.cameras[cam_index].poppedOut
                        ? "primary"
                        : "secondary"
                    }
                    className={classes.iconButton}
                    size="large"
                    key={"BUTTON6" + cam_index}
                  >
                    <PictureInPicture key={"ICON6" + cam_index} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>

          <Grid key={"GRID7" + cam_index}>
            <Grid key={"GRID7ITEM" + cam_index}>
              {this.state.cameras[cam_index].video_display ? (
                <Tooltip
                  title={
                    this.state.cameras[cam_index].fullScreen
                      ? "Windowed"
                      : "Full Screen"
                  }
                  placement="bottom"
                  arrow
                  key={"TOOLTIP7" + cam_index}
                >
                  <IconButton
                    onClick={(e) => this.goFullScreen(cam_index)}
                    color={
                      this.state.cameras[cam_index].fullScreen
                        ? "primary"
                        : "secondary"
                    }
                    className={classes.iconButton}
                    size="large"
                    key={"BUTTON7" + cam_index}
                  >
                    <AspectRatioIcon key={"ICON7" + cam_index} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>

          <Grid key={"GRID8" + cam_index}>
            <Tooltip
              title={
                this.state.cameras[cam_index]?.video_display?.muted === true
                  ? "MUTED"
                  : "UNMUTED"
              }
              placement="bottom"
              arrow
              key={"TOOLTIP8" + cam_index}
            >
              <IconButton
                onClick={(e) => this.muteVideo(cam_index)}
                className={classes.iconButton}
                size="large"
                key={"BUTTON8" + cam_index}
              >
                {this.state.cameras[cam_index]?.video_display ? (
                  this.state.cameras[cam_index]?.video_display.muted ===
                  true ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" id="muteOffIcon" />
                      <path
                        d="M5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm14.525-4l3.536 3.536-1.414 1.414L19 13.414l-3.536 3.536-1.414-1.414L17.586 12 14.05 8.464l1.414-1.414L19 10.586l3.536-3.536 1.414 1.414L20.414 12z"
                        fill="rgba(255,0,0,1)"
                      />
                    </svg>
                  ) : (
                    <VolumeUp
                      id="muteIcon"
                      variant="filled"
                      style={{ color: "rgb(0,255,0,1)" }}
                      key={"ICON8" + cam_index}
                    />
                  )
                ) : null}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
  }

  // Create Switcher controls
  switcherPieces(classes) {
    if (this.state.cookies) {
      let buttons = [];

      // Border toggle
      buttons.push(
        <Grid style={{ display: "inline" }} key={"THSIO"}>
          <Tooltip title="Toggle Borders" key={"tooltip23428349"}>
            <Button
              onClick={(e) => this.toggleBorders()}
              color={this.state.show_multicam_borders ? "primary" : "secondary"}
              variant="contained"
              style={{
                padding: "3px",
                minWidth: "32px",
                height: "32px",
                marginRight: "4px",
              }}
              key={"button234982734"}
            >
              <BorderStyleIcon
                style={{ fontSize: "1.8rem" }}
                key={"icon09872347896234"}
              />
            </Button>
          </Tooltip>
        </Grid>
      );

      // Create switcher buttons
      this.state.cameras.forEach((camera, i) => {
        buttons.push(
          <Grid style={{ display: "inline" }} key={"SCF" + i}>
            <Tooltip
              title={
                <>
                  {"Toggle " + camera.label + " Camera Display"}
                  <br />
                  {"Connection: " +
                    camera.rtcConnection?.connectionState?.toUpperCase()}
                  <br />
                  {"Stream: " + (camera.isStreamActive ? "LIVE" : "INACTIVE")}
                </>
              }
              placement="bottom"
              key={"tooltip9280734987234"}
            >
              <Badge
                variant="dot"
                invisible={camera.isStreamActive ? false : true}
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                color="primary"
                key={"badge9208374982734"}
              >
                <Button
                  onClick={(e) => this.switchDisplay(i, true)}
                  value={i}
                  style={{
                    backgroundColor: camera.color,
                    padding: 0,
                    opacity: camera.enabled ? 1.0 : 0.35,
                    padding: "3px",
                    minWidth: "32px",
                    height: "32px",
                    marginRight: "4px",
                  }}
                  variant="contained"
                  key={"button098723498y234"}
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: "800",
                    }}
                    key={"typo9028374829374"}
                  >
                    {camera.label}
                  </Typography>
                </Button>
              </Badge>
            </Tooltip>
          </Grid>
        );
      });

      // Spacer
      buttons.push(
        <Grid
          style={{ display: "inline", padding: "16px" }}
          key={"SCGBS"}
        ></Grid>
      );

      // full screen
      if (ScreenFull.isEnabled) {
        buttons.push(
          <Grid style={{ display: "inline" }} key={"SFESDF"}>
            <Tooltip title="Fullscreen" key={"tooltip0923874982y7349"}>
              <Button
                onClick={(e) => this.goFullScreen(4)}
                color={"secondary"}
                variant="contained"
                style={{
                  padding: "3px",
                  minWidth: "32px",
                  height: "32px",
                  marginRight: "4px",
                }}
                key={"button0928374098623498723"}
              >
                <AspectRatioIcon
                  style={{ fontSize: "1.8rem" }}
                  key={"icon093847982347982374"}
                />
              </Button>
            </Tooltip>
          </Grid>
        );
      }

      // Audio Out
      buttons.push(
        <Grid style={{ display: "inline" }} key={"Grid0892394283748237"}>
          <AudioOutputSelect
            activeSinkId={this.state.sinkId}
            setActiveSinkId={this.setSinkId}
            style={{
              padding: "3px",
              minWidth: "32px",
              height: "32px",
              marginRight: "4px",
            }}
            key={"AudioOutSelect23o04u8"}
          />
        </Grid>
      );

      // Toggle Sketches
      buttons.push(
        <Grid style={{ display: "inline" }} key={"grid0982734876234786"}>
          <Tooltip title="Toggle Sketches" key={"tooltip098234kjhn23"}>
            <Button
              onClick={(e) =>
                this.setState({ showSketches: !this.state.showSketches })
              }
              color={this.state.showSketches ? "primary" : "inherit"}
              variant="contained"
              style={{
                padding: "3px",
                minWidth: "32px",
                height: "32px",
                marginRight: "4px",
              }}
              key={"button2098374892734"}
            >
              <GestureIcon
                style={{ fontSize: "1.8rem" }}
                key={"Icon9082374970y34r"}
              />
            </Button>
          </Tooltip>
        </Grid>
      );

      return buttons;
    }
  }

  // Return main display
  mainPieces(classes) {
    // Create video displays
    let viewers = [];
    // let viewerControls = [];
    for (let i = 0; i < this.state.cameras.length; i++) {
      viewers.push(this.viewerPieces(classes, i));
    }

    // Return video displays
    return (
      <Grid
        container
        id="confidence_monitors"
        justifyContent="center"
        justifyItems="center"
        alignContent="start"
        alignItems="start"
        sx={{
          flex: 1
        }}
        >
        {viewers}
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    // return this.mainPieces(classes)
    return (
      <Grid 
        container
        direction="column"
        sx={{
          flex: 1
        }}
      >
        <Grid>
          <Grid
            container
            justifyContent="center"
            justifyItems="center"
          >
          {this.switcherPieces(classes)}
          </Grid>
        </Grid>

        <Grid
          sx={{
            flex: 1,
            width: "100%"
          }}
        >
          {this.mainPieces(classes)}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(withSnackbar(ConfidenceMonitor));
