import { useTheme } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Debug from "debug";
const debug = Debug("SS:VideoChat:AudioLevelMeterWide");
import useEvents from "../../../Context/Events/useEvents";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";

function AudioLevelMeterWide({
  active = true,
  offscreen = false,
  peerId,
  stream,
  track,
  width,
  height,
}: {
  active?: boolean;
  offscreen?: boolean;
  peerId?: string;
  stream?: MediaStream;
  track?: MediaStreamTrack;
  width?: number;
  height?: number;
}) {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  const runAvgs = useRef<number[]>([0]);
  const minDb = -80; // Was -100
  const vadInt = 20; // ms : How often we update the VAD RMS measurement
  const vadIter = 20; // Count : How many iterations we average to set VAD level
  // $vadInt * $vadIter = RMS Window length for VAD
  // 50 ms * 20 iterations = 1 Sec. Window

  const audioContext = useRef<AudioContext>();
  const [analyser, setAnalyser] = useState<AnalyserNode>();

  const { vadList } = useVideoChat();
  const theme = useTheme();
  const { dispatch } = useEvents();

  // Callbacks
  const initializeAnalyser = useCallback(() => {
    try {
      debug("Initializing analyser");

      if (!audioContext.current || audioContext.current.state === "closed") {
        debug("Creating new audio context to initialize");

        audioContext.current = new AudioContext();
      }

      let tracks: MediaStreamTrack[] = [];

      if (track) {
        tracks.push(track);
      } else if (stream) {
        tracks = stream.getAudioTracks();
      }

      if (tracks.length > 0) {
        if (
          audioContext.current &&
          audioContext.current.state === "suspended"
        ) {
          debug("Resuming context on init");
        
          audioContext.current.resume().catch((error) => {
            debug(
              "Error resuming context on init",
              error,
              audioContext.current
            );
          });
        }

        const audioSource = audioContext.current.createMediaStreamSource(
          new MediaStream(tracks)
        );

        const analyser = audioContext.current.createAnalyser();
        analyser.smoothingTimeConstant = 0.6;
        analyser.fftSize = 128;
        analyser.minDecibels = minDb; // Was -100
        analyser.maxDecibels = 0;

        audioSource.connect(analyser);

        debug("Analyser created ");
        setAnalyser(analyser);
      }
    } catch (error) {
      debug("Error creating audio analyser", error);
    }
  }, [audioContext, stream, track]);

  const clearMeter = useCallback(() => {
    if (ctxRef.current && ref.current) {
      ctxRef.current.clearRect(0, 0, ref.current.width, ref.current.height);
    }
  }, [ctxRef, ref]);

  // Effects
  useEffect(() => {
    if (audioContext.current && audioContext.current.state === "suspended") {
      debug("Resume audio context");
      audioContext.current.resume().catch((error) => {
        debug("Error resuming audioContext on load", error);
      });
    } else {
      debug("Initializing new Analyser ");
      initializeAnalyser();
    }

    return () => {
      if (audioContext.current && audioContext.current.state !== "closed") {
        debug("Audio Context Closing in cleanup");
        audioContext.current
          .close()
          .then(() => {
            audioContext.current = undefined;
          })
          .catch((error) => {
            debug("Error closing audioContext on cleanup", error);
          });
      }
    };
  }, [audioContext]);

  useEffect(() => {
    if (
      active && 
      ((stream && stream.getAudioTracks().length > 0) || track)
    ) {
      const reinitializeAnalyser = () => {
        debug("Reinitializing analyser");
        initializeAnalyser();
      };

      initializeAnalyser();

      // Reinitialize the AnalyserNode on focus to ensure it's always active
      window.addEventListener("focus", reinitializeAnalyser);
      return () => {
        window.removeEventListener("focus", reinitializeAnalyser);
      };
    } else {
      debug("Suspend audio context?");
      if (
        !active &&
        audioContext.current &&
        audioContext.current.state !== "closed"
      ) {
        debug("Audio Context Suspending ");
        audioContext.current.suspend().catch((error) => {
          debug("Error suspending audioContext if inactive or no track", error);
        });
      }
    }
  }, [
    active, 
    stream, track, initializeAnalyser, audioContext]);

  // Handle updating track events
  useEffect(() => {
    const handleNewTrack = (event: { track: MediaStreamTrack }) => {
      debug("New Track Event received", event);
      const newTrack = event.track;

      if (newTrack.kind === "audio") {
        debug("Replacing audio track");
        initializeAnalyser();
      }
    };

    dispatch?.on("newTrack", handleNewTrack);
    return () => {
      dispatch?.off("newTrack", handleNewTrack);
    };
  }, [dispatch, initializeAnalyser]);

  useEffect(() => {
    let animationFrame: number;
    let lastAvgRecord = 0;

    const updateMeter = (timestamp: number) => {
      if (!ctxRef.current && ref.current) {
        ctxRef.current = ref.current.getContext("2d");
      }

      if (active && analyser && ref.current && ctxRef.current) {
        if (!offscreen) {
          clearMeter();
        }

        const sampleArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(sampleArray);
        const sum = sampleArray.reduce((p, c) => p + c * c, 0);
        const rms = Math.sqrt(sum / sampleArray.length);
        const percent = (rms / Math.abs(minDb)) * 1.1; // * 1.1 to add "gain" to the meter for better display range

        // Update VAD
        if (peerId && runAvgs.current && timestamp - lastAvgRecord > vadInt) {
          runAvgs.current.unshift(percent);
          if (runAvgs.current.length > vadIter) {
            // Only store the last ${vadIter} number of values to average
            runAvgs.current.splice(vadIter);
          }
          if (vadList && vadList.current) {
            vadList.current.set(
              peerId,
              runAvgs.current.reduce((p, c) => p + c, 0) /
                runAvgs.current.length
            );
          }
          lastAvgRecord = timestamp;
        }

        // Render RMS to Meter
        if (!offscreen && rms !== 0 && ctxRef.current) {
          // Reset to active meter default look
          ctxRef.current.fillStyle = theme.palette.primary.main;
          ctxRef.current.fillRect(0, 0, 12, ref.current.height);

          ctxRef.current.clearRect(
            12,
            0,
            ref.current.width,
            ref.current.height
          );
          ctxRef.current.strokeStyle = theme.palette.primary.main;
          ctxRef.current.lineWidth = 6;
          ctxRef.current.lineCap = "round";
          for (let i = 0; i < ref.current.width * percent; i += 10) {
            // Set colors for peak levels
            if (i > ref.current.width * 0.85) {
              ctxRef.current.strokeStyle = theme.palette.error.main;
            } else if (i > ref.current.width * 0.65) {
              ctxRef.current.strokeStyle = theme.palette.warning.main;
            }

            // Draw meters
            ctxRef.current.beginPath();
            ctxRef.current.moveTo(i, 0);
            ctxRef.current.lineTo(i, ref.current.height);
            ctxRef.current.stroke();
          }
        }

        if (active) {
          animationFrame = requestAnimationFrame(updateMeter);
        }
      }
    };

    if (active && analyser) {
      debug("Starting Meter");
      animationFrame = requestAnimationFrame(updateMeter);
    } else if (ctxRef.current) {
      clearMeter();
    }
    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [analyser, active, offscreen, runAvgs, vadList, peerId]);

  return (
    <Grid>
      <canvas
        width={width ? width + "px" : "100%"}
        height={height ? height + "px" : "100%"}
        ref={ref}
        style={{
          borderRadius: 8,
          backgroundColor: "#000000",
          width: width ? width + "px" : "100%",
          height: height ? height + "px" : "100%",
        }}
      >
        ----------
      </canvas>
    </Grid>
  );
}

export default React.memo(AudioLevelMeterWide);
