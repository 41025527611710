import React, { useEffect, useState } from 'react';

export default function useMaxViewers(props) {
  const [maxViewers, setMaxViewers] = useState(0);

  useEffect(() => {
    let newUserCount = 0;
    if (props.activeSubs && props.activeSubs.length > 0 ) {
      props.activeSubs.forEach((sub, i) => {
        newUserCount += sub.max_allowed_users
      });
    }
    if (props.activePasses && props.activePasses.length > 0) {
      props.activePasses.forEach((pass, i) => {
        newUserCount = (props.activeSubs ?
          (newUserCount + pass.max_allowed_users) :
          pass.max_allowed_users
        );
      });
    }
    setMaxViewers(newUserCount);
  }, [props.activePasses, props.activeSubs]);

  return maxViewers;
}
