// Functional items
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import useDashboardAccount from "./Context/useDashboardAccount";

// Custom Components
import ProjectsTab from "./Tabs/dashboard_projects_tab";
import PassesTab from "./Tabs/dashboard_passes_tab";
import SubscriptionsList from "./Tabs/dashboard_subscriptions_tab";
import BrandingTab from "./Tabs/dashboard_branding_tab";

// Material UI
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AppBar, Tabs, Tab, Backdrop, CircularProgress } from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      container
      hidden={value !== index}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      justifyContent="center"
      sx={{
        height: "calc(100vh - 84px)",
        width: "100%",
        overflow: "auto",
        display: value === index ? "flex" : "none",
      }}
    >
      <Grid
        sx={{
          width: "100%",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {},
    tabBar: {
      paddingBottom: "8px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(2),
    },
  })
);

export default function DashboardPortal(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // User Data
  const {
    uuid,
    customer_uuid,
    subscriptions,
    projects,
    archivedProjects,
    passes,
    availablePasses,
    scheduledPasses,
    activePasses,
    expiredPasses,
    isPartner,
    isLoading,
  } = useDashboardAccount();

  let parsedURL = window.location.pathname.split("/");
  let id = "";
  if (parsedURL.length > 2 && parsedURL[2] !== "") {
    id = parsedURL[2];
  }

  let storedTab = localStorage.getItem(id + "_tab");
  const [selectedTab, setSelectedTab] = useState(
    storedTab ? storedTab : "Projects"
  );

  useEffect(() => {
    // Show an error if uuid does not exist
    if (!customer_uuid) {
      enqueueSnackbar(
        "Looks like your account is being setup.  Please refresh the page after a minute and try again.",
        { variant: "warning" }
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(uuid + "_tab", selectedTab);
  }, [selectedTab]);

  const changeTabs = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <AppBar position="relative" color="transparent" key={"APPDFBS"}>
          <Tabs
            value={selectedTab}
            onChange={changeTabs}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant="fullWidth"
            className={classes.tabBar}
          >
            <Tab
              label="Projects"
              icon={<AccountTreeIcon />}
              style={{ minWidth: "75px" }}
              value="Projects"
            />
            <Tab
              label="Subscriptions"
              icon={<CardMembershipIcon />}
              style={{ minWidth: "75px" }}
              value="Subscriptions"
            />
            <Tab
              label="Passes"
              icon={<ConfirmationNumberIcon />}
              style={{ minWidth: "75px" }}
              value="Passes"
            />
            <Tab
              label="Branding"
              icon={<PhotoLibraryIcon />}
              style={{ minWidth: "75px" }}
              value="Branding"
            />
          </Tabs>
        </AppBar>

        <TabPanel label="Projects" value={selectedTab} index="Projects">
          <ProjectsTab cssClasses={props.cssClasses} />
        </TabPanel>

        <TabPanel
          label="Subscriptions"
          value={selectedTab}
          index="Subscriptions"
        >
          <SubscriptionsList cssClasses={props.cssClasses} />
        </TabPanel>

        <TabPanel label="Passes" value={selectedTab} index="Passes">
          <PassesTab cssClasses={props.cssClasses} />
        </TabPanel>

        <TabPanel label="Branding" value={selectedTab} index="Branding">
          <BrandingTab cssClasses={props.cssClasses} />
        </TabPanel>

        <Backdrop open={isLoading} style={{ zIndex: 2001 }}>
          <CircularProgress color="primary" variant="indeterminate" />
        </Backdrop>
      </Grid>
    </Grid>
  );
}
