import { useEffect, useMemo, useState } from 'react';
import { useVideoChat } from '../../VideoChatProvider/VideoChatProvider';
import Debug from "debug"; const debug = Debug("SS:VideoChat:Camera:useAudioInputDevices");

export default function useAudioInputDevices(
  label: string,
  publisherUuid: string,
  audioInputDevices: MediaDeviceInfo[]
) {
  const [audioDeviceId, setAudioDeviceId] = useState<string>("");
  const [audioChannels, setAudioChannels] = useState<number>(1);
  const [echoCancellation, setEchoCancellation] = useState<boolean>(true);
  const [audioProcessing, setAudioProcessing] = useState<"noiseSuppression" | "voiceIsolation" | "none">("none")
  const [autoGainControl, setAutoGainControl] = useState<boolean>(true)

  const { withAudio } = useVideoChat()

  const getAudioConstraints = useMemo(() => {
    debug("Creating audio constraints", audioDeviceId, audioChannels, echoCancellation, audioProcessing, autoGainControl, withAudio)
    // Create media constraints object
    const availableConstraints = navigator.mediaDevices.getSupportedConstraints();

    let audioC: MediaTrackConstraints | false = false;
    if (withAudio && audioDeviceId !== "") {
      audioC = {
        deviceId: { exact: audioDeviceId }
      }

      // Per Browser
      if (availableConstraints.autoGainControl) {
        audioC.autoGainControl = autoGainControl
      }
      if (availableConstraints.echoCancellation) {
        audioC.echoCancellation = echoCancellation
      }
      if (availableConstraints.noiseSuppression) {
        audioC.noiseSuppression = audioProcessing === "noiseSuppression"
      }
      // @ts-expect-error In Chrome but not in Spec
      if (availableConstraints.voiceIsolation) {
        // @ts-expect-error In Chrome but not in Spec
        audioC.voiceIsolation = audioProcessing === "voiceIsolation"
      }
      if (availableConstraints.channelCount) {
        audioC.channelCount = {
          max: 8,
          ideal: audioChannels,
          min: 1
        }
      }
    }

    debug("Audio Constraints Created", audioC)
    return audioC;
  }, [audioDeviceId, audioChannels, echoCancellation, audioProcessing, autoGainControl, withAudio])

  useEffect(() => {
    try {
      const storedAudioDeviceId = window.localStorage?.getItem(publisherUuid + '_' + label + '_audioDeviceId');
      if (storedAudioDeviceId) { setAudioDeviceId(storedAudioDeviceId) }

      const storedAudioChannels = window.localStorage?.getItem(publisherUuid + '_' + label + '_audioChannels');
      if (storedAudioChannels) { 
        setAudioChannels(Number(storedAudioChannels)) 
      }

      const storedEchoCancellation = window.localStorage?.getItem(publisherUuid + '_' + label + '_echoCancellation');
      if (storedEchoCancellation) { 
        setEchoCancellation(storedEchoCancellation === "true") 
      }

      const storedAudioProcessing = window.localStorage?.getItem(publisherUuid + '_' + label + '_audioProcessing');
      if (
        storedAudioProcessing === "noiseSuppression" || 
        storedAudioProcessing === "voiceIsolation" ||
        storedAudioProcessing === "none"
      ) { 
        setAudioProcessing(storedAudioProcessing)
      }

      const storedAutoGainControl = window.localStorage?.getItem(publisherUuid + '_' + label + '_autoGainControl');
      if (storedAutoGainControl) {
        setAutoGainControl(storedAutoGainControl === "true")
      }
      
    } catch (error) {
      console.log("Local Storage not allowed or errored Audio - ", error);
    }
  }, []);

  useEffect(() => {
    if (
      (audioDeviceId === "" ||
        audioInputDevices.findIndex(device => device.deviceId === audioDeviceId) === -1) &&
      audioInputDevices.length > 0 &&
      audioInputDevices[0].deviceId
    ) {
      // Default to first device in the list
      updateAudioDeviceId(audioInputDevices[0].deviceId)
    }
  }, [audioInputDevices, audioDeviceId]);


  const updateAudioDeviceId = (id: string) => {
    setAudioDeviceId(id);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_audioDeviceId',
      id
    );
  }

  const updateAudioChannels = (channels: number) => {
    setAudioChannels(channels);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_audioChannels',
      channels.toString()
    );
  }

  const updateEchoCancellation = (checked: boolean) => {
    setEchoCancellation(checked);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_echoCancellation',
      checked.toString()
    );
  }

  const updateAudioProcessing = (processing: string) => {
    if (
      processing === "noiseSuppression" || 
      processing === "voiceIsolation" ||
      processing === "none"
    ) {
      setAudioProcessing(processing)
      window.localStorage.setItem(
        publisherUuid + '_' + label + '_audioProcessing',
        processing
      );
    }
  }

  const updateAutoGainControl = (checked: boolean) => {
    setAutoGainControl(checked);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_autoGain',
      checked.toString()
    );
  }

  return {
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    audioProcessing,
    updateAudioProcessing,
    autoGainControl,
    updateAutoGainControl,
    getAudioConstraints
  };
}
