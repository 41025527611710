export interface VideoBitrates {
  id: number,
  bitrate: number, // In kbps
  label: string,
}

// A bitrate of 0 is used to represent a custom entry
export const videoBitrates: VideoBitrates[] = [
  {
    id: 1,
    bitrate: 800,
    label: '800 Kbps'
  },
  {
    id: 2,
    bitrate: 1200,
    label: '1.2 Mbps'
  },
  {
    id: 3,
    bitrate: 1500,
    label: '1.5 Mbps'
  },
  {
    id: 4,
    bitrate: 2000,
    label: '2 Mbps'
  },
  {
    id: 0,
    bitrate: 3000,
    label: 'Custom'
  }
]
