import Debug from "debug";
const debug = Debug("SS:App");
if (process.env.REACT_APP_ENV === "development") {
  Debug.enable(process.env.REACT_APP_DEBUG);
} else {
  Debug.disable;
}
import React, { useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { useTheme } from "@mui/styles";

// Material UI
import { Box, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

// Utility Libraries
import { SnackbarProvider } from "notistack";
import adapter from "webrtc-adapter";

// Routes
import ClientViewer from "../../Viewers/client_portal";
import ProjectView from "../../Project/project_portal";
import SiteAdmin from "../../Admin/admin_portal";
import SiteAdminLogin from "../../Admin/admin_sign_in";
import DashboardPortal from "../../Dashboard/dashboard_portal";
import AccountLogin from "../../Dashboard/Account/account_login";
import ComponentErrorBoundary from "../../../errors/component_error_boundary";
import TvOSAuthorize from "../../tvOS/tvOS_Authorize";

// Context
import { DashboardAccountProvider } from "../../Dashboard/Context/DashboardAccountProvider";

// Utility
import useCustomCSS from "../../../themes/useCustomCSS";

// ************** APP ****************
export default function AppContent(props) {
  const classes = useCustomCSS();
  const theme = useTheme();

  // Dynamic app bar content
  const [projectName, setProjectName] = useState("");
  const [switcherControls, setSwitcherControls] = useState([]);
  const [chatControls, setChatControls] = useState([]);
  const [videoControls, setVideoControls] = useState([]);
  const [room, setRoom] = useState();
  const [viewerId, setViewerId] = useState();

  // Snackbar handlers
  const notistackRef = React.createRef();
  const closeSnack = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // Render Functions
  let content = (
    <Switch>
      <Route
        path="/Producer"
        render={() => (
          <DashboardAccountProvider>
            <DashboardPortal cssClasses={classes} />
          </DashboardAccountProvider>
        )}
      />
      {process.env.REACT_APP_ENV === "development" && (
        <Route
          path="/Account/Login"
          render={() => <AccountLogin cssClasses={classes} />}
        />
      )}
      <Route
        path="/Project"
        render={() => (
          <ProjectView
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter}
            cssClasses={classes}
          />
        )}
      />
      <Route
        path="/Broadcast"
        render={() => (
          <ProjectView
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter}
            cssClasses={classes}
          />
        )}
      />
      <Route
        path="/VideoVillage"
        render={() => (
          <ClientViewer
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter}
            cssClasses={classes}
          />
        )}
      />
      <Route
        path="/tvos"
        render={() => <TvOSAuthorize cssClasses={classes} />}
      />
      <Route
        exact
        path="/ssadmin"
        render={() => <SiteAdminLogin cssClasses={classes} />}
      />
      <Route
        path="/site_admin"
        render={() => <SiteAdmin cssClasses={classes} />}
      />
      {/* Do not delete - Do not enable */}
      {/* <Route
        path = '/register'
        render = {() => <SignUp cssClasses={classes} />}
      /> */}
      <Route
        path="*"
        render={() =>
          (window.location.href = "https://setstream.io/my-account/projects/")
        }
      />
    </Switch>
  );

  return (
    <ComponentErrorBoundary section="Root" showErrors={true}>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        dense={true}
        ref={notistackRef}
        classes={{
          variantSuccess: classes.notiSuccess,
          variantInfo: classes.notiInfo,
          variantError: classes.notiError,
          variantWarning: classes.notiWarning,
        }}
        action={(key) => (
          <IconButton onClick={closeSnack(key)} size="large">
            <CancelIcon />
          </IconButton>
        )}
      >
        <BrowserRouter>
          <Box
            id="appBase"
            sx={{
              height: "100vh",
              "@supports (height: 100dvh)": {
                height: "100svh",
              },
              overflow: "hidden",
            }}
          >
            {content}
          </Box>
        </BrowserRouter>
      </SnackbarProvider>
    </ComponentErrorBoundary>
  );
}
