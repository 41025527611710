import { useMemo } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { VideoChatProvider } from "../../Context/VideoChatProvider/VideoChatProvider";
import { CameraProvider } from "../../Context/CameraProvider/CameraProvider";
import { EventsProvider } from "../../Context/Events/EventsProvider";
import { MillicastProvider } from "../../Context/MillicastProvider/MillicastProvider";
import PeerList from "../PeerList/PeerList";
import MyControls from "../MyControls/MyControls";
import Me from "../Me/Me";
import { Members, PresenceChannel } from "pusher-js";
import EventEmitter from "events";
import { useMediaQuery } from "@mui/material";

export default function VideoChat({
  publisherUuid,
  userUuid,
  nickName,
  updateNickName,
  chatCollapsed,
  toggleChatCollapsed,
  members,
  presenceRoom,
  dispatch,
}: {
  publisherUuid?: string;
  userUuid: string;
  nickName: string;
  updateNickName: (nickName: string) => void;
  chatCollapsed: boolean;
  toggleChatCollapsed: () => void;
  members: Members;
  presenceRoom: PresenceChannel;
  dispatch: EventEmitter;
}) {
  const isLandscape = useMediaQuery("(orientation:landscape)")

  return (
    <Grid
      container
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "background.paper",
        flexDirection: {
          xs: isLandscape ? "column" : "row",
          sm: "row"
        },
        "@media (orientation:landscape)": {
          flexDirection: "column"
        }
      }}
    >
      <EventsProvider dispatch={dispatch}>
        <VideoChatProvider
          publisherUuid={publisherUuid}
          userUuid={userUuid}
          displayName={nickName}
          updateDisplayName={updateNickName}
          chatCollapsed={chatCollapsed}
          toggleChatCollapsed={toggleChatCollapsed}
          members={members}
          presenceRoom={presenceRoom}
        >
          <CameraProvider>
            <MillicastProvider>
              <MyControls />
              <Me />
              <PeerList />
            </MillicastProvider>
          </CameraProvider>
        </VideoChatProvider>
      </EventsProvider>
    </Grid>
  );
}
