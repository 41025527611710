import Debug from "debug";import { useSnackbar } from "notistack";
 const debug = Debug("SS:VideoChat:useMediaDevices")
import { useEffect, useState } from 'react';

export enum DevicePermission {
  allowed = "allowed",
  denied = "denied",
  unknown = "unknown",
}

export default function useMediaDevices() {

  // Video
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);

  const [videoInputPermission, setVideoInputPermission] = useState<DevicePermission>(DevicePermission.unknown);
  const [audioInputPermission, setAudioInputPermission] = useState<DevicePermission>(DevicePermission.unknown);
  const [audioOutputPermission] = useState<DevicePermission>(DevicePermission.unknown);

  const [gettingDevices, setGettingDevices] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const dispatchGetAvailableDevices = () => {
      getAvailableDevices()
      .catch(error => {
        debug("Could not get available devices ", error);
      })
    }

    // Get devices on load
    // dispatchGetAvailableDevices()

    // Listen for device changes
    navigator.mediaDevices?.addEventListener('devicechange', dispatchGetAvailableDevices);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', dispatchGetAvailableDevices);
    };
  }, []);

  const getAvailableDevices = async () => {
    try {
      debug("Get Available Devices")
      if (!navigator.mediaDevices) {
        enqueueSnackbar("It appears your browser does not support getting media devices.  If you are on iOS try using Safari or Chrome.")
        throw new Error("It appears your browser does not support getting media devices.  If you are on iOS try using Safari or Chrome.")
      }
      setGettingDevices(true);

      // Enumerate and Filter Devices
      const mediaDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
      if (!mediaDevices) { throw new Error('Broadcasting is not supported') }

      const vDevices = mediaDevices.filter(device => device.kind === 'videoinput');
      let vDeviceLabel;
      if (vDevices?.length > 0) {
        // Labels and permissions
        if (vDevices[0].label !== "") {
          setVideoInputPermission(DevicePermission.allowed)
        } else {
          vDeviceLabel = vDevices[0]?.label;
        }
        // Store
        setVideoInputDevices(vDevices)
      }

      const aInputDevices = mediaDevices.filter(device => device.kind === 'audioinput');
      let aInputLabel;
      if (aInputDevices?.length > 0) {
        // Labels and permissions
        if (aInputDevices[0]?.label !== "") {
          setAudioInputPermission(DevicePermission.allowed);
        } else {
          aInputLabel = aInputDevices[0]?.label
        }
        // Store
        setAudioInputDevices(aInputDevices);
      }

      const aOutputDevices = mediaDevices.filter(device => device.kind === 'audiooutput');
      if (aOutputDevices) { setAudioOutputDevices(aOutputDevices) }

      // Check if we have permission by checking for a device label
      if (
        !vDeviceLabel ||
        !aInputLabel
      ) {
        await getDeviceNames(
          vDeviceLabel === '' ? true : false,
          aInputLabel === '' ? true : false
        );
      }
    } catch (error) {
      debug('We encountered an issue - ', error);
      // TODO: Consider logging to server??????
    } finally {
      setGettingDevices(false)
    }
  }

  const getDeviceNames = async (video: boolean, audio: boolean) => {
    if (!video && !audio) { return }
    let stream: MediaStream | null = null;
    try {

      // Get a media stream track to force the browser to ask for permission
      // then return an updated list of devices with device names included
      stream = await navigator.mediaDevices.getUserMedia({
        video: video,
        audio: audio
      });

      await getAvailableDevices();

    } catch (error) {
      
      debug('Could not get device names ', error);
      switch ((error as Error).name) {
        case 'OverconstrainedError':
          debug("%s Constraint Error - %s", (error as OverconstrainedError).constraint, (error as OverconstrainedError).message)
          // enqueueSnackbar(
          //   error.constraint + " Settings Error while getting device names - " + error.message, 
          //   { variant: "error" }
          // );
          break;

        case 'NotAllowedError':
          if (video) { setVideoInputPermission(DevicePermission.denied) }
          if (audio) { setAudioInputPermission(DevicePermission.denied) }
          debug("Media permissions were denied ", error)
          enqueueSnackbar("Device Permissions Denied.  Please check your browser settings", { variant: "warning" })
          break;

        case 'AbortError':
        // Nothing seemed wrong but we failed.
        // Try again
        break;
        case 'NotFoundError':
        // We succeeded but got no tracks
        // Notify user to update settings
        break;
        case 'NotReadableError':
        // Something went wrong with the HW/OS/App
        // Try once more then notify?
        break;
        case 'SecurityError':
        // user media is disabled at the document level
        // TODO: Research if there are browser settings that do this
        // and the specifics of each browser
        break;
        case 'TypeError':
        // Our constraints are empty
        // Or the context is insecure (shouldn't happen outside of dev ever)
        // And an error should have already prevented us from reaching this point
          break;
        default:
          debug("Error gathering device names ", error)
          break;
      }
    } finally {
      // Cleanup stream
      setTimeout(() => {
        stream?.getTracks().forEach(track => {
          track.stop();
        });
        stream = null;
      }, 15000);
    }
  }


  return {
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission,
    getAvailableDevices,
    gettingDevices
  };
}
