import Debug from "debug";
const debug = Debug("SS:VideoChat:PeerAvatar");
import { useMemo, useEffect } from "react"
import { useMillicast } from "../../Context/MillicastProvider/MillicastProvider";
// import useCamera from "../../Context/CameraProvider/useCamera";

export default function PeerAvatar() {
  const {
    avatarRef,
    publish
  } = useMillicast()

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const draw = () => {
      if (!avatarRef || !avatarRef.current) { return }

      const ctx = avatarRef.current.getContext("2d")
      if (ctx) {
        // ctx.reset() // Support is too new (2023 on safari)
        ctx.save();
        ctx.fillStyle = "#FFFFFF";
        ctx.strokeStyle = "#FFFFFF";

        const avatarPath = new Path2D("M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0S112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z")
        ctx.fill(avatarPath)
      }
    };

    if (!avatarRef || !avatarRef.current) { return }

    const ctx = avatarRef.current.getContext("2d")
    if (ctx) {
      draw()

      timeout = setInterval(draw, 1000)

      if (publish && publish.isActive()) {
        const stream = avatarRef.current.captureStream()
        const tracks = stream.getVideoTracks()
        if (tracks.length > 0) {
          const wPeer = publish.webRTCPeer
          if (wPeer) {
            debug("Replacing track", tracks[0], tracks[0].readyState, tracks[0].muted)
            wPeer.replaceTrack(tracks[0])
          }
        }
      }

      return () => {
        if (timeout) clearTimeout(timeout)
      }
    }

  }, []);


  const renderer = useMemo(() => {
    return (
      <canvas
        ref={avatarRef}
        height={512}
        width={512}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        Avatar
      </canvas>
    )
  }, [])

  return renderer
}