import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
CircularProgress,
Button,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
Typography
} from '@mui/material/';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function WaitingRoomNewCode(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [inProgress, setInProgress] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  // Effects

  // Actions
  const createCode = async () => {
    setInProgress(true);
    try {
      let fetchUrl = process.env.REACT_APP_API_URL + '/api/waiting_room/list/' + props.publisher_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        credentials: "include"
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        setInProgress(false);
        
        // Store response in state
        if (response.waitingRoomCode) { props.setWaitingRoomCode(response.waitingRoomCode) }
        if (response.waitingRoomHash) { props.setWaitingRoomHash(response.waitingRoomHash) }

      } else {
        console.error('Could not retrieve data ', res.status);
      }  
    } catch (error) {
      console.error('Network error getting data ', error);
    } finally {
      setOpenConfirm(false)
      setInProgress(false)
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>
        
        <Button
          onClick={() => setOpenConfirm(true)}
          disabled={inProgress}
        >
          New Code
        </Button>

        <Dialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
        >
          <DialogTitle
            className={props.cssClasses.dtitle}
          >
            <Typography
              variant='h5'
            >
              Generate New Code
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Grid container className={props.cssClasses.settingsContainer}>
              <Grid item xs={12}>
                <Typography
                  paragraph
                >
                  This will generate a new code and embedded link.
                </Typography>
                <Typography
                  paragraph
                  variant='h6'
                >
                  Existing access code and embedded links will no longer work.
                </Typography>

                <Typography
                  paragraph
                  variant='h6'
                >
                  Viewers currently using the existing code will be logged out and will need to relogin with the new link.
                </Typography>

                <Typography>
                  Are you sure you would like to continue?
                </Typography>

                {
                  inProgress &&
                  <CircularProgress color="primary" variant="indeterminate" />
                }
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions >
            <Button
              id = 'cancel'
              onClick = {() => setOpenConfirm(false)}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              id = 'newCode'
              onClick = {createCode}
              variant="contained"
              color="primary"
            >
              Generate New Code
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );

}
