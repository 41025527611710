import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import {
Dialog,
DialogTitle,
DialogContent,
DialogContentText,
DialogActions,
TextField,
Input,
Button,
Table,
TableContainer,
TableHead,
TableBody,
TableRow,
TableCell,
FormControlLabel,
Switch,
Select,
MenuItem,
Backdrop,
CircularProgress,
} from '@mui/material/';
import axios from 'axios';

const useStyles = makeStyles((theme) => createStyles({
  redButton: {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));

export default function EditPassDialog(props) {
  const classes = useStyles();

  const [passToEdit, changePassToEdit] = useState(props.passToEdit);
  const [toReplace, updateToReplace] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  // Starting Values
  const [currentViewers, setCurrentViewers] = useState<number>(0);
  const [currentDuration, setCurrentDuration] = useState<number>(0);
  const [currentDurationUnits, setCurrentDurationUnits] = useState<string>('');
  const [currentName, setCurrentName] = useState<string>('');

  // Update Values
  const [updateViewers, setUpdateViewers] = useState<number>(0);
  const [updateDuration, setUpdateDuration] = useState<number>(0);
  const [updateDurationUnits, setUpdateDurationUnits] = useState<string>('');

  // Finish Values
  const [futureViewers, setFutureViewers] = useState<number>(0);
  const [futureDuration, setFutureDuration] = useState<number>(0);
  const [futureDurationUnits, setFutureDurationUnits] = useState<string>('');
  const [futureName, setFutureName] = useState<string>('');

  // Set current values
  useEffect(() => {
    if (props.passToEdit.max_allowed_users) {
      setCurrentViewers(Number(props.passToEdit.max_allowed_users))
      setFutureViewers(Number(props.passToEdit.max_allowed_users))
      setUpdateViewers(0)
    }
    if (props.passToEdit.active_duration) {
      setCurrentDuration(Number(props.passToEdit.active_duration))
      setFutureDuration(Number(props.passToEdit.active_duration))
      setUpdateDuration(0)
    }
    if (props.passToEdit.duration_units) {
      setCurrentDurationUnits(props.passToEdit.duration_units)
      // Default to hours
      if (
        props.passToEdit.duration_units.toLowerCase() !== 'hours' &&
        props.passToEdit.duration_units.toLowerCase() !== 'days'
      ) {
        setFutureDurationUnits('hours');
        setUpdateDurationUnits('hours');
      } else {
        setFutureDurationUnits(props.passToEdit.duration_units)
        setUpdateDurationUnits(props.passToEdit.duration_units)
      }
    }
    if (props.passToEdit.pass_type) {
      setCurrentName(props.passToEdit.pass_type)
      setFutureName(props.passToEdit.pass_type)
    }

  }, [props.isOpen, props.passToEdit]);

  // Update future Viewers value
  useEffect(() => {
    setFutureViewers(
      Number(toReplace ?
        Number(updateViewers) :
        Number(currentViewers) + Number(updateViewers)
      )
    )
  }, [updateViewers, toReplace]);

  useEffect(() => {
    /*
      If we are replacing the pass values, overwrite the future values
      If we are updating the pass values, combine and update future values
    */
    // Update Duration
    if (toReplace) {
      if (updateDuration !== futureDuration) {
        setFutureDuration(Math.round(Number(updateDuration)));
      }
    } else {

      // Make sure we're working with the same time units
      if (updateDurationUnits.toLowerCase() === currentDurationUnits.toLowerCase()) {
        if ((currentDuration + updateDuration) !== futureDuration) {
          setFutureDuration(Math.round(Number(currentDuration) + Number(updateDuration)));
        }
      } else {
        // Convert time units then combine
        let currentConvertedDuration:number;
        switch (updateDurationUnits.toLowerCase()) {
          case 'days':
            switch (currentDurationUnits.toLowerCase()) {
              case 'minutes':
              default:
                currentConvertedDuration = currentDuration / (60 * 24)
                break;
              case 'hours':
                currentConvertedDuration = currentDuration / 24
                break;
            }
          break;
          case 'hours':
          default:
            switch (currentDurationUnits.toLowerCase()) {
              case 'days':
                currentConvertedDuration = currentDuration * 24
              break;
              case 'minutes':
              default:
                currentConvertedDuration = currentDuration / 60
                break;
            }
            break;
        }

        // Update
        if ((currentConvertedDuration + updateDuration) !== futureDuration) {
          setFutureDuration(Math.round(Number(currentConvertedDuration) + Number(updateDuration)))
        }
      }
    }

    // Update Duration Units if needed
    if (updateDurationUnits !== futureDurationUnits) {
      setFutureDurationUnits(updateDurationUnits);
    }

  }, [updateDuration, updateDurationUnits, toReplace]);

  useEffect(() => {
    // Auto set future name
    setFutureName(futureViewers + ' Viewers - ' + futureDuration + ' ' + futureDurationUnits);
  }, [futureViewers, futureDuration, futureDurationUnits, toReplace])

  const modifyPass = async () => {
    setInProgress(true);
    try {
      let fetchUrl:string = process.env.REACT_APP_API_URL + '/api/admin_modify_pass/' + props.userName;
      let fetchOpts = {
        method:'POST',
        headers: {
          'Content-Type':'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          customer_uuid: props.customerUUID,
          pass_to_modify: props.passToEdit._id,
          new_max_allowed_viewers: futureViewers,
          new_pass_type: futureName,
          new_duration: futureDuration,
          new_duration_units: futureDurationUnits,
          to_replace: toReplace,
        })
      }

      let res = await fetch(fetchUrl, fetchOpts);
      if (res.ok) {
        setInProgress(false);
        props.toggleOpenPassEditor();
        props.onSuccess();

        enqueueSnackbar('Modified pass', {variant: 'info'});
        console.log('Successfully modified pass');
      } else {
        setInProgress(false);
        props.toggleOpenPassEditor();
        props.onSuccess();  // Refresh to reflect changes
        enqueueSnackbar('Something\'s not right. ' + res.status + res.statusText + ' Please double check the modified pass', { variant: 'warning' });
        console.error('Error updating pass ', res.status, res.statusText)
      }
    } catch (error) {
      setInProgress(false);
      props.toggleOpenPassEditor();
      props.onSuccess();  // Refresh to reflect changes
      enqueueSnackbar('Network error updating pass ' + error, { variant: 'error' });
      console.error('Network error updating pass', error);
    }
  }

  const deletePass = async () => {
    setInProgress(true);
    try {
      let fetchUrl:string = process.env.REACT_APP_API_URL + '/api/admin_deactivate_pass/' + props.userName;
      let fetchOpts = {
        method:'POST',
        headers: {
          'Content-Type':'application/json',
        },
        credentials: "include",
        body: JSON.stringify({
          customer_uuid: props.customerUUID,
          pass_to_modify: props.passToEdit._id
        })
      }

      let res = await fetch(fetchUrl, fetchOpts);
      if (res.ok) {
        let result = await res.json();

        setInProgress(false);
        props.toggleOpenPassEditor();
        props.onSuccess();

        enqueueSnackbar('Deactivated pass', {variant: 'info'});
        console.log('Successfully deactived pass ', result.message);
      } else {
        setInProgress(false);
        props.toggleOpenPassEditor();
        props.onSuccess();  // Refresh to reflect changes
        enqueueSnackbar('Something\'s not right. ' + res.status + res.statusText + ' Please double check the deactivated pass', { variant: 'warning' });
        console.error('Error deactivating pass ', res.status, res.statusText)
      }
    } catch (error) {
      setInProgress(false);
      props.toggleOpenPassEditor();
      props.onSuccess();  // Refresh to reflect changes
      enqueueSnackbar('Network error deactivating pass ' + error, { variant: 'error' });
      console.error('Network error deactivating pass', error);
    }
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.toggleOpenPassEditor}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">
        Editing Pass
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {props.passToEdit.pass_type} ( {props.passToEdit._id} )
        </DialogContentText>

        <TableContainer>
          <Table size="small">
            <TableHead>

              <TableRow>
                <TableCell>
                  -
                </TableCell>
                <TableCell>
                  Max Viewers
                </TableCell>
                <TableCell>
                  Duration
                </TableCell>
                <TableCell>
                  Pass Name
                </TableCell>
              </TableRow>

            </TableHead>
            <TableBody>

              <TableRow>
                <TableCell>
                  Current
                </TableCell>
                <TableCell>
                  {currentViewers}
                </TableCell>
                <TableCell>
                  {currentDuration + ' ' + currentDurationUnits}
                </TableCell>
                <TableCell>
                  {currentName}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <FormControlLabel
                    value={toReplace ? 'Replace' : 'Add On'}
                    control={
                      <Switch
                        color="primary"
                        checked={toReplace}
                        onChange={() => updateToReplace(!toReplace)}
                      />
                    }
                    label={toReplace ? 'Replace' : 'Add On'}
                    labelPlacement="bottom"
                  />
                </TableCell>
                <TableCell>
                  <Input
                    autoFocus
                    size="small"
                    id="max_allowed_users"
                    label="Viewers"
                    type="number"
                    fullWidth
                    value={updateViewers}
                    onChange = {(e) => setUpdateViewers(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    autoFocus
                    size="small"
                    id="active_duration"
                    label="Duration"
                    type="number"
                    fullWidth
                    value={updateDuration}
                    onChange = {(e) => setUpdateDuration(e.target.value)}
                  />
                  <Select
                    id="Time_Type"
                    value={updateDurationUnits}
                    onChange={(e) => setUpdateDurationUnits(e.target.value)}
                  >
                    <MenuItem value="hours">Hours</MenuItem>
                    {
                      (
                        currentDurationUnits.toLowerCase() === 'days' ||
                        toReplace
                      ) &&
                      <MenuItem value="days">Days</MenuItem>
                    }
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    autoFocus
                    size="small"
                    id="pass_type"
                    label="Pass Type"
                    type="text"
                    fullWidth
                    value={futureName}
                    onChange = {(e) => setFutureName(e.target.value)}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  Future
                </TableCell>
                <TableCell>
                  {futureViewers + ' Viewers'}
                </TableCell>
                <TableCell>
                  {futureDuration + ' ' + futureDurationUnits}
                </TableCell>
                <TableCell>
                  {futureName}
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={props.toggleOpenPassEditor}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.redButton}
          onClick={deletePass}
          style={{paddingLeft:'12px', paddingRight:'12px'}}
          >
            DEACTIVATE Pass
          </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={modifyPass}
        >
          MODIFY Pass
        </Button>
      </DialogActions>

      <Backdrop open={inProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>

  );

}
