// List of possible codecs
export type Codec = "h264" | "vp8" | "vp9" | "av1" | "hevc"

export interface VideoCodec {
  codec: Codec,
  label: string,
}

export const videoCodecs: VideoCodec[] = [
  {
    codec: "vp8",
    label: 'VP8 - Default'
  },
  {
    codec: "vp9",
    label: 'VP9 - iOS > 14 Required'
  },
  {
    codec: "av1",
    label: 'AV1 - Chrome Only'
  },
  {
    codec: "h264",
    label: 'h264'
  }
]
