import { ReactNode, useCallback, useState, useMemo, useEffect } from "react";
import Debug from "debug"; const debug = Debug("SS:VideoChat:ToggleVideoEnabled");
import {
  Tooltip,
  Button,
  IconButton,
  Alert,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";
import { VideoCameraFront, VideocamOff, ReportOff } from "@mui/icons-material";
import useCamera from "../../../Context/CameraProvider/useCamera";
import { DevicePermission } from "../../../Context/CameraProvider/Hooks/useMediaDevices";
import { useMillicast } from "../../../Context/MillicastProvider/MillicastProvider";

export default function ToggleVideoEnabled({
  variant = "button",
}: {
  variant?: "icon" | "button";
}) {

  const { 
    withVideo,
    setWithVideo,
    isVideoEnabled,
    setIsVideoEnabled
  } = useVideoChat()

  const { 
    videoTrack,
    videoInputPermission, 
    gettingVideoMedia, 
    gettingDevices,
  } = useCamera();

  const { 
    publish,
    avatarRef
  } = useMillicast()

  // Effects
  useEffect(() => {
    if (!withVideo) {
      setIsVideoEnabled(false)
    }
    else if (videoTrack && videoTrack.readyState === "live") {
      setIsVideoEnabled(videoTrack.enabled)
    }
  }, [videoTrack, withVideo])

  // Callbacks
  const toggleVideo = useCallback(() => {
    debug("Video Enabled? ", videoTrack?.enabled, videoTrack)
    if (!withVideo) {
      setWithVideo(true)
    }
    else if (videoTrack && videoTrack.readyState === "live") {
      videoTrack.enabled = !videoTrack.enabled
      setIsVideoEnabled(videoTrack.enabled)
      debug("Video Enabled updated?", videoTrack.enabled)
    }
  }, [videoTrack, withVideo]);

  useEffect(() => {
    if (publish && publish.isActive()) {
      
      if (videoTrack) {
        const wPeer = publish.webRTCPeer
        if (wPeer) {
          debug("Replacing track", videoTrack, videoTrack.readyState, videoTrack.muted)
          wPeer.replaceTrack(videoTrack)
        }
      }
    }
    return () => {
    
    }
  }, [isVideoEnabled, publish, videoTrack])

  // Components
  const icon = useMemo(() => {
    if (gettingDevices || gettingVideoMedia) {
      const color = variant === "button" ? "inherit" : "primary";

      return (
        <CircularProgress variant="indeterminate" size="18px" color={color} />
      );
    }

    if (videoInputPermission === DevicePermission.allowed) {
      if (isVideoEnabled) {
        return <VideoCameraFront />;
      } else {
        return <VideocamOff color="error" />;
      }
    }

    return <ReportOff color="error" />;
  }, [
    videoInputPermission,
    isVideoEnabled,
    gettingVideoMedia,
    gettingDevices,
    variant,
  ]);

  const button = useMemo(() => {
    let disabled = false;
    if (
      videoInputPermission !== DevicePermission.allowed ||
      gettingVideoMedia ||
      gettingDevices
    ) {
      disabled = true;
    }

    switch (variant) {
      case "icon":
        return (
          <IconButton
            color={isVideoEnabled ? "primary" : "inherit"}
            onClick={toggleVideo}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        );

      case "button":
      default:
        return (
          <Button
            color={isVideoEnabled ? "primary" : "inherit"}
            onClick={toggleVideo}
            disabled={disabled}
            variant="contained"
          >
            {icon}
          </Button>
        );
    }
  }, [
    videoInputPermission,
    isVideoEnabled,
    gettingVideoMedia,
    gettingDevices,
    variant,
  ]);

  const renderer = useMemo(() => {
    let title = (
      <Typography>
        "Toggle Video Enable"
      </Typography>
    )
    if (videoInputPermission !== DevicePermission.allowed) {
      title = (
        <Alert severity="error" icon={<ReportOff color="error" />}>
          Video Permission Denied. Please check your browser settings.
        </Alert>
      )
    }

    return (
      <Tooltip
        title={title}
        placement={variant === "button" ? "top" : "right-start"}
      >
        <span>{button}</span>
      </Tooltip>
    );
  }, [videoInputPermission, icon, variant]);

  return renderer;
}
