import { useState, useEffect, useCallback, useRef } from "react";
import Debug from "debug"
const debug = Debug("SS:useApiRequest")

export enum RequestMethod {
  get = "GET",
  head = "HEAD",
  post = "POST",
  put = "PUT",
  patch = "PATCH",
  delete = "DELETE",
  options = "OPTIONS",
  trace = "TRACE",
}

export default function useApiRequest() {
  const [inProgress, setInProgress] = useState(false)
  const timeout = useRef<NodeJS.Timeout>()
  const controller = useRef(new AbortController())

  /**
   * Make a fetch call to the api.
   * Response data is parsed to json and returned under the body property
   * The status property shows the http status code returned, 
   * or 0 for a network error, 
   * or 1 for a rejected request due to an existing request already in progress
   * or 2 for a timeout.current
   * the error will be null for a successful response, contain the status text for a > 300 response code, or the error message for network errors and aborted requests
   *
   * @async
   * @param url A URL or string with the full request URL
   * @param method a RequestMethod enum
   * @param {json?} data JSON object of data to send in the request body for POST / PUT / PATCH / DELETE requests only
   * @param {string?} auth A string to be passed as a Bearer token in the Authorization header
   * @returns {
  *  body: ResponseType | null,
  *  status: number,
  *  error?: string
  * }
  */
  const makeApiRequest = useCallback(async <ResponseType>(
    url: string | URL,
    method: RequestMethod,
    data?: Object | null,
    auth?: string,
  ) => {
    debug("Requesting ", url);

    if (inProgress) {
      debug("Request already in progress")
      return {
        response: null,
        status: 1,
        error: "Request currently in progress",
      };
    }

    try {
      if (typeof url === "string") {
        url = new URL(url)
      }

      // Track our request
      setInProgress(true)
      debug("Set inProgress True")
      // Make sure we have a fresh abort controller.current
      if (controller.current.signal.aborted) {
        controller.current = new AbortController();
      }
      // Timeout requests after 10 seconds with no reply
      timeout.current = setTimeout(() => {
        debug("Timeout")
        setInProgress(false)
        controller.current.abort("Request took too long and was cancelled");
        return {
          body: null,
          status: 2,
          error: "Request took too long",
        };
      }, 100000);

      // Setup the request
      const fetchHeaders = new Headers();
      fetchHeaders.append("Content-Type", "application/json");
      if (auth) {
        fetchHeaders.append("Authorization", "Bearer: " + auth);
      }

      const fetchOptions: RequestInit = {
        method: method,
        headers: fetchHeaders,
        credentials: "include",
        referrerPolicy: "strict-origin-when-cross-origin",
      };
      switch (method) {
        case RequestMethod.delete:
        case RequestMethod.put:
        case RequestMethod.post:
        case RequestMethod.patch:
          if (data) {
            fetchOptions.body = JSON.stringify(data);
          }
          break;
      }

      // Make request
      debug("Calling %s with body %o", url, fetchOptions.body);

      const res = await fetch(url, fetchOptions);
      clearTimeout(timeout.current);
      setInProgress(false);
      debug("Response from %s is %s", url, res.ok ? "OK" : "Not OK");

      // Parse response data to json
      const response = await res.json();
      debug("Response from %s with data %o", url, response);

      return {
        response: response as ResponseType,
        status: res.status,
        error: res.ok ? undefined : res.statusText,
      };
    } catch (error: unknown) {
      debug("Network Error from %s %o", url, error);
      clearTimeout(timeout.current);
      setInProgress(false)
      // Should I re-throw instead to propegate the error?????
      return {
        response: null,
        status: 0,
        error: (error as Error).message,
      };
    }
  }, [inProgress, timeout.current, controller.current])

  /**
   *
   * @param reason An optional string relaying the reason the request is being cancelled
   */
  const cancelApiRequest = useCallback((reason?: string) => {
    if (inProgress && !controller.current.signal.aborted) {
      controller.current.abort(reason);
      setInProgress(false);
      clearTimeout(timeout.current);
      debug("API Request aborted due to ", reason);
    }
  }, [inProgress, timeout.current, controller.current])

  return { makeApiRequest, cancelApiRequest, inProgress }
}