import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@mui/styles";
import { Grid, Paper, Typography } from "@mui/material";
import TvOSCheckCode from "./tvOS_CheckCode";
import TvOSLogin from "./tvOS_Login";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
  })
);

interface TvOSAuthorizeProps {
  cssClasses: any;
}

export default function TvOSAuthorize(props: TvOSAuthorizeProps) {
  // Hooks
  const classes = useStyles();

  // State
  const [tvUuid, setTvUuid] = useState<string>();
  const [accessApproved, setAccessApproved] = useState<boolean>(false);

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {!accessApproved && !tvUuid && (
          <TvOSCheckCode cssClasses={props.cssClasses} setTvUuid={setTvUuid} />
        )}
        {!accessApproved && tvUuid && (
          <TvOSLogin
            cssClasses={props.cssClasses}
            tvUuid={tvUuid}
            setAccessApproved={setAccessApproved}
          />
        )}
        {accessApproved && (
          <Paper className={props.cssClasses.signInPaper}>
            <Grid container className={props.cssClasses.signInBox}>
              <Grid item xs={12}>
                <Typography>
                  Access approved. Please return to your Apple TV to continue.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
