import { useState, useRef } from "react";
import {
  Typography,
  Select,
  MenuItem,
  Button,
  Popover,
  Tooltip,
  Paper,
} from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";

import AdvancedVideoSettings from "../AdvancedVideoSettings/AdvancedVideoSettings";
import useCamera from "../../../Context/CameraProvider/useCamera";

// Icons
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import MyVideoPreview from "../MyVideoPreview/MyVideoPreview";
import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";
import ToggleVideo from "../ToggleVideo/ToggleVideo";
import { DevicePermission } from "../../../Context/CameraProvider/Hooks/useMediaDevices";

export default function VideoSettings() {
  // Hooks
  const {
    videoInputDevices,
    videoDeviceId,
    updateVideoDeviceId,
    videoInputPermission,
  } = useCamera();

  const { withVideo } = useVideoChat();

  // State
  const [showSettings, setShowSettings] = useState<boolean>(false);

  // Refs
  const containerRef = useRef(null);

  // Render
  return (
    <Paper
      sx={{
        backgroundColor: "background.dark",
        borderRadius: 2,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={4}
        padding={4}
        sx={{
          minHeight: 64,
        }}
      >
        <Grid>
          {
            videoInputPermission && 
            <MyVideoPreview />
          }
        </Grid>

        <Grid>
          <Grid
            container
            direction="column"
            spacing={8}
          >
            <Grid>
              <ToggleVideo />
            </Grid>

            <Grid
              ref={containerRef}
            >
              {withVideo && videoInputPermission && (
                <Tooltip title="Advanced Settings">
                  <span>
                    <Button
                      onClick={() => setShowSettings(!showSettings)}
                      variant="outlined"
                      disabled={videoInputPermission !== DevicePermission.allowed}
                    >
                      <SettingsIcon />
                    </Button>
                  </span>
                </Tooltip>
              )}
              {
                !withVideo &&
                <Typography sx={{ height: "31px " }}>
                  No Video
                </Typography>
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid
          alignItems="flex-end"
          sx={{
            flex: 1,
            minWidth: "200px"
          }}
        >
          {withVideo && videoInputPermission === DevicePermission.allowed && (
            <Select
              id="videoInputDevices"
              label="Video Input"
              value={videoDeviceId}
              fullWidth
              variant="outlined"
              onChange={(adid) => updateVideoDeviceId(adid.target.value)}
            >
              {videoInputDevices.map((adev, i) => (
                <MenuItem value={adev.deviceId} key={"MEN" + i}>
                  {adev.label}
                </MenuItem>
              ))}
            </Select>
          )}
          {withVideo && videoInputPermission !== DevicePermission.allowed && (
            <Typography>
              {
                videoInputPermission === DevicePermission.unknown ?
                "Please allow video permissions" :
                "Video Permission Denied"
              }
            </Typography>
          )}
        </Grid>

        <Popover
          onClose={() => setShowSettings(false)}
          open={showSettings}
          anchorEl={containerRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: {
                padding: 18,
                border: "1px solid",
                borderColor: "secondary.main",
                borderRadius: 4,
              },
            },
          }}
        >
          <AdvancedVideoSettings />
        </Popover>
      </Grid>
    </Paper>
  );
}
