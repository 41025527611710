import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
FormControlLabel,
Switch
} from '@mui/material/';
import { useSnackbar } from 'notistack';

export default function WaitingRoomAutoApprove(props) {
  // Hooks

  // State
  const [inProgress, setInProgress] = useState(false);

  // Effects

  // Actions
  const updateAutoApprove = async (approve: Boolean) => {
    setInProgress(true);
    try {
      let fetchUrl = process.env.REACT_APP_API_URL + '/api/waiting_room/auto/' + props.publisher_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          autoApprove: approve
        })
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        
        // Store response in state
        if (typeof response.autoApprove === 'boolean') { props.setAutoApprove(response.autoApprove) }

      } else {
        console.error('Could not update auto approve ', res.status);
      }  
    } catch (error) {
      console.error('Network error updating auto approve ', error);
    } finally {
      setInProgress(false)
    }
  }

  // Render
  return (
    <Grid>
      <FormControlLabel
        label="Auto Approve"
        control={<Switch
          checked={props.autoApprove}
          onChange={() => updateAutoApprove(!props.autoApprove)}
        />}
      />
      {
        inProgress &&
        <CircularProgress 
          variant='indeterminate'
        />
      }
    </Grid>
  );

}
