// Returns the current width and height of the passed Element

export default function getCurrentSize(element:Element) {
    let currentWidth:string = window
      .getComputedStyle(element)
      .getPropertyValue('width');
    let currentWidthNum:number = Number(currentWidth.substring(0, currentWidth.length - 2))
    let currentHeight:string = window
      .getComputedStyle(element)
      .getPropertyValue('height');
    let currentHeightNum:number = Number(currentHeight.substring(0, currentHeight.length - 2));

    return [currentWidthNum, currentHeightNum];
  }
