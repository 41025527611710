import { useCallback, useEffect, useMemo, useState } from 'react';
import { useVideoChat } from '../../VideoChatProvider/VideoChatProvider';
import Debug from "debug"; const debug = Debug("SS:VideoChat:Camera:useVideoDevices");
import { PeerConnection } from '@millicast/sdk';
import { videoQualities } from '../Settings/video_qualities';

export type VideoResizeMode = "none" | "crop-and-scale";

export default function useVideoInputDevices(
  label: string,
  publisherUuid: string,
  videoInputDevices: MediaDeviceInfo[],
) {
  const [videoDeviceId, setVideoDeviceId] = useState<string>("");
  const [videoFacingMode, setVideoFacingMode] = useState<VideoFacingModeEnum>("user");
  const [videoResizeMode, setVideoResizeMode] = useState<VideoResizeMode>("crop-and-scale");
  const [videoCodec, setVideoCodec] = useState<string>("vp9");
  const [videoBitrate, setVideoBitrate] = useState<number>(1000); // Kbps
  const [videoWidth, setVideoWidth] = useState<number>(960);
  const [videoQuality, setVideoQuality] = useState<number>(1);

  const supportedVideoCapabilities = PeerConnection.getCapabilities("video")
  
  const { 
    withVideo
  } = useVideoChat()

  const getVideoConstraints = useMemo(() => {
    debug("Creating video constraints", videoDeviceId, videoFacingMode, videoResizeMode, videoCodec, videoWidth, withVideo)

    // Create media constraints object
    const availableConstraints = navigator.mediaDevices.getSupportedConstraints();

    let videoC: MediaTrackConstraints | false = false;
    if (withVideo && videoDeviceId !== "") {
      videoC = {
        deviceId: { exact: videoDeviceId },
        width:{
          max: 1920,
          ideal: videoWidth ? videoWidth : 1280,
          min: 640
        },
        height: {
          max: 1080,
          ideal: videoWidth ? videoWidth * (9/16) : 720,
          min: 360
        }
      }
      
      // if (availableConstraints.aspectRatio) {
      //   videoC.aspectRatio = (16/9)
      // }
      if (availableConstraints.facingMode) {
        videoC.facingMode = videoFacingMode
      }
      // @ts-expect-error In Chrome but not in Spec
      if (availableConstraints.resizeMode) {
        // @ts-expect-error In Chrome but not in Spec 
        videoC.resizeMode = videoResizeMode
      }

      debug("Created video constraints ", videoC)
    }
    return videoC;
  }, [videoDeviceId, videoFacingMode, videoResizeMode, videoCodec, videoWidth, withVideo])

  useEffect(() => {
    try {
      const storedVideoDeviceId = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoDeviceId');
      if (storedVideoDeviceId) { setVideoDeviceId(storedVideoDeviceId) }

      const storedVideoFacingMode = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoFacingMode');
      if (storedVideoFacingMode) { setVideoFacingMode(storedVideoFacingMode as VideoFacingModeEnum) }

      const storedVideoResizeMode = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoResizeMode');
      if (storedVideoResizeMode) { setVideoResizeMode(storedVideoResizeMode as VideoResizeMode) }

      const storedVideoCodec = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoCodec');
      if (storedVideoCodec) { setVideoCodec(storedVideoCodec) }

      const storedVideoBitrate = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoBitrate');
      if (storedVideoBitrate) { setVideoBitrate(Number(storedVideoBitrate)) }

      const storedVideoWidth = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoWidth');
      if (storedVideoWidth) { setVideoWidth(Number(storedVideoWidth)) }
      
      const storedVideoQuality = window.localStorage?.getItem(publisherUuid + '_' + label + '_videoQuality');
      if (storedVideoQuality) { setVideoQuality(Number(storedVideoQuality)) }

    } catch (error) {
      console.log("Local Storage not allowed or errored Video - ", error);
    }
  }, []);

  useEffect(() => {
    if (
      (!videoDeviceId ||
        videoInputDevices?.findIndex(vd => vd?.deviceId === videoDeviceId) === -1) &&
      videoInputDevices?.length > 0 &&
      videoInputDevices[0]?.deviceId
    ) {
      debug("Setting video device id", videoInputDevices[0].deviceId)
      // Select the first item by default
      updateVideoDeviceId(videoInputDevices[0].deviceId);
    }
  }, [videoInputDevices, videoDeviceId]);

  // Actions

  const updateVideoDeviceId = (id: string) => {
    setVideoDeviceId(id);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoDeviceId',
      id
    );
  }

  const updateVideoFacingMode = (mode: VideoFacingModeEnum) => {
    setVideoFacingMode(mode);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoFacingMode',
      mode
    );
  }

  const updateVideoResizeMode = (mode: VideoResizeMode) => {
    setVideoResizeMode(mode);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoResizeMode',
      mode
    );
  }

  const updateVideoCodec = (codec: string) => {
    setVideoCodec(codec);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoCodec',
      codec
    )
  }

  const updateVideoBitrate = (bitrate: number) => {
    setVideoBitrate(bitrate);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoBitrate',
      bitrate.toString()
    );
  }

  const updateVideoWidth = (width: number) => {
    setVideoWidth(width);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoWidth',
      width.toString()
    );
  }
  
  const updateVideoQuality = (qualityId: number) => {
    if (typeof qualityId !== "number") { return }
    const quality = videoQualities.find(q => q.id === qualityId)
    if (!quality) {
      debug("Quality not found", quality, qualityId, videoQualities)
      return
    }

    updateVideoWidth(quality.width)
    updateVideoBitrate(quality.bitrate)
    setVideoQuality(quality.id);
    window.localStorage.setItem(
      publisherUuid + '_' + label + '_videoQuality',
      quality.id.toString()
    );
  }

  return {
    videoDeviceId,
    updateVideoDeviceId,
    videoFacingMode,
    updateVideoFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth,
    updateVideoWidth,
    videoQuality,
    updateVideoQuality,
    getVideoConstraints,
    supportedVideoCapabilities
  };
}
