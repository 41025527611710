import React, { useEffect, useState } from 'react';

// requires an array of passes as props

export default function useHasActiveSubs(props) {
  const [hasActiveSubs, setHasActiveSubs] = useState(false);

  useEffect(() => {

    if (props && props.length > 0) {
      let currentlyActiveSub = props.find(
        pass =>
          (new Date(pass.begins_on) < Date.now() &&
          new Date(pass.expires_on) > Date.now())
      );
      setHasActiveSubs(currentlyActiveSub ? true : false);
    } else {
      setHasActiveSubs(false);
    }

  }, [props]);
  
  return hasActiveSubs;
}
