import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
TextField,
IconButton,
} from '@mui/material/';
import ClearTwoToneIcon from '@mui/icons-material/Clear';
import BackspaceIcon from '@mui/icons-material/Backspace';
import isValidEmail from '../../../utils/isValidEmail';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    // minWidth: '400px'
  },
  valid: {
    borderBottom: '1px solid ' + theme.palette.primary.light,
    borderRadius: '4px',
    margin: 'auto'
  },
  invalid: {
    borderBottom: '1px solid ' + theme.palette.warning.main,
    borderRadius: '4px',
    margin: 'auto'
  },
  listItem: {
    borderRadius: '4px',
    margin: 'auto'
  }
}));

export default function EmailListItem(props) {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState(props.email);

  useEffect(() => {
    setEmail(props.email);
    setIsValid(isValidEmail(props.email))
  }, [props.email]);

  useEffect(() => {
    props.updateEmail(props.index, email);
  }, [email]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={11} className={classes.listItem}>
        <TextField
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={!isValid}
          type="email"
          fullWidth
        >
          {email}
        </TextField>
      </Grid>

      <Grid item xs={1}>
        <IconButton onClick={e => props.removeEmail(props.index)} size="large">
          <BackspaceIcon
            color={isValid ? "primary" : "error"}
          />
        </IconButton>
      </Grid>
    </Grid>
  );

}
