import React, { useEffect, useRef, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import RequestWaitingRoomAccess from './RequestWaitingRoomAccess';
import InWaitingRoom from './InWaitingRoom';
import logToServer from '../../utils/log_to_server';
import pusherAuth from '../Pusher/pusherAuth';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function ViewerWaitingRoom(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  
  // recconnect rate limit
  let retries: React.Ref<number> = useRef(0);

  // State
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [joinWaitingRoom, setJoinWaitingRoom] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string>("");
  const pusherRef = useRef()
  const isReconnecting = useRef(false)

  // Effects
  useEffect(() => {
    // Automatically try logging in if we have room, nickName and code hash
    if (
      props.room && 
      props.wrHash && 
      props.nickName
    ) {
      loginWaitingRoom()
    }

    document.addEventListener('visibilitychange', handleVisbilityChange)

    return () => {
      if (pusherRef.current) {
        pusherRef.current.disconnect()
        pusherRef.current = null
      }

      document.removeEventListener('visibilitychange', handleVisbilityChange)
    }
  }, [])

  useEffect(() => {
    let newPusher: Pusher;

    if (joinWaitingRoom && props.room && props.user_uuid) {
    
      if (pusherRef.current) {
        pusherRef.current.connect()
      } else 
      if (!pusherRef.current) {
        newPusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
          forceTLS: true,
          cluster: 'us3',
          channelAuthorization: {
            endpoint: process.env.REACT_APP_API_URL + '/api/pusher/auth/'+ props.room + '/' + props.user_uuid + '_wr',
            customHandler: async (params, callback) => {await pusherAuth(params, callback, props.room, props.user_uuid + '_wr')}
          }
        });

        pusherRef.current = newPusher

        // Monitor connection status
        newPusher.connection
        .bind('error', (error) => {
          if (
            error?.data?.code !== 1006 &&
            error?.data?.code !== 4009
          ) {
            console.log('pusher error ', error)
          }
          setIsWaiting(false)
        })

        // Subscribe to channels
        subscribeToChannels()
      }

      setJoinWaitingRoom(false);
    }
  }, [props.room, props.user_uuid, joinWaitingRoom]);

  // Actions
  const loginWaitingRoom = async (e?: React.FormEvent<HTMLFormElement>) => {
    
    e?.preventDefault();
    setInProgress(true);

    try {
      let fetchUrl = process.env.REACT_APP_API_URL + '/api/waiting_room/login'
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          code: accessCode,
          hash: props.wrHash,
          nickName: props.nickName,
          room: props.room,
          uuid: props.user_uuid
        })
      }
      let res = await fetch(fetchUrl, fetchData);
      setInProgress(false);
      let response = await res.json();
      
      if (
        res.ok &&
        response.type === "success" && 
        response.uuid
      ) {

        // Update uuid if it's outdated
        // This can happen when localstorage outlives the db record
        if (props.user_uuid !== response.uuid) {
          localStorage.setItem(response.room + '_wr', response.uuid);
          props.updateState('user_uuid', response.uuid)
        }
        
        if (response.inWaitingRoom) {
          setJoinWaitingRoom(true);
        } else {
          // type=success && !inWaitingRoom means we are logged in
          props.updateLoggedInStatus()
        }
      } else {
        console.error('Waiting Room Access Network Error ', res.status, response);
        enqueueSnackbar("Error - " + response.message, { variant: "warning" })
      }  
    } catch (error) {
      console.error('Network error getting data ', error);
      enqueueSnackbar("Network error.  Please refresh and try again", { variant: "warning" });
    } finally {
      
      // Update nickName
      if (props.nickName && props.nickName !== localStorage.getItem("ss_nickname")) {
        localStorage.setItem("ss_nickname", props.nickName)
      }
      
      setInProgress(false);
    }
  }

  const logoutWaitingRoom = async () => {
    pusherRef.current?.disconnect()
    pusherRef.current = null
    setIsWaiting(false)
    try {
      await fetch(
        process.env.REACT_APP_API_URL + '/api/waiting_room/logout/' + props.user_uuid,
        { credentials: "include" }
      )
    } catch (error) {
      // Fail Silently & log
      logToServer({
        action: "Waiting Room Logout",
        result: "Failed",
        user_uuid: props.user_uuid,
        error: error
      })
    }
  }

  const handleVisbilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // pusherRef.current?.disconnect()
        // pusherRef.current = null
      } else {
        setJoinWaitingRoom(true)
      }
  }

  const subscribeToChannels = () => {
    pusherRef.current?.subscribe('presence-' + props.room + '_wr')
        .bind("pusher:subscription_succeeded", message => {
          setIsWaiting(true)
          setInProgress(false)
        })
        .bind("pusher:subscription_error", message => {          
          console.log("wr subscription error ", message, retries.current)
          handleSubscriptionError()
        })

        // Setup Channel
        pusherRef.current?.subscribe('private-' + props.user_uuid + '_wr')
        .bind('pusher:subscription_error', handleSubscriptionError)
        .bind('access_granted', (message) => {
          loginWaitingRoom()
        })
        .bind('log_out', (message) => {
          console.log("Access Expired")
          enqueueSnackbar("Access Expired", { variant: "error" })
          logoutWaitingRoom()
        })
  }

  const handleSubscriptionError = (message) => {
    if (
      !isReconnecting.current &&
      message?.status === 403 && 
      (accessCode || props.wrHash) &&
      retries.current < 5
    ) {
      // Access is denied so disconnect and try to login again
      retries.current++
      pusherRef.current?.disconnect()
      setIsWaiting(false)
      loginWaitingRoom()
      
      // Rate limit retries
      setTimeout(() => { 
        if (retries.current > 0) {
          retries.current-- 
        }
      }, 10000) // 10 seconds
    } else if (!isReconnecting) {
      console.log("WR Subscription Error ", retries.current, message);
      enqueueSnackbar('We had an issue connecting.  Please refresh and try again', { variant: 'error' })
    }
  }


  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        
        {
          !isWaiting &&
          <RequestWaitingRoomAccess
            loginWaitingRoom={loginWaitingRoom}
            accessCode={accessCode}
            setAccessCode={setAccessCode}
            {...props}
          />
        }
        {
          isWaiting &&
          <InWaitingRoom
            setIsWaiting={setIsWaiting}
            {...props}
          />
        }

      </Grid>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
