/**
 * NodeJS Style event emitter
 * Use this for async triggers across the app without importing
 * This can also cross context provider boundries when necessary
 */

import Debug from "debug";
const debug = Debug("SS:VideoChat:EventsProvider");
import React, { ReactNode } from "react";
import { EventEmitter } from "events";

interface Props {
  dispatch: EventEmitter;
  children: ReactNode;
}

interface Context {
  dispatch: EventEmitter
}

export const EventsContext = React.createContext<Context>(null!);

export function EventsProvider({dispatch, children}: Props) {
  
  return (
    <EventsContext.Provider
      value={{
        dispatch
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}