import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
} from '@mui/material/';
import { Members } from 'pusher-js';
import SketchesMarker from './sketches_marker';
import { Marker } from '../sketches_types';
import useSketches from '../Context/useSketches';

interface Member {
  id: string,
  info: object
}

const useStyles = makeStyles((theme) => createStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    pointerEvents: 'none',
  }
}));

export default function SketchesMarkerRemote(props:any) {
  // Hooks
  const classes = useStyles();
  const {
    markerHistory,
    sbPusher,
    members,
    updateMembers,
    viewerUuid,
    cam_index
  } = useSketches();

  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markerToMove, setMarkerToMove] = useState<Marker | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  // Show markers stored in history
  useEffect(() => {
    if (markerHistory?.length > 0) {
      let markersToShow: Array<Marker> = [];

      // Create an array of markers to dispaly
      for (let i = markerHistory.length - 1; i >= 0 ; i--) {
        let viewerAvailable = false;
        if (members) {
          let member = members.get(markerHistory[i].viewerUuid);

          if (member) {
            viewerAvailable = true;
          }
        }
        
        if (
          markerHistory[i].viewerUuid !== viewerUuid &&
          markerHistory[i].visible &&
          markerHistory[i].cam_index === cam_index &&
          viewerAvailable // Only show if logged in
        ) {
          markersToShow.push(markerHistory[i]);
        }
      }
      setMarkers(markersToShow)
    } else {
      // Reset array
      setMarkers([]);
    }
  }, [markerHistory, members, updateMembers]);

  // Listen for remote marker moves
  useEffect(() => {
    if (sbPusher) {
      // Track cursor movements
      sbPusher.bind('client-sb_marker_move', setMarkerToMove);
    }

    return () => {
      if (sbPusher) {
        sbPusher.unbind('client-sb_marker_move', setMarkerToMove);
      }
    }
  }, [sbPusher]);

  useEffect(() => {
    if (markerToMove) {
      moveMarker(markerToMove);
    }
    setMarkerToMove(null);
  }, [markerToMove])

  // Actions
  // Track cursor movements
  const moveMarker = (message: Marker) => {

    if (message.viewerUuid !== viewerUuid) {
      // Get our version of the Marker
      let markerToUpdate: Marker | undefined = markers.find(marker => marker.uuid === message.uuid);
      let markerIndex: number = markers.indexOf(markerToUpdate as Marker);
      // Update
      let newMarkers = [...markers]
      newMarkers[markerIndex !== -1 ? markerIndex : 0] = message;
      
      setMarkers(newMarkers);
    }
  }

  // Render
  return (
    <Grid
      className={classes.container}
      ref={containerRef}
    >
      {
        markers.map(marker => (
          <SketchesMarker
            marker={marker}
          />
        ))
      }
    </Grid>
  );

}
