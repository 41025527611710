import React from 'react';

interface Props {
    section: string,
    showErrors: Boolean,
    errorHandler?: Function,
    retryHandler?: Function
}

interface State {
  showErrors: Boolean,
  hasError: Boolean,
  errorNum: number,
  error: Error | null,
  errorMessage: string,
  info: any,
}

interface ErrorToLog {
  created: Date,
  section: string,
  page: string,
  info: any,
  error_name: string,
  error_message: string,
  error_stack: string | undefined,
  error: Error,
}

class ComponentErrorBoundary extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      showErrors: process.env.REACT_APP_ENV === 'development',
      hasError: false,
      errorNum: 1,
      error: null,
      errorMessage: '-',
      info: '--',
     };
  }

  static getDerivedStateFromError(error:Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: any) {
    // Log to console
    console.error('%s Fatal Error: %o', this.props.section, error);
    console.error('%s Fatal Info:', this.props.section, info);

    // Retry if parent provides a method
    if (this.props.errorHandler) {
      this.props.errorHandler();
    }

    // Display fallback UI
    this.setState({
      errorNum: this.state.errorNum + 1,
      error: error,
      errorMessage: error.message,
      info: info
    });

    let page: string = window.location.href;
    let errorData: ErrorToLog = {
      created: new Date(),
      section: this.props.section,
      page: page,
      info: info,
      error_name: error.name,
      error_message: error.message,
      error_stack: error.stack,
      error: error,
    }

    // Use SendBeacon and fallback to Fetch API
    if (navigator.sendBeacon) {
      navigator.sendBeacon(
        process.env.REACT_APP_API_URL + '/api/errors',
        JSON.stringify(errorData))
    } else {
      let fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain'
        },
        credentials: "include",
        body: JSON.stringify(errorData),
      }

      fetch(process.env.REACT_APP_API_URL + '/api/errors', fetchOpts)
      .catch((e: Error) => {
        console.error('Network error - Could not log error ', e);
      })
    }

    // Wait 3 second then try again
    // Only retry 3 times before giving up
    if (this.props.retryHandler && this.state.errorNum <= 3) {
      setTimeout(() => {
        if (this.props.retryHandler) {
          this.props.retryHandler();
        }
      }, 3000)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>We had trouble loading the {this.props.section}. Please refresh the page or contact support.</h1>
          {
            this.state.showErrors &&
            this.state.errorMessage
          }
        </div>
      )
    }
    return this.props.children
  }
}

export default ComponentErrorBoundary;
