import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Typography,
} from '@mui/material/';
import useCustomBranding from './Context/useCustomBranding';

export interface BrandLogoProps {
  size: string,
  showName: boolean,
}

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: "flex",
    padding: theme.spacing(2),
  },
  logoContainer: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto"
  },
  nameContainer: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: theme.spacing(4),
  },
  small: {
    maxWidth: "36px",
    height: "36px",
    maxHeight: "36px"
  },
  medium: {
    maxWidth: "50px",
    height: "50px",
    maxHeight: "50px"
  },
  large: {
    maxWidth: "80px",
    height: "80px",
    maxHeight: "80px"
  }

}));

export default function BrandLogo(props: BrandLogoProps) {
  // Hooks
  const classes = useStyles();
  const { brandName, brandLogoUrl } = useCustomBranding()
  const logoRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
      if (brandLogoUrl && logoRef.current) {
      logoRef.current.src = brandLogoUrl
    }
  }, [brandLogoUrl]);

  const getClassName = () => {
    switch (props.size) {
      case "small":
      default:
        return classes.small
        break;
      case "medium":
        return classes.medium
        break;
      case "large":
        return classes.large
        break;
    }
  }

  // Render
  return (
    <Grid className={classes.container}>
      <Grid className={classes.logoContainer}>
          <img 
            src="/images/SS_Logo_Only.svg" alt="SetStream.io" 
            className={getClassName()} 
            id="brandLogo" 
            ref={logoRef} 
          />
      </Grid>
      {
        props.showName &&
        <Grid className={classes.nameContainer}>
          <Typography>
            {brandName}
          </Typography>
        </Grid>
      }
    </Grid>
  )
}
