import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Button,
Table,
TableHead,
TableBody,
TableRow,
TableCell,
Typography,
Link,
FormControl,
InputLabel,
Select,
MenuItem,
Hidden,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import SelectPass from '../Elements/select_pass';
import formatExpiration from '../../../utils/format_expiration';
import getPassData from '../Utils/getPassData';
import createNewFormattedExpiration from '../../../utils/create_new_formatted_expiration';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  infoHeader: {
    backgroundColor: theme.palette.background.light,
  },
}));

export default function AttachSubscription(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Subs
  const [selectedSub, setSelectedSub] = useState('');
  const [selectedSubData, setSelectedSubData] = useState();
  const [selectedSubProjectName, setSelectedSubProjectName] = useState('');

  // Display
  const [newExpirationDisplay, setNewExpirationDisplay] = useState('-');
  const [maxViewers, setMaxViewers] = useState('-');

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});

  // Effects

  // Update selected Sub
  useEffect(() => {
    if (props.availableSubs && props.availableSubs.length > 0) {
      setSelectedSub(props.availableSubs[0]._id);
    }
  }, [props.availableSubs])

  // Update selected pass data
  useEffect(() => {
    let subData = getPassData(props.availableSubs, selectedSub);
    setSelectedSubData(subData);

    // Set attached project name
    if (
      subData?.session_id_attached_to
    ) {
      let newSelectedSubProjectName = props.subsProjectNames
      .find(sub => (
        sub._id === subData.session_id_attached_to
      ))
      setSelectedSubProjectName(newSelectedSubProjectName.project_name)
    } else {
      setSelectedSubProjectName('');
    }
  }, [selectedSub]);

  // Update selected pass display info
  useEffect(() => {
    if (
      selectedSubData &&
      selectedSubData.active_duration &&
      selectedSubData.duration_units &&
      selectedSubData.max_allowed_users
    ) {
      let newExpirationDisplay = createNewFormattedExpiration(selectedSubData, 0);
      setNewExpirationDisplay(newExpirationDisplay)
      setMaxViewers(selectedSubData.max_allowed_users);

      let newDisplayData = {}
      newDisplayData[selectedSub] = {
        Subscription: selectedSubData?.max_allowed_users +' Viewers',
        maxViewers: selectedSubData.max_allowed_users,
        expiresOn: formatExpiration(selectedSubData?.expires_on),
        currentlyAttachedTo: selectedSubProjectName,
      }
      setDisplayData(newDisplayData);
      setActionData({
        url:'/api/producer_subs/attach/',
        postData: {
          subscription_id: selectedSub,
          session_id: props.projectId,
        },
        actionText:'Attach To This Project',
      });
    }

  }, [selectedSubData]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} >

      <Hidden mdDown>
          <Grid container className={props.cssClasses.tableHeader}>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Subscription
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Expires
            </Grid>

            <Grid item xs={4} align="center" className={props.cssClasses.tableCell}>
              Project Attached To
            </Grid>

          </Grid>

          <Grid container className={props.cssClasses.tableBody}>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <FormControl
                focused
                fullWidth
                className={props.cssClasses.select}
              >
                <InputLabel
                  id="AvailableSubs"
                  className={props.cssClasses.selectLabel}
                >
                  Select a Subscription
                </InputLabel>
                <Select
                  id='AvailableSubs'
                  label='Select a Subscription'
                  placeholder='Select a Subscription'
                  variant='outlined'
                  color='primary'
                  focused
                  fullWidth
                  value={selectedSub}
                  onChange={e => setSelectedSub(e.target.value)}
                >
                  {
                    props.availableSubs.map((sub, i) => (
                      <MenuItem value={sub._id} key={'AP'+i} >
                        - {sub.max_allowed_users} Viewers -
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              {selectedSubData?.max_allowed_users}
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              {formatExpiration(selectedSubData?.expires_on)}
            </Grid>

            <Grid item xs={4} align="center" className={props.cssClasses.tableCell}>
              {selectedSubProjectName}
            </Grid>

          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Subscription
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <FormControl
                focused
                fullWidth
                className={props.cssClasses.select}
              >
                <InputLabel
                  id="AvailableSubs"
                  className={props.cssClasses.selectLabel}
                >
                  Select a Subscription
                </InputLabel>
                <Select
                  id='AvailableSubs'
                  label='Select a Subscription'
                  placeholder='Select a Subscription'
                  variant='outlined'
                  color='primary'
                  focused
                  fullWidth
                  value={selectedSub}
                  onChange={e => setSelectedSub(e.target.value)}
                >
                  {
                    props.availableSubs.map((sub, i) => (
                      <MenuItem value={sub._id} key={'AP'+i} >
                        - {sub.max_allowed_users} Viewers -
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Viewers
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {selectedSubData?.max_allowed_users}
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Expires
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {formatExpiration(selectedSubData?.expires_on)}
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              Project Attached To
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableBody}>
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {selectedSubProjectName}
            </Grid>
          </Grid>
        </Hidden>

          <Grid container className={props.cssClasses.tableFooter}>
            {
              selectedSubProjectName.length > 0 &&
              <Grid item xs={12} align="right" className={props.cssClasses.tableRow}>
                <Typography color="error">
                  Attaching this subscription to this project will remove it from {selectedSubProjectName}.
                </Typography>
              </Grid>
            }

            <Grid item xs={12} align="right" className={props.cssClasses.tableCell}>
              <Button
                onClick={() => props.changeStep('Confirm', displayData, actionData, false)}
                variant="contained"
                color="primary"
                >
                  Attach To This Project
                </Button>
              </Grid>
          </Grid>

      </Grid>
    </Grid>
  );

}
