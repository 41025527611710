import { ReactNode } from "react"
import BrandLogo from "../../Branding/BrandLogo"

import { 
  Button,
  Theme,
  Tooltip,
  useMediaQuery
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"

interface Props {
  projectName: string,
  switcherControls: ReactNode,
  chatControls: ReactNode,
  videoControls: ReactNode
  logout: () => void,
  children: ReactNode
}

export default function MenuBar({
  logout,
  children
}: Props) {

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  return (
    <Grid 
      container
      alignItems="center"
      id="MenuBar"
      sx={{
        borderBottom: "1px solid",
        borderColor: "secondary.dark",
      }}
    >
      <Grid
        sx={{
          minWidth: {
            xs: "44px",
            sm: "200px"
          }
        }}
      >
        <BrandLogo
          size="small"
          showName={!isMobile}
        />
      </Grid>

      <Grid style={{ 
        margin: 'auto',
        flex: 1
        }}
      >
        {children}
      </Grid>

      <Grid
        sx={{
          minWidth: "48px",
        }}
      >
        <Tooltip
          title="Logout"
          aria-label='Logout'
        >
          <Button
            onClick={logout}
            variant="text"
          >
            <ExitToAppIcon />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  )
}