import Debug from "debug"; const debug = Debug("SS:VideoChat:AdvancedVideoSettings");
import {
  Typography,
  Select,
  MenuItem,
} from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";
import useCamera from "../../../Context/CameraProvider/useCamera";

export default function AdvancedVideoSettings() {

  // Hooks
  const {
    videoCodec,
    availableVideoCodecs,
    updateVideoCodec,
    videoQualities,
    videoQuality,
    updateVideoQuality,
  } = useCamera();

  // Render
  return (
    <Grid container spacing={2}>
      <Grid
        xs={12}
        sx={{
          textAlign: "center",
          borderBottom: "1px solid",
          borderColor: "secondary.main",
          marginBottom: "12px",
        }}
      >
        <Typography>Video Settings</Typography>
      </Grid>

      <Grid xs={12}>
        <Typography>Codec</Typography>
        <Select
          id="allowedCodecs"
          label="Codec"
          value={videoCodec}
          fullWidth
          variant="outlined"
          onChange={(fr) => updateVideoCodec(fr.target.value)}
        >
          {availableVideoCodecs.map((vc) => (
            <MenuItem value={vc} key={vc}>
              {vc}
            </MenuItem>
          ))}
        </Select>
      </Grid>


      <Grid xs={12}>
        <Typography>Quality</Typography>
        <Select
          id="quality"
          label="Quality"
          value={videoQuality}
          variant="outlined"
          fullWidth
          onChange={(fr) => updateVideoQuality(Number(fr.target.value))}
        >
          {videoQualities?.map((vq, i) => (
            <MenuItem value={vq.id} key={"VQ" + i}>
              {vq.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>

    </Grid>
  );
}
