import { useRef, useState, useMemo } from "react";
import { SourceIdItem } from "../../Context/VideoChatProvider/Reducers/SourceIdReducer";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";

import PeerCard from "../PeerCard/PeerCard";
import PeerVideo from "../PeerVideo/PeerVideo";
import PeerAudio from "../PeerAudio/PeerAudio";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Tooltip, useMediaQuery } from "@mui/material";
import PeerControls from "../PeerControls/PeerControls";
import useIsPeerMe from "../../Context/VideoChatProvider/Hooks/useIsPeerMe";

export default function Peer({ 
  peer,
  offscreen = false
}: { 
  peer: SourceIdItem,
  offscreen?: boolean
}) {
  const [showControls, setShowControls] = useState<boolean>(false);
  const anchorEl = useRef(null);

  const { 
    chatCollapsed, 
    subConnectionStatus, 
    pubConnectionStatus,
  } =
    useVideoChat();
  const isMe = useIsPeerMe(peer);
  const isLandscape = useMediaQuery("(orientation:landscape)")


  const controls = useMemo(() => {
    if (isMe) {
      return (
        <Tooltip
          title={
            <>
              <Typography>Connection:</Typography>
              <Typography>Recv: {subConnectionStatus}</Typography>
              <Typography>Send: {pubConnectionStatus}</Typography>
            </>
          }
          placement="right-start"
        >
          <span>
            <PeerControls peer={peer} />
          </span>
        </Tooltip>
      );
    } else {
      return <PeerControls peer={peer} />;
    }
  }, [peer, isMe, subConnectionStatus, pubConnectionStatus]);

  return (
    <PeerCard peer={peer}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{
          textAlign: "center",
          width: "100%",
          maxWidth: {
            xs: isLandscape ? "100%" : chatCollapsed ? "92px" : isMe ? "172px" : "136px",
            md: chatCollapsed ? "92px" : isMe ? "172px" : "136px"
          },
          height: "100%",
          margin: "auto",
          border: "none",
        }}
        ref={anchorEl}
        onClick={
          chatCollapsed ? () => setShowControls(!showControls) : undefined
        }
        id={"peer_" + peer.sourceId}
      >
        <Grid
          sx={{
            width: "100%",
            height: "24px",
            position: "absolute",
            top: 0,
            left: 0,
            border: "none",
          }}
        >
          {controls}
        </Grid>

        <Grid
          sx={{
            flex: 1,
            maxHeight: isMe ? "172px" : "136px",
            width: "100%",
            height: {
              xs: isLandscape ? "100%" : chatCollapsed ? "92px" : "192px",
              md: chatCollapsed ? "92px" : "192px"
            },
            margin: "auto",
          }}
        >
          <Grid
            container
            sx={{
              height: "100%",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            <Grid
              sx={{
                margin: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              {
                !offscreen &&
                <PeerVideo peer={peer} />
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sx={{
            width: "100%",
            height: "18px",
            padding: 0,
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0)",
            border: "none",
          }}
        >
          <PeerAudio offscreen={offscreen} peer={peer} />
        </Grid>
      </Grid>
    </PeerCard>
  );
}
