import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://setstream.io" underline="hover">
        Set Stream .io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const initialState = {
    userName: '',
    password: ''
  }
  const [formState, updateFormState] = useState(initialState);
  const [inProgress, setInProgress] = useState(false);

  // Update state
  function setFormState(event) {
    const name = event.target.name;
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    updateFormState(
      {...formState, [name]: value}
    );
  }

  // Form submission
  async function submitForm(e) {
    e.preventDefault();
    enqueueSnackbar('Submitting your request, please stand by ....');
    console.log('Logging in user ', formState.userName);

    try {
      let fetchUrl = process.env.REACT_APP_API_URL + '/api/admin_login'
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        credentials: "include",
        body: JSON.stringify(formState)
      }
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        setInProgress(false);
        // Store response in state
        enqueueSnackbar('Logged in', {variant: 'success'});
        console.log('Logged in ', response.admin_uuid);
        history.push('/site_admin/' + response.admin_uuid);
      } else {
        console.error('Could not retrieve data ', res.status);
      }  
    } catch (error) {
      console.error("Network error logging in", error);
      enqueueSnackbar("We had trouble logging in." + error.message, { variant: "error" })
    }
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
        className={classes.form}
        onSubmit={(e) => submitForm(e)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="User Name"
            name="userName"
            autoComplete="userName"
            autoFocus
            onChange = {(e) => setFormState(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange = {(e) => setFormState(e)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={inProgress}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" underline="hover">
                {/* Forgot password? */}
              </Link>
            </Grid>
            <Grid item>
              {/*<Link href="register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>*/}
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
