import { ReactNode } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMediaQuery } from "@mui/material";

export default function AppContentColumns({
  isVCOpen,
  children,
}: {
  isVCOpen: boolean;
  children: ReactNode;
}) {
  const isLandscape = useMediaQuery("(orientation:landscape)")

  return (
    <Grid
      sx={{
        flex: 1,
        height: {
          xs: isLandscape ? "100%" : isVCOpen ? "calc(100% - 192px)" : "calc(100% - 96px)",
          md: isVCOpen ? "calc(100% - 192px)" : "calc(100% - 96px)"
        },
      }}
    >
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          flex: 1,
          overflow: "hidden",
          marginBottom: 2,
        }}
        id="AppContentColumns"
      >
        {/* Column layout for main content */}
        <Grid
          container
          direction="row"
          sx={{
            height: "100%",
            overflow: "hidden",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
