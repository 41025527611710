import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";

import { Tooltip, IconButton, useMediaQuery } from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";

export default function CollapseChat() {
  const { chatCollapsed, toggleChatCollapsed } = useVideoChat();
  const isLandscape = useMediaQuery("(orientation:landscape)")

  return (
    <Tooltip
      title={chatCollapsed ? "Enlarge Chat" : "Shrink Chat"}
      placement="right-start"
    >
      <IconButton onClick={toggleChatCollapsed} color="secondary">
        <OpenInBrowser
          sx={{
            transform: {
              xs: isLandscape ? 
                chatCollapsed ? "rotate(270deg)" : "rotate(90deg)" : 
                chatCollapsed ? "none" : "rotate(180deg)",
              md: chatCollapsed ? "none" : "rotate(180deg)"
            },
          }}
        />
      </IconButton>
    </Tooltip>
  );
}
