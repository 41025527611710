import { ReactNode, useCallback, useMemo } from "react";

import {
  Tooltip,
  Button,
  IconButton,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Mic, MicOff, ReportOff } from "@mui/icons-material";

import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";
import useCamera from "../../../Context/CameraProvider/useCamera";
import { DevicePermission } from "../../../Context/CameraProvider/Hooks/useMediaDevices";

export default function ToggleAudio({
  variant = "button",
}: {
  variant?: "button" | "icon";
}) {
  const {
    setIsActive,
    withAudio,
    setWithAudio
  } = useVideoChat();
  const {
    audioInputPermission,
    gettingAudioMedia,
    gettingDevices,
    audioTrack,
    stopAudioMedia
  } = useCamera();

  const toggleAudio = useCallback(() => {
    if (withAudio) {
      setWithAudio(false);
      stopAudioMedia()
    } else {
      setIsActive(true);
      setWithAudio(true);
    }
  }, [withAudio, stopAudioMedia]);

  const icon = useMemo(() => {
    if (gettingDevices || (withAudio && gettingAudioMedia)) {
      const color = variant === "button" ? "inherit" : "primary";
      return (
        <CircularProgress variant="indeterminate" size="18px" color={color} />
      );
    }

    if (audioInputPermission === DevicePermission.allowed) {
      if (withAudio) {
        return <Mic />;
      } else {
        return <MicOff color="error" />;
      }
    }

    return <ReportOff color="error" />;
  }, [
    audioInputPermission,
    withAudio,
    gettingAudioMedia,
    gettingDevices,
    variant,
  ]);

  const button = useMemo(() => {
    let disabled = false
    if (
      audioInputPermission !== DevicePermission.allowed ||
      gettingAudioMedia ||
      gettingDevices
    ) {
      disabled = true
    }

    switch (variant) {
      case "icon":
        return (
          <IconButton
            color={withAudio ? "primary" : "inherit"}
            onClick={toggleAudio}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        );

      case "button":
      default:
        return (
          <Button
            color={withAudio ? "primary" : "inherit"}
            onClick={toggleAudio}
            disabled={disabled}
            variant="contained"
          >
            {icon}
          </Button>
        );
    }
  }, [
    audioInputPermission,
    withAudio,
    gettingAudioMedia,
    gettingDevices,
    variant,
  ]);

  const renderer = useMemo(() => {
    return (
      <Tooltip
        title={
          audioInputPermission === DevicePermission.allowed ? (
            "Toggle Audio"
          ) : (
            <Alert severity="error" icon={<ReportOff color="error" />}>
              Audio Permission Denied. Please check your browser settings.
            </Alert>
          )
        }
        placement="right-start"
      >
        <span>{button}</span>
      </Tooltip>
    );
  }, [withAudio, audioInputPermission, icon, variant]);

  return renderer;
}
