import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
InputLabel,
Typography,
Select,
MenuItem,
Backdrop,
CircularProgress,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import DashboardActivatePassDialog from '../Dialogs/dashboard_activate_pass_dialog';
import createNewFormattedExpiration from '../../../../utils/create_new_formatted_expiration';
import useDashboardAccount from '../../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

export default function AttachPassToProjectSelect(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    customer_uuid,
    projects,
    getAccountData
  } = useDashboardAccount(); 

  // State
  const [inProgress, setInProgress] = useState(false);
  const [selected, setSelected] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [actionData, setActionData] = useState();
  const [displayData, setDisplayData] = useState();

  // Effects
  useEffect(() => {
    return () => {
      setSelected('');
    }
  }, [])

  // Actions
  const selectProject = (sessionId) => {
    setSelected(sessionId);
    
    let newDisplayData = {}
      newDisplayData[sessionId] = {
        passType: props.pass.pass_type,
        newExpiration: createNewFormattedExpiration(props.pass, 0),
        maxViewers: props.pass.max_allowed_users,
      }
      setDisplayData(newDisplayData);
      setActionData({
        url:'/api/access_passes/',
        postData: {
          pass_id: props.pass._id,
          to_schedule: false,
          session_id: sessionId
        },
        actionText:'activating a pass',
      });

    setIsDialogOpen(true);
  }

  const handleClose = () => {
    setSelected('');
    setIsDialogOpen(false);
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>
        
        {/* Label on desktop is in table header already */}
        <Hidden mdUp>
          <InputLabel
            id="attach_to_project_label"
            style={{paddingBottom:'4px'}}
          >
            Activate on Project
          </InputLabel>
        </Hidden>
        
        <Select
          id="attach_to_project"
          value={selected}
          onChange={e => selectProject(e.target.value)}
          variant="outlined"
          color="primary"
          fullWidth
        >
          {
            projects &&
            projects.map((project, i) => (
              <MenuItem
                value={project._id}
                key={'proj'+i}
              >
                {project.project_name}
              </MenuItem>
            ))
          }
        </Select>

      </Grid>

      <DashboardActivatePassDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        {...props}
        uuid={customer_uuid}
        actionData={actionData}
        displayData={displayData}
        sessionId={selected}
        pass_id={props.pass._id}
      />

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
