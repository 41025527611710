export interface VideoResolutions {
  id: number,
  label: string,
  width: number,
  height: number,
}

export const videoResolutions: VideoResolutions[] = [
  {
    id: 1080,
    label: '1080p',
    width: 1920,
    height: 1080
  },
  {
    id: 720,
    label: '720p',
    width: 1280,
    height: 720
  },
  {
    id: 540,
    label: "540p",
    width: 960,
    height: 540
  },
  {
    id: 360,
    label: "360p",
    width: 640,
    height: 360
  }
]
