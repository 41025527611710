import dayjs from "dayjs";
import { Sketch } from "../sketches_types";
import { mEvent } from "../sketches_types";

export default function mergeSketchHistory(
  sketchHistory:Sketch[], 
  setSketchHistory:(sketchHistory: Sketch[]) => void,
  sketchesToMerge: Sketch[],
  append: boolean,
): void {

  // Find new sketches
  let sketchesToAdd: Sketch[] = sketchesToMerge.filter(sketch => {
    let foundSketch: Sketch | undefined = sketchHistory.find(sk => sk.uuid === sketch.uuid);
    if (!foundSketch) { return sketch }
  })

  // Use lastUpdated date to check if we need to update 
  let sketchesToUpdate: Sketch[] = sketchHistory.map((sketch: Sketch): Sketch => {
    // Does sketch exist in sketchesToMerge?
    let sketchToCheck: Sketch | undefined = sketchesToMerge.find(stf =>  (
      stf.uuid === sketch.uuid
    ));
    // If not, keep original
    if (!sketchToCheck) { return sketch; }

    // If so, are .updated times the same?
    let updateCheck: number = dayjs(sketchToCheck.updated).diff(dayjs(sketch.updated));
    
    if (updateCheck > 0) {
      // Merge or replace the existing sketch with the newer sketch
      if (append) {
        let newSketch: Sketch = {...sketch}
        newSketch.mEvents = sketch.mEvents.concat(sketchToCheck.mEvents)
        return newSketch;
      } else {
        return sketchToCheck
      }
    } else {
      // Use the sketch from sketchHistory if it is newer or the same .updated date
      return sketch;
    }
  });
  
  // Combine and store
  let newSketchHistory = sketchesToUpdate.concat(sketchesToAdd);
  setSketchHistory(newSketchHistory);

}