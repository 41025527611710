import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Button,
Typography,
Input,
Checkbox,
Divider,
Hidden,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import SelectPass from './select_pass';
import formatExpiration from '../../../utils/format_expiration';
import getPassData from '../Utils/getPassData';
import createNewExpiration from '../../../utils/create_new_expiration';
import createNewFormattedExpiration from '../../../utils/create_new_formatted_expiration';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.background.default
  }
}));

export default function ModifyScheduledPass(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Display
  const [startDate, setStartDate] = useState(dayjs().local().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(dayjs().local().format('HH:mm'));
  const [beginsDate, setBeginsDate] = useState(new Date());
  const [endsDate, setEndsDate] = useState('-');
  const [endsDateDisplay, setEndsDateDisplay] = useState('-');
  const [maxViewers, setMaxViewers] = useState('-');
  const [isModified, setIsModified] = useState(false);

  // Data
  const [selectedPassData, setSelectedPassData] = useState();

  // Confirmation
  const [displayData, setDisplayData] = useState({});
  const [actionData, setActionData] = useState({});
  const [overlaps, setOverlaps] = useState('');

  // Update selected pass data
  useEffect(() => {
    setSelectedPassData(getPassData(props.scheduledPasses, props.selectedPass))
  }, [props.selectedPass]);

  useEffect(() => {
    // Selected pass data does not exist on page load
    if (selectedPassData && selectedPassData.begins_on) {
      setStartDate(dayjs(selectedPassData.begins_on).format('YYYY-MM-DD'));
      setStartTime(dayjs(selectedPassData.begins_on).format('HH:mm'));
    }
  }, [selectedPassData]);

  // Update start time and date selection
  useEffect(() => {

    // Ensure we have pass data to work with
    if (selectedPassData && props.scheduledPasses && props.scheduledPasses.length > 0) {
      let begins = dayjs(startDate + 'T' + startTime, 'YYYY-MM-DDTHH:mm');

      // Ensure begins_on is in the future
      if (begins.isBefore(dayjs())) {
        begins = dayjs(selectedPassData.begins_on);

        setStartDate(begins.format('YYYY-MM-DD'));
        setStartTime(begins.format('HH:mm'));
        enqueueSnackbar('Scheduled date and time must be in the future.', {variant:'warning'})
      }

      // Default to no overlap warning
      let overlaps = '';

      // if begins_on overlaps an active pass
      // - Show warning
      // - modify active pass
      if (begins.add(1,'minute').isBefore(dayjs(props.activePasses[0]?.expires_on))) {
        // TODO: Modify active pass expiration
        overlaps = 'active_pass'
      }

      // If begins_on overlaps another scheduled pass expiration
      // - show warning
      // - modify scheduled pass
      let scheduledBeginsCheck = props.scheduledPasses.filter(pass =>
        pass._id != props.selectedPass &&
        begins.subtract(1,'minute').isAfter(dayjs(pass.begins_on)) &&
        begins.add(1,'minute').isBefore(dayjs(pass.expires_on))
      )
      if (scheduledBeginsCheck.length > 0) {
        // TODO: Modify scheduled pass expiration
        overlaps = 'scheduled_begins'
      }

      // If expiration will overlap another scheduled pass begins
      // - show warning
      // - truncate pass to schedule
      let scheduledExpiredCheck = props.scheduledPasses.filter(pass =>
        pass._id != props.selectedPass &&
        dayjs(endsDate).add(1,'minute').isAfter(dayjs(pass.begins_on)) &&
        dayjs(endsDate).add(1,'minute').isBefore(dayjs(pass.expires_on))
      )
      if (scheduledExpiredCheck.length > 0) {
        overlaps = 'scheduled_expires'
      }

      setOverlaps(overlaps);
      if (!begins.isSame(dayjs(beginsDate))) {
        setBeginsDate(begins.toDate());
      }

    }
  }, [startTime, startDate, endsDate]);

  // Update new starting date and time for shceduled pass to begin
  useEffect(() => {
    if (
      selectedPassData &&
      selectedPassData.max_allowed_users &&
      selectedPassData.active_duration &&
      selectedPassData.duration_units
    ) {
      setMaxViewers(selectedPassData.max_allowed_users)

      let compareDates = dayjs(beginsDate).diff(dayjs(selectedPassData.begins_on), 'minute');
      if (compareDates === 0) {
        // Pass does not need updating
        props.dontModifyPass(selectedPassData._id);
        // Use existing pass expiration
        setEndsDate(dayjs(selectedPassData.expires_on))
        setEndsDateDisplay(formatExpiration(selectedPassData.expires_on))
        // Remove highlighting
        setIsModified(false);
      } else {
        // Use newly calculated expiration
        let expires = newExpiration();
        // Truncate if overlapping a scheduled pass
        let overlapExpiration = props.scheduledPasses
          .filter(pass => (
            pass._id != props.selectedPass &&
            dayjs(expires).add(1,'minute').isAfter(dayjs(pass.begins_on)) &&
            dayjs(expires).add(1,'minute').isBefore(dayjs(pass.expires_on))
          ))
          .reduce((p,c) => (
            (c != null && p != null) ? (
              dayjs(c.begins_on).isBefore(p.begins_on) ?
              dayjs(c.begins_on) : dayjs(p.begins_on)
            ) : (
              // null
              c != null ? (
                dayjs(c.begins_on)
              ) : (
                null
              )
            )
          ), null);

        if (overlapExpiration) {
          expires = overlapExpiration;
        }

        setEndsDate(expires);
        setEndsDateDisplay(formatExpiration(expires));

        // Highlight modified row
        let compareDates = dayjs(beginsDate).diff(dayjs(selectedPassData.begins_on), 'minute');
        if (compareDates === 0) {
          setIsModified(false);
        } else {
          setIsModified(true);
        }

        // Update pass
        let  displayData = {
          pass: selectedPassData.pass_type,
          Viewers: selectedPassData.max_allowed_users,
          willBegin: formatExpiration(beginsDate),
          willExpire: formatExpiration(expires),
        };
        let postData = {
          pass_id: props.selectedPass,
          begins_on: beginsDate
        };

        // Update parent modified pass object to reflect changes
        props.modifyPass(displayData, postData);
      }

    }
  }, [beginsDate]);

  // Actions
  // Create a new formatted expiration date and time
  const newExpiration = () => {
    // let begins = beginsDate - Date.now();
    let begins = dayjs(beginsDate).diff(dayjs(), 'ms')
    if (Math.sign(begins) === -1) { begins = 0 }
    return dayjs(createNewExpiration(selectedPassData, begins));
  }

  // Actions
  const unSchedule = () => {
    let newDisplayData = {}
    newDisplayData[props.selectedPass] = {
      pass: selectedPassData.pass_type,
      action: 'Unschedule'
    }
    let newActionData = {
      url:'/api/scheduled_passes/',
      postData: {
        pass_to_update: props.selectedPass,
        action: 'unschedule',
      },
      actionText: 'Unschedule pass'
    }

    props.changeStep('Confirm', newDisplayData, newActionData, false);
  }

  const activateNow = () => {
    let newDisplayData = {}
    newDisplayData[props.selectedPass] = {
      pass: selectedPassData.pass_type,
      action: 'activate_now',
    }
    let newActionData = {
      url:'/api/scheduled_passes/',
      postData: {
        pass_to_update: props.selectedPass,
        action: 'activate_now',
      },
      actionText: 'Activate pass now'
    }

    props.changeStep('Confirm', newDisplayData, newActionData, false);
  }

  // Render
  const renderWarning = () => {
    switch (overlaps) {
      case 'active_pass':
        return (
            'Starting this pass at this time overlaps a currently active pass.  This will modify the expiration of the active pass to be the start of this scheduled pass.'
        )
        break;
      case 'scheduled_expires':
        return (
            'Starting this pass at this time overlaps a scheduled pass.  This will modify the expiration of this pass to be the start of the scheduled pass.'
        )
        break;
      case 'scheduled_begins':
        return (
            'Starting this pass at this time overlaps a scheduled pass.  This will modify the expiration of the scheduled pass to be the start of this pass.'
        )
        break;
    }
  }

  return (
    <Grid container className={isModified ? props.cssClasses.tableRowHighlighted : props.cssClasses.tableRow}>

      {/* Desktop */}
      <Hidden mdDown>
        <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
            {selectedPassData?._id}
        </Grid>

        <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
            {selectedPassData?.pass_type}
        </Grid>

        <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
          <Typography>{selectedPassData?.max_allowed_users}</Typography>
        </Grid>

        <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
          <Input
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            label="Choose Start Date"
            required
            color="primary"
          />
        </Grid>

        <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
          <Input
            type="time"
            value={startTime}
            onChange={e => setStartTime(e.target.value)}
            label="Choose Start Time"
            required
            color="primary"
          />
        </Grid>

        <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
          <Typography>{endsDateDisplay}</Typography>
        </Grid>

        <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
          <Checkbox
            type="checkbox"
            name="isModified"
            checked={isModified}
            disabled={true}
            hidden={true}
          />
        </Grid>

        <Grid item xs={8} align="center" className={props.cssClasses.tableCell}>

        </Grid>

        <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
          <Button
            onClick={() => activateNow()}
            variant="contained"
            color="primary"
          >
            Activate Now
          </Button>
        </Grid>

        <Grid item xs={2} align="right" className={props.cssClasses.tableCell}>
          <Button
            onClick={() => unSchedule()}
            variant="contained"
          >
            UnSchedule
          </Button>
        </Grid>

          {
            isModified &&
            overlaps.length > 0 &&
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography display="inline">
                {renderWarning()}
              </Typography>
            </Grid>
          }
      </Hidden>

      {/* Mobile */}
      <Hidden mdUp>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            Pass ID
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {selectedPassData?._id}
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            Pass Name
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              {selectedPassData?.pass_type}
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            Viewers
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Typography>{selectedPassData?.max_allowed_users}</Typography>
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            Will Begin
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Input
              type="date"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              label="Choose Start Date"
              required
              color="primary"
            />
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            At
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Input
              type="time"
              value={startTime}
              onChange={e => setStartTime(e.target.value)}
              label="Choose Start Time"
              required
              color="primary"
            />
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            Will Expire
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Typography>{endsDateDisplay}</Typography>
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableHeader}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            Modified
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Checkbox
              type="checkbox"
              name="isModified"
              checked={isModified}
              disabled={true}
              hidden={true}
            />
          </Grid>
        </Grid>


        <Grid container className={props.cssClasses.tableBody}>
          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Button
              onClick={() => activateNow()}
              variant="contained"
              color="primary"
            >
              Activate Now
            </Button>
          </Grid>

          <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
            <Button
              onClick={() => unSchedule()}
              variant="contained"
            >
              UnSchedule
            </Button>
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableBody}>
          {
            isModified &&
            overlaps.length > 0 &&
            <Grid item xs={12} align="center" className={props.cssClasses.tableCell}>
              <Typography display="inline">
                {renderWarning()}
              </Typography>
            </Grid>
          }
        </Grid>

      </Hidden>






    </Grid>
  );

}
