import {
  Tooltip,
  Button,
  Badge,
} from "@mui/material"
import { Chat, ChevronLeft, ChevronRight, DoubleArrow, ExpandCircleDown, Forum, MenuOpen } from "@mui/icons-material"

export default function ToggleTextChatButton({
  unreadChats,
  isChatOpen,
  openChat
}: {
  unreadChats: number,
  isChatOpen: boolean,
  openChat: () => void
}) {

  return (
    <Tooltip
      title={isChatOpen ? "Collapse Chat" : "Open Text Chat"}
      placement="right-end"
    >
      <Button
        onClick={openChat}
        color="primary"
        variant="text"
        sx={{
          width: '38px',
          minWidth: "38px",
          maxWidth: "38px",
          height: '38px',
          marginTop: "6px",
        }}
      >
        <Badge
          badgeContent={
            isChatOpen ? null :
              unreadChats > 0 ? unreadChats : null
          }
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          {
            isChatOpen ?
            <>
                <DoubleArrow
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block"
                    }
                  }}
                />
                <ExpandCircleDown
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none"
                    }
                  }}
                />
              </>
              :
              <Forum />
          }
        </Badge>
      </Button>
    </Tooltip>
  )
}