import { useContext } from 'react';
import { SketchesContext } from './SketchesProvider';

export default function useSketches() {
  const context = useContext(SketchesContext);
  if (!context) {
    console.error('No Sketches provider found');
  }
  return context;
}
