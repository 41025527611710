import { useState, useEffect, useCallback } from "react"
import { SourceIdItem } from "../Reducers/SourceIdReducer"
import { useVideoChat } from "../VideoChatProvider"
import Debug from "debug"; const debug = Debug("SS:VideoChat:usePeerName");

export default function usePeerName(peer: SourceIdItem) {
  const [name, setName] = useState<string>("")

  const {
    members,
    presenceRoom
  } = useVideoChat()

  const updatePeerName = useCallback(() => {
    debug("Update peer name", peer, members)
    if (peer && members) {
      const member = members.get(peer.sourceId)
      debug("Member", member)
      if (member) {
        if (member.info && member.info.name) {
          setName(member.info.name)
        }
      }
    }
  }, [peer, members])

  useEffect(() => {
    updatePeerName()
  }, [updatePeerName])

  useEffect(() => {
    if (presenceRoom) {
      const handleMemberAdded = (member: { id: string, info: object }) => {
        debug("Member Added.  Updating name", member)
        updatePeerName()
      }
      presenceRoom.bind("pusher:member_added", handleMemberAdded);
      return (() => {
        presenceRoom.unbind("pusher:member_added", handleMemberAdded);
      })
    }
  }, [presenceRoom]);

  return name
}