// Full canvas reset
export default function clearCanvas(canvasContext:CanvasRenderingContext2D) {
  if (canvasContext) {
    canvasContext.restore();
    canvasContext.clearRect(
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height
    );
  }
}
