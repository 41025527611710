// This function will return the pass object for a {selectedPass} id from the {passes} list
//   passes: [{Passes}],
//   selectedPass: String /* Pass._id */

export default function getPassData(passes, selectedPass) {

  let selectedPassData;
  if (passes && passes.length > 0) {
    selectedPassData = passes.find(pass => pass._id.toString() === selectedPass);
  }
  return selectedPassData;
}
