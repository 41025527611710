import React, { useState, useEffect } from 'react';
import {
  Grid,
  Input,
  Button,
  CircularProgress
} from "@mui/material"
import { useSnackbar } from 'notistack';

export default function PurchasePassCoupon(props) {

  const {enqueueSnackbar} = useSnackbar()

  const [couponCode, setCouponCode] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);

  function updateCouponCode(value) {
    if (value.length < 50) {
      // TODO: Strip spaces and special characters
      setCouponCode(value)
    }
  }

  async function applyCoupon() {
    setInProgress(true)

    console.debug("Applying coupon %s %o", couponCode, props)

    try {
      if (!couponCode) {
        throw new Error("No Coupon Found.  Please update the coupon and try again.")
      }

      let url = process.env.REACT_APP_API_URL + "/api/purchase_pass_coupon/" + props.uuid
      let opts = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          code: couponCode
        })
      }
      
      let response = await fetch(url, opts);
      let res = await response.json();
      if (response.ok) {

         switch (res.type?.toLowerCase()) {
          case "success":
            // Store coupon data
            if (res.couponData) {
              props.setCouponData(res.couponData)
            }
   
            enqueueSnackbar("Coupon Applied", { variant: "info" })
            break;
         
          case "notfound":
            enqueueSnackbar(res.message, { variant: "warning" })
            console.error("Coupon not found")
            props.setCouponData(null)
            break;
         }
      } else {
        console.error("Error getting coupon", response.status, )
        enqueueSnackbar(res.message, { variant: "warning" })
        setCouponCode("")
        props.setCouponData(null)
      }
    } catch (error) {
      console.error("Could not update coupon %o", error)
      enqueueSnackbar("Network error applying coupon.  Please try again.", { variant: "warning" })
      props.setCouponData(null)
    } finally {
      setInProgress(false)
    }

  }

  return (
    <Grid container>
      <Grid item xs={12} align="left">
        <Input
          type="text"
          value={couponCode}
          onChange={e => updateCouponCode(e.target.value)}
          label="Apply Coupon"
          color="primary"
          style={{width:'90%'}}
        />
      </Grid>

      <Grid item xs={12} align="left">
        <Button
          variant="contained"
          color="primary"
          disabled={(inProgress || !couponCode)}
          onClick={applyCoupon}
        >
          Apply Coupon
        </Button>
        {
          inProgress &&
          <CircularProgress size={28} style={{ position: "relative", top: "8px", marginLeft: "12px" }} />
        }
      </Grid>
    </Grid>
  )
}