import React, { useEffect, useState } from 'react';

// requires an array of passes as props

export default function useHasAvailablePass(props) {
  const [hasActivePass, setHasActivePass] = useState(false);

  useEffect(() => {
    if (props && props.length > 0) {
      let currentlyActivePass = props.find(
        pass => !pass.expires_on
      );
      setHasActivePass(currentlyActivePass ? true : false);
    } else {
      setHasActivePass(false);
    }
  }, [props]);

  return hasActivePass;
}
