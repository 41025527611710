import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Select,
MenuItem,
FormControl,
InputLabel,
} from '@mui/material/';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

// ***
// Store selected pass state in parent
// Pass passes, seletctedPass, setSelectedPass as props

export default function SelectPass(props) {
  const classes = useStyles();

  useEffect(() => {
    // Remove selected pass if it's no longer available
    if (!props.passes.some(pass => pass._id === props.selectedPass)) {
      props.setSelectedPass(props.passes[(props.passes.length - 1)] ? props.passes[(props.passes.length - 1)]._id : '');
    } else if (
      props.selectedPass === '' &&
      props.passes[(props.passes.length - 1)] &&
      props.passes[(props.passes.length - 1)]._id
    ) { props.setSelectedPass(props.passes[(props.passes.length - 1)]._id); }

  }, [props.passes, props.selectedPass]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <FormControl
          focused
        >
          <InputLabel
            id="AccessPasses"
            className={props.cssClasses.selectLabel}
          >
            Select a Pass
          </InputLabel>
          <Select
            id='AccessPasses'
            label='AccessPasses'
            placeholder='Select a Pass'
            variant='outlined'
            color='primary'
            value={props.selectedPass}
            onChange={e => props.setSelectedPass(e.target.value)}
          >
            {
              props.passes.map((pass, i) => (
                <MenuItem value={pass._id} key={'AP'+i} >- {pass.pass_type} -</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

}
