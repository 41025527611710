import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { CookiesProvider } from 'react-cookie';

// Utility
import ComponentErrorBoundary from './errors/component_error_boundary';
import useDarkTheme from './themes/useDarkTheme';
import AppContent from './components/App/AppContent/AppContent';
import { BrandingProvider } from './components/Branding/Context/BrandingProvider';

// ************** APP ****************
export default function App() {
  const darkTheme = useDarkTheme();

  return (
    <ComponentErrorBoundary
      section='App'
      showErrors={true}
    >
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={darkTheme}>
            <BrandingProvider>
              <CssBaseline />
              <AppContent />
            </BrandingProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </ComponentErrorBoundary>
  );
}
