import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import formatTimeStamp from '../../utils/formatTimeElapsedShort';
import Linkify from 'linkify-react';

export default function ChatBubble(props) {

  return(
      <Grid xs={12}>

        <Paper
          sx={{
            backgroundColor: props.isLocal ? 
                "chat.local" : "chat.remote",
            color: "common.white",
            margin: props.isLocal ? 
              "0px 0px 4px 32px" : "0px 32px 4px 0px",
            padding: (2, 6),
            fontSize: '.8rem',
            borderRadius: props.isLocal ?
            "18px 0 18px 18px" : "0 18px 18px 18px",
            wordWrap: 'break-word',
            borderWidth: props.isLocal ?
              "0px 0px 2px 2px" : "0px 2px 2px 0px",
            borderStyle: "solid",
            borderColor: props.message.color ? 
              props.message.color : "solids.light",
          }}
        >
          <Grid container>

            <Grid xs={10}>

              <Typography
                variant='body1'
                align='left'
              >
                <Linkify options={{target:'_blank', rel:'noreferrer'}}>
                  {props.message.message}
                </Linkify>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>

            <Grid xs={9}>
              <Typography
                variant='subtitle2'
                align='left'
                sx={{
                  fontSize: '.7rem',
                  color: props.message.color ? 
                    props.message.color : "common.white" ,
                }}
              >
                {props.message.nickName}
              </Typography>

            </Grid>

            <Grid xs={3}>

              <Typography
                variant='subtitle2'
                align='right'
                sx={{
                  fontSize: '.6rem',
                  color: "text.secondary",
                }}
              >
              {formatTimeStamp(props.message.timestamp)}
              </Typography>
            </Grid>
          </Grid>

        </Paper>
      </Grid>
  )
}
