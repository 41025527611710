export interface AudioChannelTypes {
  id: number,
  label: string,
}

export const audioChannelTypes: AudioChannelTypes[] = [
  {
    id: 1,
    label: 'Mono'
  },
  {
    id: 2,
    label: 'Stereo'
  },
  {
    id: 3,
    label: '5.1 Surround (OBS Only)'
  }
]
