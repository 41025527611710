export default function createNewExpiration(pass, offset) {
  const allowedDurationUnits = [
    'minutes',
    'hours',
    'days',
    'weeks',
    'months',
    'years',
  ]

  let baseDate = new Date(Date.now() + offset);
  let expires;

  switch (pass.duration_units) {
    case 'minutes':
      expires = new Date(baseDate).setMinutes(baseDate.getMinutes() + Number(pass.active_duration));
      return expires;
      break;
    case 'hours':
      expires = new Date(baseDate).setHours(baseDate.getHours() + Number(pass.active_duration));
      return expires;
      break;
    case 'days':
      expires = new Date(baseDate).setHours(baseDate.getHours() + Number(Number(pass.active_duration) * 24));
      return expires;
      break;
    case 'weeks':
      expires = new Date(baseDate).setHours(baseDate.getHours() + Number(Number(pass.active_duration) * 24 * 7));
      return expires;
      break;
    case 'months':
      expires = new Date(baseDate).setMonth(baseDate.getMonth() + Number(pass.active_duration));
      return expires;
      break;
    case 'years':
      expires = new Date(baseDate).setFullYear(baseDate.getFullYear() + Number(pass.active_duration));
      return expires;
      break;
    default: // Assume minutes if not supplied
    expires = new Date(baseDate).setMinutes(baseDate.getMinutes() + Number(pass.active_duration));
    return expires

  }
}
