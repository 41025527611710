import { useContext } from 'react';
import { DashboardAccountContext } from './DashboardAccountProvider';

export default function useDashboardAccount() {
  const context = useContext(DashboardAccountContext);
  if (!context) {
    console.error('No Dashboard account provider found');
  }
  return context;
}
