import dayjs from "dayjs";
import { Marker } from "../sketches_types";
import { mEvent } from "../sketches_types";

export default function mergeMarkerHistory(
  markerHistory:Marker[], 
  setMarkerHistory:(markerHistory: Marker[]) => void,
  markersToMerge: Marker[],
  append: boolean,
): void {

  // Find new markers
  let markersToAdd: Marker[] = markersToMerge.filter(marker => {
    let foundMarker: Marker | undefined = markerHistory.find(sk => sk.uuid === marker.uuid);
    if (!foundMarker) { return marker }
  })

  // Use lastUpdated date to check if we need to update 
  let markersToUpdate: Marker[] = markerHistory.map((marker: Marker): Marker => {
    // Does marker exist in markersToMerge?
    let markerToCheck: Marker | undefined = markersToMerge.find(stf =>  (
      stf.uuid === marker.uuid
    ));
    // If not, keep original
    if (!markerToCheck) { return marker; }

    // If so, are .updated times the same?
    let updateCheck: number = dayjs(markerToCheck.updated).diff(dayjs(marker.updated));
    
    if (updateCheck > 0) {
      // Merge or replace the existing marker with the newer marker
      if (append) {
        let newMarker: Marker = {...marker}
        newMarker.mEvents = marker.mEvents.concat(markerToCheck.mEvents)
        return newMarker;
      } else {
        return markerToCheck
      }
    } else {
      // Use the marker from markerHistory if it is newer or the same .updated date
      return marker;
    }
  });
  
  // Combine and store
  let newMarkerHistory = markersToUpdate.concat(markersToAdd);
  setMarkerHistory(newMarkerHistory);

}