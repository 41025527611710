import {
  getPriceListByViewers
} from '../constants/prices';

// Calculate the price of a new pass
export default function calculatePassPrice(totalViewers, active_duration, duration_units) {

  let price = 0;

  // Tiers by # of viewers
  let tiers = [1, 2, 3, 5, 10, 20, 30, 45, 100, 1000];
  let priceList = getPriceListByViewers(active_duration, duration_units);

  // Could not find the price list
  if (!priceList) { return 0; }

  // Add support for Viewer counts > 100 @ the 100 Viewer tier price
  // This is required so there is always one more tier above the highest pricing tier
  priceList[1000] = (((priceList[100] - priceList[45]) / 55) * 900) + priceList[100];

  // Get tiers
  let lastFullTier = tiers
    .filter(tier => (
      tier <= totalViewers
    ))
    .pop();
  let lastFullTierIndex =  tiers.indexOf(lastFullTier);

  let topTier = tiers[lastFullTierIndex + 1];
  let topTierIndex = lastFullTierIndex + 1;

  // Return price if 1 viewer
  if (topTierIndex === 0) {
    return priceList[tiers[topTierIndex]];
  }

  // Calculate full cost on new combined access

  // if viewers === topTier then use tier
  if (totalViewers === topTier) {
    return priceList[tiers[topTierIndex]];
  }

  // if viewers < topTier then
  if (totalViewers < topTier) {

    // subtract topTier - tier[1] to get # users in tier
    let usersInTier = topTier - lastFullTier

    // subtract lastFullTier price from topTier price
    // to get cost diff for topTier
    let lastTierCost = priceList[topTier] - priceList[lastFullTier]

    // Divide by # users in tier
    let lastTierPerUserCost = lastTierCost / usersInTier

    // Set price to lastFullTier
    price = priceList[lastFullTier] * active_duration;

    // create partial tier price
    let usersToPriceInTopTier = totalViewers - lastFullTier;
    let usersInTopTierCost = lastTierPerUserCost * usersToPriceInTopTier;

    // Add partial tier cost to price
    price += usersInTopTierCost * active_duration;

  }
  return price;
}
