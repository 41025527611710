import { useState, useEffect, useCallback } from "react";
import useApiRequest, { RequestMethod } from "../../../../../hooks/useApiRequest/useApiRequest";
import { useSnackbar } from "notistack";
import Debug from "debug";import useEvents from "../../Events/useEvents";
 const debug = Debug("SS:VideoChat:useToken")

interface ResponseData {
  type: string;
  message: string;
  streamName: string;
  pubTokenId: string;
  pubToken: string;
  accountId: string;
  subTokenId: string;
  subToken: string;
}

export default function useVideoChatToken(userUuid: string) {
  // State
  const [streamName, setStreamName] = useState<string>("");
  const [pubTokenId, setPubTokenId] = useState<string>("");
  const [pubToken, setPubToken] = useState<string>("");
  const [accountId, setSubAccountId] = useState<string>("");
  const [subTokenId, setSubTokenId] = useState<string>("");
  const [subToken, setSubToken] = useState<string>("");
  const [canConnect, setCanConnect] = useState<boolean>(false);
  
  const { makeApiRequest, cancelApiRequest, inProgress } = useApiRequest()
  const { enqueueSnackbar } = useSnackbar()

  const { dispatch } = useEvents()

  // Callbacks
  const getVcToken = useCallback(async () => {
    try {
    const {response, status, error} = await makeApiRequest<ResponseData>(
      process.env.REACT_APP_API_URL + '/api/videochat/token/' + userUuid,
      RequestMethod.get
    )
    if (error || status < 200 || status > 299) {
      if (response?.type === "inactive") {
        debug("No active session")
        // enqueueSnackbar("No active session.  Please activate a pass or subscription and try again.")
      } else {
        debug("Error getting video chat token", status, error, response)
        throw new Error(`Getting Video Chat Token errored with status ${status} and error ${error}`)
      }
    } else if (response) {
      if (response.accountId) { setSubAccountId(response.accountId) }
      if (response.streamName) { setStreamName(response.streamName) }
      if (response.pubTokenId) { setPubTokenId(response.pubTokenId) }
      if (response.pubToken) { setPubToken(response.pubToken) }
      if (response.subTokenId) { setSubTokenId(response.subTokenId) }
      if (response.subToken) { setSubToken(response.subToken) }
      if (
        response.accountId &&
        response.streamName &&
        response.pubToken && 
        response.subToken
      ) {
        setCanConnect(true)
      }
    } else {
      throw new Error("No response received")
    }

    } catch (error) {
      debug("Could not get Video Chat authorization", error)
      enqueueSnackbar("Could not get Video Chat authorization", { variant: "error" })
    }  
  }, [userUuid])

  // Effects
  // Get token on load
  useEffect(() => {
    getVcToken()
    return () => {
      cancelApiRequest("Cleanup")
    }
  }, [])
  
  useEffect(() => {
    const handelActivate = (message: string) => {
      debug("Activating", message)
      getVcToken()
    }
    
    const handleRemoveSub = (message: string) => {
      debug("Removing Sub", message)
      getVcToken()
    }
    
    dispatch?.on("activate", handelActivate)
    dispatch?.on("remove_subscription", handleRemoveSub)
    return () => {
      dispatch?.off("activate", handelActivate)
      dispatch?.off("remove_subscription", handleRemoveSub)
    }
  }, [dispatch, getVcToken])


  return {
    streamName,
    pubTokenId,
    pubToken,
    accountId,
    subTokenId,
    subToken,
    canConnect,
    loadingAccess: inProgress,
  }
}