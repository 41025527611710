import Debug from "debug"; const debug = Debug("SS:Project:ViewersTab");
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { TextField } from '@mui/material';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ViewerPermissions from './viewer_permissions';
import InviteViewers from './invite_viewers';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import BlockIcon from '@mui/icons-material/Block';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import copy from 'copy-to-clipboard';
import Hidden from '@mui/material/Hidden';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { TransitEnterexit } from '@mui/icons-material';
import WaitingRoomList from './WaitingRoom/WaitingRoomList';

const useStyles = ((theme) => ({
  inviteScrollBox: {
    maxHeight: 'calc(100vh - 400px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      heigh: '100%',
      maxHeight: '100%',
      overflowY: 'auto'
    }
  },
  inviteScrollBoxNoVC: {
    maxHeight: 'calc(100vh - 302px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      heigh: '100%',
      maxHeight: '100%',
      overflowY: 'auto'
    }
  },
  statusError: {
    color: theme.palette.warning.main,
  },
  viewerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid ' + theme.palette.shades.superDark,
    borderRadius: '8px',
    padding: '4px'
  },
  viewerHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid ' + theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
  removeButton: {
    background: theme.palette.background.light,
    color: theme.palette.text.secondary,
    fontWeight: 'normal'
  },
  inviteButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    fontWeight: 'normal'
  },

}));
const default_authorized_users = [{
  email: '-',
  uuid: '-',
  nickName: '-',
  emailStatus: 'success'
}];

const default_anon_users = [{
  email: '-',
  uuid: '-',
  nickName: '-',
  emailStatus: 'success'
}];

const default_connected_users = [{
  uuid: '-',
  socketID: '-',
  name: '-',
  room: '-',
  email: '-'
}];


class ViewersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cookies: Cookies.get(props.publisher_uuid),
      email: '',
      authorized_users: default_authorized_users,
      anon_users: default_anon_users,
      // connected_users: default_connected_users,
      confirm_open: false,
      anchorEl: null,
      open_settings: null
    }

    this.setFormState = this.setFormState.bind(this);
    this.resendInvite = this.resendInvite.bind(this);
    this.logOutViewer = this.logOutViewer.bind(this);
    this.resend_all_invites = this.resend_all_invites.bind(this);
    this.getAuthUsers = this.getAuthUsers.bind(this);
    this.close_settings = this.close_settings.bind(this);

  }

  // Update state
  setFormState(event) {
    const name = event.target.name;
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    this.setState({...this.state, [name]: value});
  }

  // User permissions menu control
  open_settings(e, proj_id) {
    this.setState({
      anchorEl: e.currentTarget,
      open_settings: proj_id
    })
  }

  close_settings = (e) => {
    this.setState({
      anchorEl: null,
      open_settings: null
    })
  }

  // Resend Invite to user
  resendInvite(e) {
    // Close modal
    this.close_settings();
    
    axios.post(process.env.REACT_APP_API_URL + '/api/resend_invite/' + this.props.publisher_uuid, {email: e, publisher_uuid: this.props.publisher_uuid}, { withCredentials: true })
    .then(result => {
      this.props.enqueueSnackbar(result.data.message, { variant: 'success' });
    })
    .catch(err => {
      this.props.enqueueSnackbar('Looks like something went wrong', { variant: 'error' });
      console.error('Error - ', err);
    });
    this.props.enqueueSnackbar('Submitting your request, please stand by ....', { variant: 'info' });
  }

  // Resend invites to all viewers
  resend_all_invites(e) {
    axios.post(process.env.REACT_APP_API_URL + '/api/resend_all_invites/' + this.props.publisher_uuid, {publisher_uuid: this.props.publisher_uuid}, { withCredentials: true })
    .then(result => {
      this.props.enqueueSnackbar(result.data.message, { variant: 'success' });
      // console.log('Resent invite to ', e);
    })
    .catch(err => {
      this.props.enqueueSnackbar('Looks like something went wrong', { variant: 'error' });
      console.error('Error - ', err);
    });
    this.props.enqueueSnackbar('Submitting your request, please stand by ....', { variant: 'info' });
    // console.log('Resending invite to ', e);
  }

    // Remove an authorized user
  deleteUser(e) {
    // Close modal first
    this.handleClose()

    let toSubmit = {
      authUser: this.state.authorized_users[e],
      publisher_uuid: this.props.publisher_uuid
    };
    axios.post(process.env.REACT_APP_API_URL + '/api/deny_viewer/' + this.props.publisher_uuid, toSubmit, { withCredentials: true })
    .then(res => {
      if (res.data.type) {
        switch (res.data.type) {
          case 'success':

            // this.subscribePusher();
            this.getAuthUsers();

            this.props.enqueueSnackbar('Successfully removed user access', { variant: 'success' });
            break;
          case 'error':
            console.error('Could not delete user ', res.data.message);
            this.props.enqueueSnackbar('Looks like we had issue ' + res.data.message, { variant: 'warning' });
            break;
          default:
        }

      }
    })
    .catch(err => {
      console.error('Error - ', err);
    });
  }

  // Log out selected Viewer
  async logOutViewer(viewerToLogOut) {
    // Close modal
    this.close_settings();
    
    let fetchData = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        room: this.props.room,
        viewerToLogOut: viewerToLogOut
      })
    }

    try {
      let res = await fetch(process.env.REACT_APP_API_URL + "/api/logout_viewer/" + this.props.publisher_uuid, fetchData);
      if (res.ok) {
        // let response = await res.json();
        // Should I update the dashboard here?  This is probably uneccessary as the update will happen
        // once the viewer is successfully logged out
      }
    } catch (error) {
      this.enqueueSnackbar('Error logging out viewer.  Try removing access instead', { variant: 'error' });
      console.error('Could not log out user ', error);
    }
  }

  // List currently authorized users
  getAuthUsers() {
    // Request the list of authorized users
    axios.get(process.env.REACT_APP_API_URL + '/api/invite_viewer/' + this.props.publisher_uuid, { withCredentials: true })
    .then(res => {
      switch (res.data.type) {
        case 'logged_out':
          this.props.enqueueSnackbar('Looks like you are logged out.  Please log in again.', { variant: 'info' });
          console.log('Logged out ', res.data.message);
          // window.location.reload(false);
          break;
        default:
          if (res.data.authorized_users) {
            let authUsers = res.data.authorized_users ? res.data.authorized_users : [];
            // Update online status indicator
            if (this.props.presenceRoom) {
              this.props.presenceRoom.members.each(member => {
                let foundViewer = authUsers.findIndex(aUser => aUser.uuid === member.id)
                if (foundViewer !== -1) {
                  authUsers[foundViewer].isOnline = true
                }
              })
            }
            this.setState({
              authorized_users: authUsers,
            });
          }
          break;
      }
    })
    .catch(err => {
      console.error('Error - Could not retreive auth users', err);
    });
  }

  subscribePusher() {
    // Listen for user disconnects
    if (this.props.soloroom) {

      this.props.soloroom.bind('email_status', message => {
        // Get updated user list
        console.log('Email Status Change ', message);
        this.getAuthUsers();
      });
    }

    if (this.props.channel) {
      this.props.channel.bind("viewer_online", message => {
        debug("Viewer Online", message)
        if (message?.viewer) {

          // Don't add ourselves to the list
          if (
            !message.viewer ||
            message.viewer.uuid === this.props.publisher_uuid ||
            message.viewer.uuid === this.props.userUuid ||
            message.viewer.viewerType === "project" ||
            message.viewer.viewerType === "broadcaster" ||
            message.viewer.viewerType === "producer"
          ) { 
            debug("Skipping ourselves", message)
            return 
          }

          let currentViewers = this.state.authorized_users;
          let foundViewer = currentViewers.findIndex(aUser => aUser.uuid === message.viewer.uuid)
          if (foundViewer === -1) { 
            // Viewer not found, add
            let newViewer = message.viewer;
            newViewer.isOnline = true;
            currentViewers.push(newViewer)
          } else { 
            // Viewer found, update
            let newViewer = currentViewers[foundViewer];
            newViewer.isOnline = true;
            currentViewers[foundViewer] = newViewer
          }
          this.setState({
            authorized_users: currentViewers
          })
        }
      })

      this.props.channel.bind("viewer_offline", message => {
        if (message?.viewer) {
          let currentViewers = this.state.authorized_users.filter(aUser => {
            if (aUser.uuid === message.viewer.uuid) {
              // Only update and return offline email viewers
              if (aUser.viewerType === 'email') {
                let newViewer = aUser;
                newViewer.isOnline = false;
                return newViewer
              }
            } else { return aUser }
          })
          this.setState({
            authorized_users: currentViewers
          })
        }
      })
    }

    if (this.props.presenceRoom) {
      // console.log("Subscribe to presenceRoom ", this.props.presenceRoom);
      this.props.presenceRoom
      .bind("pusher:subscription_succeeded", event => {
        let authUsers = this.state.authorized_users
        this.props.presenceRoom.members.each(member => {           
          let foundViewer = authUsers.findIndex(aUser => aUser.uuid === member.id)
          if (foundViewer !== -1) {
            authUsers[foundViewer].isOnline = true
            if (member.info?.name) {
              authUsers[foundViewer].nickName = member.info.name
            }
          }
        })
        this.setState({
          authorized_users: authUsers,
        });
      })
      .bind("pusher:member_added", member => {
        debug("Member Added", member)
        let authUsers = this.state.authorized_users
        let foundViewer = authUsers.findIndex(aUser => aUser.uuid === member.id)
        if (foundViewer !== -1) {
          authUsers[foundViewer].isOnline = true
          if (member.info?.name) {
            authUsers[foundViewer].nickName = member.info.name
          }
          this.setState({
            authorized_users: authUsers,
          });
        }
      })
      .bind("pusher:member_removed", member => {
        let authUsers = this.state.authorized_users
        let foundViewer = authUsers.findIndex(aUser => aUser.uuid === member.id)
        if (foundViewer !== -1) {
          authUsers[foundViewer].isOnline = false
          this.setState({
            authorized_users: authUsers,
          });
        }
      })
    }

  }

  // Modal handlers
  handleOpen(i, email) {
    this.close_settings();
    this.setState({
      user_i_to_remove: i,
      user_email_to_remove: email,
      confirm_open: true
    });
  };

  handleClose() {
    this.setState({
      user_i_to_remove: null,
      user_email_to_remove: null,
      confirm_open: false
    })
  };

  componentDidMount() {
    if (this.state.cookies) {
      this.subscribePusher();
      this.getAuthUsers();
    }
  }

  // Copy text to clipboard
  copy_text(event, title, text) {
    copy(text);
    this.close_settings();
    this.props.enqueueSnackbar(title + ' Copied', { variant: 'info' });
  }

  // Check if user is online and return icon
  isOnline(uuid) {
    let isOn = false;
    if (this.props.members) {
      let memberCheck = this.props.members.get(uuid)
      if (memberCheck) {
        isOn = true
      } 
    }
    
    if(isOn === true ) {
      return (
        <Typography color="primary">
          Online
        </Typography>
      )
    } else {
      return (
        null
      )
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid>
        <Grid item style={{marginTop: '4px'}}>
          <form>
            <Grid component={Paper}>
              <Grid aria-label="Authorized Viewers">

                <Grid
                  container
                  direction="row"
                  className={this.props.isVCOpen ? classes.inviteScrollBox : classes.inviteScrollBoxNoVC}
                >

                  <Grid
                    container
                    direction="row"
                    className={classes.viewerHeader}
                  >
                    <WaitingRoomList
                      cssClasses={this.props.cssClasses}
                      publisher_uuid={this.props.publisher_uuid}
                      userUuid={this.props.userUuid}
                      room={this.props.room}
                      pusher={this.props.pusher}
                      soloroom={this.props.soloroom}
                      presenceRoom={this.props.presenceRoom}
                      setWaitingRoomLength={this.props.setWaitingRoomLength}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    className={classes.viewerHeader}
                  >
                    <Grid
                      container
                      className={this.props.cssClasses.tableHeader}
                    >
                      <Typography>
                        Email Invites
                      </Typography>
                    </Grid>
                    <InviteViewers
                      cssClasses={this.props.cssClasses}
                      getAuthUsers={this.getAuthUsers}
                      authUsers={this.state.authorized_users}
                      publisher_uuid={this.props.publisher_uuid}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    className={this.props.cssClasses.tableHeader}
                  >
                    <Hidden mdDown>
                      <Grid item align="center" xs={1} style={{margin: 'auto'}}>
                      </Grid>
                      <Grid item align="left" xs={5} style={{margin: 'auto'}}>
                        <Typography>
                          Viewer
                        </Typography>
                      </Grid>

                      <Grid item align="center" xs={6} md={1} style={{margin: 'auto'}}>
                        <Typography>
                          Status
                        </Typography>
                      </Grid>

                      <Grid item align="center" xs={12} md={5} style={{margin: 'auto'}}>
                        <Typography>
                          Permissions
                        </Typography>
                      </Grid>
                    </Hidden>

                  </Grid>

                  {
                    this.state.authorized_users.map((user, i) => {
                      if (
                        // i > 0 // Don't return the admin object
                        user.uuid !== this.props.publisher_uuid &&
                        user.uuid !== this.props.userUuid
                      ) {
                      return (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={classes.viewerBox}
                          key={'GDO'+i}
                        >

                          <Grid item xs={2} md={1} key={'ELEL'+i} style={{margin: 'auto', "textAlign": 'center'}}>
                            <IconButton onClick={e => this.open_settings(e, user.uuid)} size="large">
                              <SettingsIcon />
                            </IconButton>
                            <Menu
                              id={user.uuid + "-settings"}
                              anchorEl = {this.state.anchorEl}
                              keepMounted
                              open={this.state.open_settings === user.uuid}
                              onClose={e => this.close_settings()}
                              PaperProps={{
                                className:this.props.cssClasses.popoverMenu,
                                style: {
                                  // width: '40vw',
                                },
                              }}
                              MenuListProps={{
                                style: {

                                }
                              }}
                            >
                              <Grid container align="center" className={this.props.cssClasses.tableHeader}>
                                {user.email}
                              </Grid>

                              <Grid container className={this.props.cssClasses.settingsContainer}>

                                {
                                  user.email &&
                                  user.email !== "-" &&
                                  <Grid item xs={12}>
                                    <MenuItem
                                      onClick={e => this.resendInvite(user.email)}
                                      >
                                      <Grid container>
                                        <Grid item align="center" xs={2} sm={1}>
                                          <SendIcon />
                                        </Grid>

                                        <Grid item xs={10} sm={11}>
                                          Resend Invite
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  </Grid>
                                }

                                <Grid item xs={12}>
                                  <MenuItem
                                    onClick={e => this.logOutViewer(user.uuid)}
                                  >
                                    <Grid container>
                                      <Grid item align="center" xs={2} sm={1}>
                                        <ExitToAppIcon />
                                      </Grid>
                                      <Grid item xs={10} sm={11}>
                                        Log Out Viewer
                                      </Grid>
                                    </Grid>

                                  </MenuItem>
                                </Grid>

                                <Grid item xs={12}>
                                  <MenuItem
                                    onClick={e => this.handleOpen(i, user.email)}
                                  >
                                    <Grid container>
                                      <Grid item align="center" xs={2} sm={1}>
                                        <CancelIcon />
                                      </Grid>
                                      <Grid item xs={10} sm={11}>
                                        Remove Access
                                      </Grid>
                                    </Grid>

                                  </MenuItem>
                                </Grid>

                                {
                                  user.viewerType === 'email' &&
                                  <Grid item xs={12}>
                                    <MenuItem
                                      onClick={e => this.copy_text(e, 'Link', process.env.REACT_APP_HOST_URL + '/VideoVillage/' + this.props.room + '/' + user.uuid)}
                                      >
                                      <Grid container>
                                        <Grid item align="center" xs={2} sm={1}>
                                          <FileCopyIcon color="secondary" />
                                        </Grid>
                                        <Grid item xs={10} sm={11}>
                                          Copy Link
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  </Grid>
                                }

                                {
                                  user.viewerType === 'email' &&
                                  <Grid item xs={12}>
                                    <MenuItem
                                      onClick={e => this.copy_text(e, 'Password', user.passHash)}
                                      >
                                      <Grid container>
                                        <Grid item align="center" xs={2} sm={1}>
                                          <FileCopyIcon color="secondary" />
                                        </Grid>
                                        <Grid item xs={10} sm={11}>
                                          Copy Password
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  </Grid>
                                }

                              </Grid>

                            </Menu>
                          </Grid>

                          <Grid item xs={10} md={5} key={'TC'+i} style={{margin: 'auto', flex:1}}>
                            <Grid container>
                              <Grid item xs={10} sm={11} className={this.props.cssClasses.tableCell}>
                                <Typography
                                  key={'TPN'+i}
                                  variant="h6"
                                  style={{overflowWrap: 'break-word'}}
                                >
                                  {user.nickName}
                                </Typography>
                                <Typography
                                  key={'TP'+i}
                                  variant='h6'
                                  className={user.emailStatus === 'Errored' ? classes.statusError : null}
                                  style={{overflowWrap: 'break-word'}}
                                >
                                  {user.email === '-' ? "" : user.email}
                                </Typography>

                              </Grid>

                              <Grid item xs={2} sm={1} className={this.props.cssClasses.tableCell}>
                                <IconButton onClick={e => this.handleOpen(i, user.email)} size="large">
                                    <CancelIcon />
                                  </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={1} key={'EL'+i} style={{margin: 'auto', "textAlign": 'center'}}>

                            <Grid container>
                              <Grid item xs={6} md={12}>
                                {/* {this.isOnline(user.uuid)} */}
                                {
                                  user.isOnline && 
                                  <Typography color="primary">
                                    Online
                                  </Typography>
                                }
                              </Grid>

                              <Grid item xs={6} md={12}>
                                  <Typography
                                    key={'TS'+i}
                                    className={user.emailStatus === 'Errored' ? classes.statusError : null}
                                  >
                                  {user.emailStatus ? user.emailStatus : '-'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <ViewerPermissions
                              viewer_index={i}
                              viewer={user}
                              enabledCameras={this.props.enabledCameras}
                              publisher_uuid={this.props.publisher_uuid}
                            />
                          </Grid>
                        </Grid>
                      );}
                    })
                  }

                  <Dialog
                    open={this.state.confirm_open}
                    onClose={this.handleClose}
                  >
                    <DialogTitle
                      className={this.props.cssClasses.dtitle}
                    >
                      <Typography
                        variant='h5'
                      >
                        Remove Access
                      </Typography>
                    </DialogTitle>

                    <DialogContent
                    >
                      <Grid container className={this.props.cssClasses.settingsContainer}>
                        <Grid item xs={12}>
                          <Typography>
                            This will remove access for {this.state.user_email_to_remove}.
                          </Typography>

                          <Typography>
                            Are you sure you would like to continue?
                          </Typography>
                        </Grid>
                      </Grid>
                    </DialogContent>

                    <DialogActions >
                      <Button
                        id = 'cancel'
                        onClick = {e => this.handleClose()}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <Button
                        id = 'remove'
                        onClick = {e => this.deleteUser(this.state.user_i_to_remove)}
                        variant="contained"
                        color="primary"
                      >
                        Remove Access
                      </Button>
                    </DialogActions>
                  </Dialog>

                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(ViewersTab)))
