import React, { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import { Table, TableContainer, TableRow, TableBody, TableCell, TableHead } from '@mui/material';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import EditPassDialog from './admin_edit_pass_value_dialog.tsx';
import UserSearchDialog from './admin_woo_users_search_dialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = ((theme) => ({
}));

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      wp_username: '',
      customer_id: '',
      customer_uuid: '',
      customer_woo_id: '',
      customer_search: '',
      userSearchResults: [],
      isSearchDialogOpen: false,
      searchInProgress: false,
      headers: {
        _id: 'ID',
        customer_user_name: 'User Name',
        customer_email: 'Email',
        customer_id: 'Woo ID',
        customer_uuid: 'UUID',
      },
      user_info: [{
        _id: '-',
        customer_id: '-',
        customer_uuid: '-',
        customer_email: '-',
        customer_user_name: '-'
      }],
      passTypes: [
        'Add_On_10',
        '1_Hour_5',
        '3_Hour_5',
        '5_Hour_5',
        '3_Hour_3',
        '3_Hour_10',
        '24_Hour_10',
        '24_Hour_20',
        '24_Hour_30',
        '24_Hour_45',
        'Free_Trial'
      ],
      createPassViewers: 3,
      createPassDuration: 1,
      createPassDurationUnits: 'hours',
      selectedAccessPass: 'Free_Trial',
      confirm_create_open: false,
      confirm_activate_open: false,
      orderNumberToActivate: 0,
      pass_headers: {
        _id: 'Pass ID (Click to EDIT)',
        pass_type: 'Pass Type',
        is_available: 'Available?',
        is_active: 'Active?',
        active_duration: 'Duration',
        duration_units: 'Type',
        max_allowed_users: 'Max Users',
        order_id: 'Order ID',
        product_id: 'Product ID',
        purchased_on: 'Purchased On',
        activated_on: 'Activated On',
        begins_on: 'Begins On',
        expires_on: 'Expires On',
        session_id_activated_on: 'Session ID Activated On'
      },
      user_passes: [{
        _id: '-',
        order_id: '-',
        product_id: '-',
        purchased_on: '-',
        activated_on: '-',
        begins_on: '-',
        expires_on: '-',
        active_duration: '-',
        duration_units: '-',
        max_allowed_users: '-',
        session_id_activated_on: '-',
        pass_type: '-'
      }],
      subscription_headers: {
        stripe_id: 'Stripe ID',
        last_update: 'Last Update',
        description: 'Description',
        status: 'Status',
        ended: "Ended",
        max_allowed_users: "Max Allowed Users",
        session_id_activated_on: 'Session ID Activated On',
        subscriber_token_id: 'Subscriber Token ID',
        begins_on: 'Begins On',
        expires_on: 'Expires On',
        created_on: 'Created On',
        active_duration: "Active Duration",
        duration_units: 'Duration Units',
      },
      subscriptions: [{
        stripe_id: '-',
        last_update: '-',
        description: '-',
        status: '-',
        ended: false,
        begins_on: '-',
        expires_on: '-',
        created_on: '-',
        active_duration: 0,
        duration_units: '-',
        max_allowed_users: 0,
        subscriber_token_id: '-',
        session_id_activated_on: '-',
      }],
      sessions_headers: {
        _id: 'Project ID',
        project_name: 'Project Name',
        project_is_archived: 'Project Is Available',
        publisher_uuid: 'Publisher UUID',
        broadcaster_uuid: 'Broadcaster UUID',
        subscriber_uuid: 'Subscriber UUID',
        publisher_token_id: 'Publisher Token ID',
        subscriber_token_id: 'Subscriber Token ID',
        // currently_active_pass: 'Active Pass ID',
        // currently_active_pass_expires: 'Active Pass Expires At',
        last_pass_activated_at: 'Last Pass Activated At',
        // connected_user_count: 'Connected Users Count',
      },
      user_sessions: [{
        _id: '-',
        project_name: '-',
        project_is_archived: '-',
        publisher_uuid: '-',
        broadcaster_uuid: '-',
        subscriber_uuid: '-',
        publisher_token_id: '-',
        subscriber_token_id: '-',
        // currently_active_pass: '-',
        // currently_active_pass_expires: '-',
        last_pass_activated_at: '-',
        // connected_user_count: '-',
      }],
      isPassEditorOpen: false,
      passToEdit: {
        _id: '-',
        max_allowed_users: 0,
        active_duration: 0,
        pass_type: '-'
      },
      inProgress: false,
    }

    this.setFormState = this.setFormState.bind(this);
    this.getUser = this.getUser.bind(this);
    this.refreshUser = this.refreshUser.bind(this);
    this.getSearchedUser = this.getSearchedUser.bind(this);

  }

  Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://setstream.io" underline="hover">
          SetStream.io
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  refreshList() {
    this.setState({...this.state});
  }

  // Update state
  setFormState(event) {
    const name = event.target.name;
    this.setState(
      {
        [name]: event.target.value
      }
    );
  }

  // Retrieve user info
  getUser(e, lookup) {
    if (e !== null) {
      e.preventDefault();
    }

    this.setState({inProgress: true});

    let postData = null;
    switch (lookup) {
      case 'email':
        console.log('Getting User with email: ', this.state.email);
        postData = {
          email: this.state.email.toLowerCase()
        }
        this.setState({
          email: '',
        })
        break;
      case 'wp_username':
        console.log('Getting user with WP User Name ', this.state.wp_username);
        postData = {
          wp_username: this.state.wp_username.toLowerCase()
        }
        break;
      case 'customer_id':
        console.log('Getting User with _id: ', this.state.customer_id);
        postData = {
          customer_id: this.state.customer_id.toLowerCase()
        }
        this.setState({
          customer_id: ''
        });
        break;
      case 'customer_uuid':
        console.log('Getting User with UUID: ', this.state.customer_uuid);
        postData = {
          customer_uuid: this.state.customer_uuid.toLowerCase()
        }
        this.setState({
          customer_uuid: ''
        });
        break;
      case 'customer_woo_id':
        console.log('Getting User with Woo ID: ', this.state.customer_woo_id);
        postData = {
          customer_woo_id: this.state.customer_woo_id
        }
        this.setState({
          customer_woo_id: ''
        });
        break;
      case '_id':
        console.log('Getting User with _id: ', this.state.user_info[0]._id);
        postData = {
          customer_id: this.state.user_info[0]._id
        }
        break;
      default:
        this.props.enqueueSnackbar('Invalid Request Data for ', lookup, { variant: 'error' });
        return
    }

    if (postData !== null) {
      axios.post(
        process.env.REACT_APP_API_URL + '/api/admin_woo_users_list/' + lookup + '/' + this.props.userName, 
        postData,
        { withCredentials: true }
      )
      .then(user_data => {
        this.setState({inProgress: false});

        switch (user_data.data.type) {
          case 'success':
            console.log('GOT User data ', user_data.data);
            this.setState({
              user_info: user_data.data.user_info,
              user_sessions: user_data.data.session_list,
              user_passes: user_data.data.passes,
              subscriptions: user_data.data.subscriptions
            });
            break;
          case 'unauthorized':
            this.props.enqueueSnackbar('You are not authorized for this page.', {variant: 'error'})
            this.props.history.push('/login');
            break;
          case 'error':
          this.props.enqueueSnackbar('Could not retrieve user - ' + user_data.data.message, { variant: 'warning' });
            console.log('Could not retrieve user info ', user_data.data);
            break;
          default:

        }
        console.log('GOT USER ', user_data.data);
      })
      .catch(err => {
        this.setState({inProgress: false});
        this.props.enqueueSnackbar('Error - ' + err.message, { variant: 'error' });
        console.error('Error - Could not retrieve user data', err);
      });
    } else {
      this.props.enqueueSnackbar('Invalid Request ', lookup, { variant: 'error' });
      console.error('Invalid Lookup Request with ', postData);
    }
  }

  // Search for users
  searchForUsers(e) {
    e.preventDefault();

    // Search for users then open dialog
    let postData = { search: this.state.customer_search };
    axios.post(
      process.env.REACT_APP_API_URL + '/api/admin_woo_users_list/search/' + this.props.userName, 
      postData,
      { withCredentials: true }
    )
    .then(response => {
      if (
        response &&
        response.data &&
        response.data.type
      ) {
        switch (response.data.type) {
          case 'success':
            this.setState({
              isSearchDialogOpen: true,
              userSearchResults: response.data.users,
            });
            break;
          case 'no-users':
            this.props.enqueueSnackbar('No users found for that search', { variant: 'warning' });
            break;
          case 'error':
          default:
            this.props.enqueueSnackbar('Could not search for users ' + response.data.message, { variant: 'error' })
            break;
        }
      } else {
        this.props.enqueueSnackbar('Incorrect response ' + response.data, { variant: 'error' });
      }
    })
    .catch(err => {
      console.error(err.message);
    })
  }

  getSearchedUser(id) {
    this.setState({
      customer_woo_id: id,
      isSearchDialogOpen: false,
      customer_search: '',
    }, () => {
      this.getUser(null, 'customer_woo_id')
    })
  }

  // Refresh user data after update
  refreshUser() {
    this.getUser(null, '_id');
  }

  // Create a new pass
  createNewPass(pass_type) {
    this.handleClose();
    if (this.state.user_info[0] && this.state.user_info[0]._id) {
      let passToCreate = {
        customer_id: this.state.user_info[0]._id,
        viewers: this.state.createPassViewers,
        duration: this.state.createPassDuration,
        duration_units: this.state.createPassDurationUnits,
      }
      axios.post(
        process.env.REACT_APP_API_URL + '/api/admin_woo_users_list/create_pass/' + this.props.userName, 
        passToCreate,
        { withCredentials: true }
      )
      .then(result => {
        switch (result.data.type) {
          case 'success':
            this.props.enqueueSnackbar(result.data.message, { variant: 'info' });
            this.getUser(null, '_id');
            console.log('Successfully created new pass');
            break;
          case 'error':
            this.props.enqueueSnackbar(result.data.message, { variant: 'warning' });
            console.error('Error creating new pass -', result.data.message);
            break;
          default:
        }
      })
      .catch(err => {
        console.error('Error creating new pass - ', err);
      });
      console.log('Creating new pass for ', this.state.user_info[0]._id);
    } else {
      this.props.enqueueSnackbar('Invalid request data', {variant: 'warning' });
      console.error('Invalid request data \n', this.state.selectedAccessPass, '\n', this.state.user_info);
    }
  }

  // Activate a custom order
  activateCustomOrder(orderId) {
    console.log('Activating order %s ', this.state.orderNumberToActivate);
    this.setState({confirm_activate_open: false});
    axios.post(
      process.env.REACT_APP_API_URL + '/api/admin_woo_users_list/activate_custom_order/' + this.props.userName, 
      {arg:this.state.orderNumberToActivate},
      { withCredentials: true }
    )
    .then(result => {
      this.props.enqueueSnackbar('Activating order', {variant: 'info'});
      window.setTimeout(() => {
        this.getUser(null, '_id');
      }, 3000);
    })
    .catch(err => {
      this.props.enqueueSnackbar('There was an issue activating the order.  Please double check if it went through', {variant:'error'});
      this.getUser(null, '_id');
      console.error('Error Activating custom order - ', err);
    });
  }

  // Create a new pass handlers
  handleOpen(e) {
    e.preventDefault();
    this.setState({
      confirm_create_open: true,
    });
  };

  handleClose() {
    this.setState({
      confirm_create_open: false
    })
  };

  // Access Pass Selection
  changeAccessPassSelect(ap) {
    console.log('Choosing access pass ', ap.target.value);
    this.setState({
      selectedAccessPass: ap.target.value
    })
  }

  // Open pass editor
  toggleOpenPassEditor(pass, header, row) {
    if (pass) {
      this.setState({
        isPassEditorOpen: !this.state.isPassEditorOpen,
        passToEdit: (!this.state.isPassEditorOpen ? pass : {
          _id: '-',
          max_allowed_users: 0,
          active_duration: 0,
          pass_type: '-'
        }),
      });
    } else {
      this.setState({
        isPassEditorOpen: !this.state.isPassEditorOpen,
        passToEdit: {
          _id: '-',
          max_allowed_users: 0,
          active_duration: 0,
          pass_type: '-'
        }
      });
    }
  }

  toggleOpenSearchDialog() {
    this.setState({
      isSearchDialogOpen: !this.state.isSearchDialogOpen
    });
  }

  // Check if pass is active / available
  formatDisplay(pass, meta, row, list) {

    switch (meta) {
      case 'is_active':
        if (pass.expires_on && Date.parse(pass.expires_on) > Date.now() ) {
          return (
            <LinkIcon color="primary" />
          )
        } else {
          return (
            <LinkOffIcon color="info" />
          )
        }
        break;
      case 'is_available':
        if (!pass.expires_on) {
          return (
            <LinkIcon color="primary" />
          )
        } else {
          return (
            <LinkOffIcon color="info" />
          )
        }
        break;
      case 'ended':
      case 'project_is_archived':
        if (pass[meta] === false) {
          return (
            <LinkIcon color="primary" />
          )
        } else {
          return (
            <LinkOffIcon color="info" />
          )
        }
        break;
      case '_id':
        if (list === 'Passes') {
          return (
            <Button
              color="secondary"
              value={pass[meta]}
              name={meta + row}
              onClick={(e) => this.toggleOpenPassEditor(pass, meta, row)}
            >
              {pass[meta]}
            </Button>
          )
        } else {
          return (
            <Typography>
              {pass[meta]}
            </Typography>
          )
        }
        break;
      default:
        if (typeof pass[meta] === "string") {
          return (
            <Typography>
              {pass[meta]}
            </Typography>
          )
        }
    }
  }

  componentDidMount() {
  }

  // Poulate passes list
  passPieces(classes) {
    return this.state.passTypes.map(pass => (<MenuItem value={pass} key={pass} >- {pass} -</MenuItem>));
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container component="main" className={this.props.cssClasses.root}>
        <CssBaseline />
        <Container>

          <Grid item xs={12} className={this.props.cssClasses.sectionTitle}>
            <Typography>
              User Info
            </Typography>
          </Grid>

          {/* Customer Lookup */}
          <Grid container>

            <Grid item xs={4}>
              <form onSubmit={e => this.getUser(e, 'email')}>
                <TextField
                  variant= 'outlined'
                  required
                  id="email"
                  label="Customer Email"
                  name="email"
                  type="email"
                  width="450px"
                  value={this.state.email}
                  onChange = {(e) => this.setFormState(e)}
                />
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                >
                  Look up email
                </Button>
              </form>
            </Grid>

            <Grid item xs={4}>
              <form onSubmit={e => this.getUser(e, 'wp_username')}>
                <TextField
                  variant= 'outlined'
                  required
                  id="wp_username"
                  label="WP User Name"
                  name="wp_username"
                  type="text"
                  width="450px"
                  value={this.state.wp_username}
                  onChange = {(e) => this.setFormState(e)}
                />
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                >
                  Look up WP User Name
                </Button>
              </form>
            </Grid>

            <Grid item xs={4}>
              <form onSubmit={e => this.getUser(e, 'customer_id')}>
                <TextField
                  variant= 'outlined'
                  required
                  id="customer_id"
                  label="Customer _id"
                  name="customer_id"
                  type="customer_id"
                  width="400px"
                  value={this.state.customer_id}
                  onChange = {(e) => this.setFormState(e)}
                />
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                >
                  Look up _id
                </Button>
              </form>
            </Grid>

            <Grid item xs={4}>
              <form onSubmit={e => this.getUser(e, 'customer_uuid')}>
                <TextField
                  variant= 'outlined'
                  required
                  id="customer_uuid"
                  label="Customer UUID"
                  name="customer_uuid"
                  type="customer_uuid"
                  width="400px"
                  value={this.state.customer_uuid}
                  onChange = {(e) => this.setFormState(e)}
                />
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                >
                  Look up UUID
                </Button>
              </form>
            </Grid>

            <Grid item xs={4}>
              <form onSubmit={e => this.getUser(e, 'customer_woo_id')}>
                <TextField
                  variant= 'outlined'
                  required
                  id="customer_woo_id"
                  label="Customer Woo ID"
                  name="customer_woo_id"
                  type="customer_woo_id"
                  width="200px"
                  value={this.state.customer_woo_id}
                  onChange = {(e) => this.setFormState(e)}
                />
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                >
                  Look up Woo ID
                </Button>
              </form>
            </Grid>

            <Grid item xs={4}>
              <form
                onSubmit={e => this.searchForUsers(e)}
              >
                <TextField
                  variant= 'outlined'
                  required
                  id="customer_search"
                  label="Customer Search"
                  name="customer_search"
                  type="customer_search"
                  width="200px"
                  value={this.state.customer_search}
                  onChange = {(e) => this.setFormState(e)}
                />
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                >
                  Customer Search
                </Button>
              </form>
            </Grid>
          </Grid>

          {/* Create new pass */}
          <Grid container className={this.props.cssClasses.flex}>
            <Grid item xs={6}>
              <form onSubmit={e => this.handleOpen(e)}>
                Create a new pass
                <InputLabel id="Viewers">Viewers</InputLabel>
                <Input
                  label="Viewers"
                  id="Viewers"
                  type="number"
                  value={this.state.createPassViewers}
                  onChange={e => this.setState({createPassViewers:e.target.value})}
                  variant="outlined"
                />
                <InputLabel id="Duration">Duration</InputLabel>
                <Input
                  label="Duration"
                  id="Duration"
                  type="number"
                  value={this.state.createPassDuration}
                  onChange={e => this.setState({createPassDuration:e.target.value})}
                  variant="contained"
                />
                <Select
                  label="Duration Units"
                  value={this.state.createPassDurationUnits}
                  onChange={e => this.setState({createPassDurationUnits:e.target.value})}
                  variant="outlined"
                >
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </Select>
                <Button
                  type="submit"
                  id="submit"
                  variant="contained"
                  color="primary"
                  disabled = {this.state.user_info[0]._id === '-' ? true : false}
                >
                  Create New Pass
                </Button>

                <Dialog
                  open={this.state.confirm_create_open}
                  onClose={e => this.handleClose()}
                  aria-labelledby="confirm-activation"
                  aria-describedby="Confirm activating a pass"
                >
                  <DialogContentText className={classes.padding}>
                    <Typography className={classes.sectionTitle} color="primary">Create a new pass</Typography>
                    <Typography color="black">
                      This will add a new pass to the customers account
                    </Typography>
                  </DialogContentText>
                  <DialogActions>
                    <Button
                      id = 'cancel'
                      onClick = {e => this.handleClose()}
                      variant="contained"
                      className={classes.selectButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      id = 'goLive'
                      onClick = {e => this.createNewPass(this.state.selectedAccessPass)}
                      variant="contained"
                      color="primary"
                      className={classes.selectButton}
                    >
                      Create New Pass
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </Grid>

            {/* Activate custom order */}
            {/* <Grid item xs={6}>
              <InputLabel id="AccessPasses">Activate a custom order</InputLabel>
              <Input
                id = 'ActivateOrderNumber'
                label = 'Activate Order #'
                type = 'number'
                name = 'orderNumberToActivate'
                value = {this.state.orderNumberToActivate}
                onChange = {ap => this.setFormState(ap)}
                placeholder = 'Order #'
                variant="outlined"
                borderRadius = {4}
                required
              />
              <Button
                id="activateCustomOrder"
                variant="contained"
                color="primary"
                disabled = {this.state.user_info[0]._id === '-' ? true : false}
                onClick={() => this.setState({confirm_activate_open: true})}
              >
                Activate Custom Order
              </Button>

              <Dialog
                open={this.state.confirm_activate_open}
                onClose={e => this.setState({confirm_activate_open: !this.state.confirm_activate_open})}
                aria-labelledby="confirm-order-activation"
                aria-describedby="Confirm activating a custom order"
              >
                <DialogContentText className={classes.padding}>
                  <Typography className={classes.sectionTitle} color="primary">Activate a custom order</Typography>
                  <Typography color="black">
                    This will confirm the custom order # {this.state.orderNumberToActivate}
                  </Typography>
                </DialogContentText>
                <DialogActions>
                  <Button
                    id = 'cancel'
                    onClick = {e => this.setState({confirm_activate_open: !this.state.confirm_activate_open})}
                    variant="contained"
                    className={classes.selectButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    id = 'activate'
                    onClick = {e => this.activateCustomOrder(this.state.orderNumberToActivate)}
                    variant="contained"
                    color="primary"
                    className={classes.selectButton}
                  >
                    Activate
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid> */}
          </Grid>

          {/* User Info */}
          <Grid className={this.props.cssClasses.expansion}>
            <Grid item>
              <TableContainer component={Paper} style={{'max-height':'50vh'}}>
                <Table size="small" stickyHeader>
                  <TableHead className={this.props.cssClasses.sectionTitle}>
                    <TableRow>
                    {
                      Object.values(this.state.headers).map((head, column) => {
                        return (
                          <TableCell key={'TCH'+column} component="th" scope="row" className={this.props.cssClasses.sectionTitle}>
                            {head}
                          </TableCell>
                        )
                      })
                    }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    this.state.user_info?.map((pass, row) => {
                      let header = Object.keys(this.state.headers)
                      let components = []
                      header.map((meta, column) => {
                        components.push(
                          <TableCell key={'TCB'+row+'-'+column} scope="row" style={{whiteSpace: 'nowrap'}}>
                            {this.formatDisplay(pass, meta, row, 'Info')}
                          </TableCell>
                        );
                        return true
                      })

                      return [
                        <TableRow key={'TRB'+row}>
                          {components}
                        </TableRow>
                        ]
                    })
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* Subscription List */}
          <Grid className={this.props.cssClasses.expansion}>
            <Grid item>
              <TableContainer component={Paper} style={{'max-height':'50vh', 'min-height':'300px'}}>
                <Table size="small" stickyHeader>
                  <TableHead className={this.props.cssClasses.sectionTitle}>
                    <TableRow>
                    {
                      Object.values(this.state.subscription_headers).map((head, column) => {
                        return (
                          <TableCell key={'TCH'+column} component="th" scope="row" className={this.props.cssClasses.sectionTitle}>
                            {head}
                          </TableCell>
                        )
                      })
                    }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    this.state.subscriptions?.map((pass, row) => {
                      let header = Object.keys(this.state.subscription_headers)
                      let components = []
                      header.map((meta, column) => {
                        components.push(
                          <TableCell key={'TCB'+row+'-'+column} scope="row" style={{whiteSpace: 'nowrap'}}>
                            {this.formatDisplay(pass, meta, row, 'Passes')}
                          </TableCell>
                        );
                        return true
                      })

                      return [<TableRow key={'TRB'+row}>
                        {components}
                        </TableRow>
                        ]
                    })
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* Pass List */}
          <Grid className={this.props.cssClasses.expansion}>
            <Grid item>
              <TableContainer component={Paper} style={{'max-height':'50vh', 'min-height':'300px'}}>
                <Table size="small" stickyHeader>
                  <TableHead className={this.props.cssClasses.sectionTitle}>
                    <TableRow>
                    {
                      Object.values(this.state.pass_headers).map((head, column) => {
                        return (
                          <TableCell key={'TCH'+column} component="th" scope="row" className={this.props.cssClasses.sectionTitle}>
                            {head}
                          </TableCell>
                        )
                      })
                    }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    this.state.user_passes?.map((pass, row) => {
                      let header = Object.keys(this.state.pass_headers)
                      let components = []
                      header.map((meta, column) => {
                        components.push(
                          <TableCell key={'TCB'+row+'-'+column} scope="row" style={{whiteSpace: 'nowrap'}}>
                            {this.formatDisplay(pass, meta, row, 'Passes')}
                          </TableCell>
                        );
                        return true
                      })

                      return [<TableRow key={'TRB'+row}>
                        {components}
                        </TableRow>
                        ]
                    })
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* Sessions List */}
          <Grid className={this.props.cssClasses.expansion}>
            <Grid item>
              <TableContainer component={Paper} style={{'max-height':'50vh', 'min-height':'300px'}}>
                <Table size="small" stickyHeader>
                  <TableHead className={this.props.cssClasses.sectionTitle}>
                    <TableRow>
                    {
                      Object.values(this.state.sessions_headers).map((head, column) => {
                        return (
                          <TableCell key={'TCH'+column} component="th" scope="row" className={this.props.cssClasses.sectionTitle}>
                            {head}
                          </TableCell>
                        )
                      })
                    }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    this.state.user_sessions?.map((pass, row) => {
                      let header = Object.keys(this.state.sessions_headers)
                      let components = []
                      header.map((meta, column) => {
                        components.push(
                          <TableCell key={'TCB'+row+'-'+column} scope="row" style={{whiteSpace: 'nowrap'}}>
                            {this.formatDisplay(pass, meta, row, 'Sessions')}
                          </TableCell>
                        );
                        return true
                      })

                      return [<TableRow key={'TRB'+row}>
                        {components}
                        </TableRow>
                        ]
                    })
                  }
                  </TableBody>
                </Table>
              </TableContainer>

              <EditPassDialog
                isOpen={this.state.isPassEditorOpen}
                passToEdit={this.state.passToEdit}
                toggleOpenPassEditor = {() => this.toggleOpenPassEditor()}
                onSuccess={this.refreshUser}
                userName={this.props.userName}
                customerUUID={this.state.user_info[0].customer_uuid}
              />

              <UserSearchDialog
                isOpen={this.state.isSearchDialogOpen}
                users={this.state.userSearchResults}
                toggleOpenSearchDialog={() => this.toggleOpenSearchDialog()}
                inProgress={this.state.searchInProgress}
                getSearchedUser={this.getSearchedUser}
              />

            </Grid>
          </Grid>

        </Container>

          <Backdrop open={this.state.inProgress} style={{zIndex: '2001'}}>
            <CircularProgress color="primary" variant="indeterminate" />
          </Backdrop>

      </Grid>
    );
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(UsersList)))
