import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
List,
ListItem,
ListItemIcon,
ListItemText,
Button,
Typography,
Link
} from '@mui/material/';
import {
  AddAlarm,
  Info,
  Contacts,
  CreditCard,
  AccessTime,
  SettingsApplications,
  AssignmentInd,
} from '@mui/icons-material/';

// Hooks
import useHasAvailablePass from '../Hooks/useHasAvailablePass';
import useHasActivePass from '../Hooks/use_has_active_pass';
import useHasScheduledPass from '../Hooks/use_has_scheduled_pass';
import useIsActivePassHourly from '../Hooks/useIsActivePassHourly';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    width: '250px',
    margin: 'auto'
  }
}));

const icons = [
  <Info />,
  <AccessTime />,
  <SettingsApplications />,
  <Contacts />,
  <AddAlarm />,
  <AssignmentInd />,
  <CreditCard />
]

export default function SelectOption(props) {
  const classes = useStyles();
  const hasAvailablePass = useHasAvailablePass(props.availablePasses);
  const hasActivePass = useHasActivePass(props.activePasses);
  const hasScheduledPass = useHasScheduledPass(props.scheduledPasses);
  const isActivePassHourly = useIsActivePassHourly(props.activePasses);
  const [steps, setSteps] = useState(['Purchase Pass'])

  // Decide which steps to display
  useEffect(() => {
    let stepsToDisplay = [];

    if (props.modalAction === 'activate') {
      stepsToDisplay.push('Purchase Pass');
      stepsToDisplay.push('Purchase Subscription');
    }
    if (props.availableSubs.length > 0 && props.modalAction === 'activate') {
        stepsToDisplay.push('Attach Subscription')
    }
    if (hasAvailablePass && props.modalAction === 'activate') {
      stepsToDisplay.push('Activate Pass');
    }
    if (hasAvailablePass && props.modalAction === 'schedule') {
      stepsToDisplay.push('Schedule Pass');
    }
    if (hasScheduledPass && props.modalAction === 'schedule') {
      stepsToDisplay.push('Manage Scheduled');
    }

    if (hasActivePass) {
      if (props.modalAction === 'activate') {
        stepsToDisplay.push('Add Viewers');
        stepsToDisplay.push('Add Time');
        stepsToDisplay.push('Add Viewers & Time');
      }
    }

    setSteps(stepsToDisplay)
  }, [hasAvailablePass, hasActivePass, hasScheduledPass, isActivePassHourly]);

  let renderIcon = (step) => {
    switch (step) {
      case 'Activate Pass':
        return <Info />
        break;
      case 'Schedule Pass':
        return <AccessTime />
        break;
      case 'Manage Scheduled':
        return <SettingsApplications />
        break;
      case 'Add Viewers':
        return <Contacts />
        break;
      case 'Add Time':
        return <AddAlarm />
        break;
      case 'Add Viewers & Time':
        return <AssignmentInd />
        break;
      case 'Purchase Pass':
        return <CreditCard />
        break;
      default:
        return <AddAlarm />
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <List>
          {
            steps.length > 0 &&
            steps.map((step, index) => (
              <ListItem
                dense
                button
                onClick={() => props.changeStep(step, {}, {}, false)}
              >
                <ListItemIcon>
                  {renderIcon(step)}
                </ListItemIcon>
                <ListItemText>
                  {step}
                </ListItemText>
              </ListItem>
            ))
          }
          {
            steps.length === 0 &&
            <Typography>
              No passes available to schedule.  Would you like to
              <Link
                onClick={() => props.changeStep('Purchase Pass', {}, {}, false)}
                underline="hover">
                {' purchase '}
              </Link>
              one?
            </Typography>
          }
        </List>
      </Grid>
    </Grid>
  );

}
