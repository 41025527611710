import React, { useEffect, useState } from 'react';

// requires an array of activePasses as props
// Return true if the first pass is hourly otherwise false

export default function useIsActivePassHourly(props) {
  const [isPassHourly, setIsPassHourly] = useState(false);

  useEffect(() => {
    if (
      props &&
      props.length > 0 &&
      props[0]._id &&
      props[0].duration_units &&
      (props[0].duration_units.toLowerCase() === 'hours' ||
      props[0].duration_units.toLowerCase() === 'minutes')
    ) {
      setIsPassHourly(true);
    }
  }, [props]);

  return isPassHourly;
}
