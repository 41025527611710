import Debug from "debug";
const debug = Debug("SS:VideoChat:usePublish");
import { useEffect, useState } from "react";
import {
  BroadcastEvent,
  Director,
  Publish
} from "@millicast/sdk";
import { useVideoChat } from "../../VideoChatProvider/VideoChatProvider";

export default function usePublish() {
  const {
    streamName,
    pubToken,
    setPubConnectionStatus,
    setPubReconnecting
  } = useVideoChat()

  const [publish, setPublish] = useState<Publish>()

  useEffect(() => {
    const publishTokenGenerator = () => Director.getPublisher({
      streamName: streamName,
      token: pubToken,
    })

    if (streamName && pubToken) {
      const newPublish = new Publish(streamName, publishTokenGenerator)
      debug("Created new Publish", newPublish)
      setPublish(newPublish)
    }
  }, [streamName, pubToken])

  useEffect(() => {
    const handleBroadcastEvent = (event: BroadcastEvent) => {
      debug("BroadCast Event ", event)
      switch (event.type) {
        case "active":

          break;
        case "inactive":
          break;
        case "viewercount":
          break;
      }
    }

    const handleConnectionStateChangeEvent = (event: RTCPeerConnectionState) => {
      debug("Connection State Event ", event)
      setPubConnectionStatus(event)
      if (event === "connected") {
        setPubReconnecting(false)
      }
    }

    const handleReconnect = (event: { timeout: number, error: Error }) => {
      debug("Reconnect", event)
      if (event.timeout > 0) {
        setPubReconnecting(true)
      }
    }

    // const handleStats = async (event: RTCStatsReport) => {
    //   debug("Stats", event)
    // }

    publish?.on("broadcastEvent", handleBroadcastEvent)
    publish?.on("connectionStateChange", handleConnectionStateChangeEvent)
    publish?.on("reconnect", handleReconnect)
    // publish?.webRTCPeer?.on("stats", handleStats)
    return () => {
      publish?.off("broadcastEvent", handleBroadcastEvent)
      publish?.off("connectionStateChange", handleConnectionStateChangeEvent)
      publish?.off("reconnect", handleReconnect)
      // publish?.webRTCPeer?.off("stats", handleStats)
      publish?.stop()
    }

  }, [publish])

  return publish
}