import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
FormControl,
Input,
Button,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import useDashboardAccount from '../Context/useDashboardAccount';
import useCustomBranding from '../../Branding/Context/useCustomBranding';
import BrandColorOption from '../../Branding/BrandColorOption';
import BrandLogo from '../../Branding/BrandLogo';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  previewLogo: {
    width: '50px',
    height: '50px',
    maxWidth: '50px',
    maxHeight: '50px',
    marginRight: theme.spacing(12)
  },
  previewBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(12),
    padding: theme.spacing(12)
  }
}));

export default function DashboardBrandingTab(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { 
    customer_uuid
  } = useDashboardAccount();
  const {
    brandName,
    setBrandName,
    storedBrandName,
    brandLogoUrl,
    brandPrimaryColor,
    storedBrandPrimaryColor,
    brandSecondaryColor,
    storedBrandSecondaryColor,
    fetchBranding
  } = useCustomBranding()

  // State
  const [inProgress, setInProgress] = useState(false);
  const [needsUpdating, setNeedsUpdating] = useState(false)
  const [newBrandName, setNewBrandName] = useState(brandName)
  const [newLogo, setNewLogo] = useState()
  const [newLogoFile, setNewLogoFile] = useState<File | undefined>()

  const logoRef = useRef()

  // Effects

  // Display stored brand name
  useEffect(() => {
    if (brandName !== newBrandName) {
      setNewBrandName(brandName)
    }
  }, [brandName]);

  useEffect(() => {
    if (brandLogoUrl && logoRef.current) {
      logoRef.current.src = brandLogoUrl
    }
  }, [brandLogoUrl]);

  // Determine if we need to update branding
  useEffect(() => {
    let toUpdate = false

    if (newBrandName && newBrandName !== brandName) { toUpdate = true }
    if (newLogoFile) { toUpdate = true }
    if (brandPrimaryColor !== storedBrandPrimaryColor) { toUpdate = true }
    if (brandSecondaryColor !== storedBrandSecondaryColor) { toUpdate = true }

    if (toUpdate) { setNeedsUpdating(true) }
    else { setNeedsUpdating(false) }
  }, [newBrandName, newLogoFile, brandPrimaryColor, storedBrandPrimaryColor, brandSecondaryColor, storedBrandSecondaryColor])

  // Actions
  const updatenewBrandName = (event) => {
    let newName = event.target?.value
    if (!newName) { return }
    if (newName === brandName)

    newName = newName.trim()
    if (newName.length > 24) {
      newName = newName.substring(0,24)
      enqueueSnackbar("Brand Name cannot exceed 24 characters", { variant: "warning" })
    }

    setNewBrandName(newName)
  }

  const updatePreview = async (event) => {
    let logoFile = event.target?.files[0]
    if (!logoFile) { return; }
    if (!logoRef.current) { return }

    if (logoFile.size > 102400) {
      enqueueSnackbar("File is too large.  Must be under 100Kb")
    }
    
    setNewLogoFile(logoFile)

    try {
      let reader = new FileReader()
      reader.onload = (e) => {
        if (!e.target?.result) { return }
        setNewLogo(e.target.result)
      }
      reader.readAsDataURL(logoFile)
    } catch (error) {
      console.error("Error loading logo", error)
      enqueueSnackbar("Error getting logo. Please try again.", { variant: "warning"})
    }
  }

  const updateBranding = async () => {
    setInProgress(true)

    try {
      
      let data = {}
      if (newBrandName !== "" && newBrandName !== storedBrandName) {
        data.brandName = newBrandName
      }
      if (newLogoFile) {
        data.updateLogo = true
      }
      if (brandPrimaryColor && brandPrimaryColor !== storedBrandPrimaryColor) {
        data.brandPrimaryColor = brandPrimaryColor
      }
      if (brandSecondaryColor && brandSecondaryColor !== storedBrandSecondaryColor) {
        data.brandSecondaryColor = brandSecondaryColor
      }

      let fetchUrl = process.env.REACT_APP_API_URL + '/api/producer_update_branding/' + customer_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(data)
      }
      let res = await fetch(fetchUrl, fetchData);
      let response = await res.json();
      if (res.ok) {
        // Update branding in provider
        if (brandName) { setBrandName(newBrandName) }
        
        // Upload logo to S3
        if (response.presignedUrl && response.logoSuffix) {
          await uploadLogo(response.presignedUrl, response.logoSuffix)
        }

        fetchBranding(customer_uuid)

        enqueueSnackbar("Branding Updated", { variant: "info" })

      } else {
        console.error('Could not retrieve data ', res.status, response);
      }  
    } catch (error) {
      console.error('Network error getting data ', error);
    } finally {
      setInProgress(false)
    }
  }

  const uploadLogo = async (presignedUrl, logoSuffix) => {
    if (!presignedUrl || !logoSuffix) { return }
    setInProgress(true)

    try {
      if (!newLogoFile) { throw new Error("Could not process logo.  Please try again") }

      let logoFile = new File([newLogoFile], customer_uuid + '-' + logoSuffix + ".png", { type: "image/png" })
      if (!logoFile) { throw new Error("Error formatting logo for storage") }
      
      let fetchData = {
        method: 'PUT',
        body: logoFile
      }
      let res = await fetch(presignedUrl, fetchData);
      if (res.ok) {
        await storeUpdatedLogo(logoSuffix)
      } else {
        enqueueSnackbar("Logo update failed.  Please try again", { variant: "warning" })
        console.error('Could not retrieve data ', res.status);
      }  
    } catch (error) {
      enqueueSnackbar("Network error updating logo.  Please try again", { variant: "warning" })
      console.error('Network error getting data ', error);
    } finally {
      setInProgress(false)
    }
  }

  const storeUpdatedLogo = async (logoSuffix) => {
    try {
      if (!logoSuffix) { throw new Error("Could not store logo.  Please try again") }

      let fetchUrl = process.env.REACT_APP_API_URL + '/api/producer_update_branding/' + customer_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({ logoSuffix: logoSuffix })
      }
      let res = await fetch(fetchUrl, fetchData);
      let response = await res.json();
      if (res.ok) {
        setNewLogoFile(undefined)
        enqueueSnackbar("Logo updated", { variant: "info" })
      } else {
        console.error('Could not store logo ', res.status, response);
        enqueueSnackbar("Error storing Logo, please try again", { variant: "info" })
      }  
    } catch (error) {
      enqueueSnackbar("Network error updating logo.  Please try again", { variant: "warning" })
      console.error('Network error getting data ', error);
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>
        
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Logo
            </Grid>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Brand Name
            </Grid>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              Accent Colors
            </Grid>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              
            </Grid>
          </Grid>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Grid container align="center">
                <Grid item xs={6} className={classes.previewBox}>
                <img 
                  src={newLogo} 
                  alt="Your Logo" 
                  id="logoPreview" 
                  className={classes.previewLogo}
                  ref={logoRef}
                />
                </Grid>

                <Grid item xs={6}>
                  <Grid container direction='column'>
                    <Grid item align="left">
                      Dimensions: 100px X 100px
                    </Grid>
                    <Grid item align="left">
                      File Type: .png
                    </Grid>
                    <Grid item align="left">
                      File Size: Max 100Kb
                    </Grid>
                    <Grid item align="left">
                      <Button
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        <input
                          type="file"
                          accept="image/png"
                          onChange={updatePreview}
                          hidden
                          id="filePicker"
                        />
                        Select Logo
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Input
                value={newBrandName}
                onChange={updatenewBrandName}
              />
            </Grid>
            
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Grid container direction='column'>
                <Grid item align="center">
                  <Typography>Click to change</Typography>
                </Grid>

                <Grid item align="center">
                  <BrandColorOption setNeedsUpdating={setNeedsUpdating} />
                </Grid>

                <Grid item align="center">
                  <BrandColorOption secondary setNeedsUpdating={setNeedsUpdating} />
                </Grid>
              </Grid>

            </Grid>
            
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Button
                onClick={updateBranding}
                disabled={!needsUpdating}
                color="primary"
              >
                Update Branding
              </Button>
            </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
            
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}></Grid>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}></Grid>
            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}></Grid>
          </Grid>

      </Grid>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
