import { useRef, useState, useMemo } from "react";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import { useMillicast } from "../../Context/MillicastProvider/MillicastProvider";
import useCamera from "../../Context/CameraProvider/useCamera";
import Debug from "debug"; const debug = Debug("SS:VideoChat:MyControls:Connection");

import {
  Button,
  IconButton,
  Tooltip,
  Popover,
  Typography,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import CallEndIcon from "@mui/icons-material/CallEnd";
import CallIcon from "@mui/icons-material/Call";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import { useSnackbar } from "notistack";

export default function Connection({
  variation,
}: {
  variation: "icon" | "text";
}) {
  const [showConnection, setShowConnection] = useState<boolean>(false);
  const connectionRef = useRef(null);

  const {
    displayName,
    subConnectionStatus,
    subReconnecting
  } = useVideoChat();

  const {
    gettingAudioMedia,
    gettingVideoMedia
  } = useCamera()

  const {
    join,
    leave,
    reconnect,
    connecting
  } = useMillicast();

  const { enqueueSnackbar } = useSnackbar()

  // Memos
  const buttonColor = useMemo(() => {
    if (subReconnecting) { return "error" }
    switch (subConnectionStatus) {
      case "new":
      case "closed":
        return "primary";
      case "connected":
        return "error";
      case "connecting":
        return "info";
      default:
        return "inherit";
    }
  }, [subConnectionStatus, subReconnecting]);

  const buttonIcon = useMemo(() => {
    if (subReconnecting) { return (
      <CircularProgress 
        color="warning" 
        variant="indeterminate"
        size={24}
      />
    ) }
    switch (subConnectionStatus) {
      case "connected":
        return <CallIcon color="primary" />;
      case "connecting":
        return <SettingsPhoneIcon color="info" />;

      case "failed":
        return <PhoneDisabledIcon color="warning" />;
      case "closed":
      case "new":
      case "disconnected":
      default:
        return <CallEndIcon color="error" />;
    }
  }, [subConnectionStatus, subReconnecting]);

  const buttonText = useMemo(() => {
    if (subReconnecting) { return "Reconnecting ......"}
    switch (subConnectionStatus) {
      case "connected":
        return "Leave";
      case "connecting":
        return "Connecting .......";

      case "failed":
        return "Reconnect";
      case "closed":
      case "new":
      case "disconnected":
      default:
        return "Join";
    }
  }, [subConnectionStatus, subReconnecting]);

  const buttonAction = useMemo(() => {
    if (subReconnecting) { return () => {} }
    if (!displayName) { () => {
      enqueueSnackbar("Please identify yourself", { variant: "info" })
    }}
    switch (subConnectionStatus) {
      case "connected":
        return leave;
      case "connecting":
        return () => { };
      case "failed":
        return reconnect;
      case "closed":
      case "new":
      default:
        return () => {
          join().catch(error => {
            debug("Error joining room", error)
          })
        };
    }
  }, [
    subConnectionStatus, 
    subReconnecting, 
    leave, 
    reconnect, 
    join, 
    displayName
  ]);

  const buttonDisable = useMemo(() => {
    if (
      gettingAudioMedia ||
      gettingVideoMedia ||
      connecting ||
      subReconnecting
    ) {
      return true;
    }
    return false;
  }, [subConnectionStatus, subReconnecting, gettingAudioMedia, gettingVideoMedia]);

  const buttonElement = useMemo(() => {
    switch (variation) {
      case "text":
        return (
          <Button
            color={buttonColor}
            variant="contained"
            onClick={buttonAction}
            fullWidth
            disabled={buttonDisable}
          >
            {buttonText}
          </Button>
        );

      case "icon":
      default:
        return (
          <Tooltip
            title={
              <Typography>
                {buttonText}
              </Typography>
            }
            placement="right-start"
          >
            <IconButton
              onClick={buttonAction}
              ref={connectionRef}
              color={buttonColor}
            >
              {buttonIcon}
            </IconButton>
          </Tooltip>
        );
    }
  }, [
    variation,
    buttonAction,
    buttonColor,
    buttonIcon,
    buttonText,
    buttonDisable,
  ]);

  return (
    <>
      {buttonElement}

      <Popover
        open={showConnection}
        onClose={() => setShowConnection(false)}
        anchorEl={connectionRef.current}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          maxWidth: "800px",
          maxHeight: "80vh",
          borderRadius: 8,
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: "center",
            maxWidth: "90%",
            margin: "auto",
            padding: 4,
          }}
        >
          <Grid xs={12}>
            <Typography>
              Are you sure you want to leave the conversation?
            </Typography>
            <Typography variant="caption">
              You will still be able to watch the streams.
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Button color={buttonColor} variant="contained">
              Leave
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
