import { useEffect, useMemo, useState } from "react";
import useCamera from "../../Context/CameraProvider/useCamera";
import Debug from "debug";
const debug = Debug("SS:VideoChat:Me");
import JoinScreen from "../JoinScreen/JoinScreen";
import Peer from "../Peer/Peer";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ToggleVideo from "../Video/ToggleVideo/ToggleVideo";
import ToggleAudio from "../Audio/ToggleAudio/ToggleAudio";
import Connection from "../Connection/Connection";
import ShowSettings from "../ShowSettings/ShowSettings";
import { useMediaQuery } from "@mui/material";

export default function Me() {
  const [inRoom, setInRoom] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream>(
    new MediaStream()
  );

  const {
    userUuid,
    chatCollapsed,
    pubConnectionStatus,
    pubReconnecting,
    subConnectionStatus,
    subReconnecting,
  } = useVideoChat();

  const { audioTrack, videoTrack } = useCamera();

  const isLandscape = useMediaQuery("(orientation:landscape")

  useEffect(() => {
    if (!mediaStream) {
      // I don't think we will ever hit this, but it's here in case
      const tracks = [];
      if (audioTrack) {
        tracks.push(audioTrack);
      }
      if (videoTrack) {
        tracks.push(videoTrack);
      }
      const newStream = new MediaStream(tracks);
      setMediaStream(newStream);
      debug("Created new media stream", newStream);
    } else {
      const audioTracks = mediaStream.getAudioTracks();
      const videoTracks = mediaStream.getVideoTracks();

      if (audioTrack && audioTracks.length > 0) {
        // Only replace the track if it's changed
        if (audioTrack !== audioTracks[0]) {
          debug("Updating my audio track", audioTrack, audioTracks);
          audioTracks.forEach((track) => mediaStream.removeTrack(track));
          mediaStream.addTrack(audioTrack);
        }
      } else if (audioTrack) {
        mediaStream.addTrack(audioTrack);
      }

      if (videoTrack && videoTracks.length > 0) {
        // Only replace the track if it's changed
        if (videoTrack !== videoTracks[0]) {
          debug("Updating my video track", videoTrack, videoTracks);
          videoTracks.forEach((track) => mediaStream.removeTrack(track));
          mediaStream.addTrack(videoTrack);
        }
      } else if (videoTrack) {
        mediaStream.addTrack(videoTrack);
      }
    }
    return () => {
      if (mediaStream) {
        // Remove the tracks from our stream but don't stop them
        // since other componenets may be using them
        mediaStream.getTracks().forEach((track) => {
          mediaStream.removeTrack(track);
        });
      }
    };
  }, [audioTrack, videoTrack, mediaStream]);

  useEffect(() => {
    debug("In Room? ", pubConnectionStatus, subConnectionStatus);
    if (
      pubConnectionStatus === "connected" ||
      subConnectionStatus === "connected" ||
      pubReconnecting ||
      subReconnecting
    ) {
      setInRoom(true);
    } else {
      setInRoom(false);
    }
  }, [
    pubConnectionStatus,
    subConnectionStatus,
    pubReconnecting,
    subReconnecting,
  ]);

  const renderer = useMemo(() => {
    if (inRoom) {
      const peer = (
        <Peer
          peer={{
            sourceId: userUuid,
            audioTrackMids: [],
            videoTrackMids: [],
            mediaStream: mediaStream,
          }}
        />
      );

      if (chatCollapsed) {
        return (
          <Grid
            container
            sx={{
              height: "100%",
              width: "100%",
              flexDirection: {
                xs: isLandscape ? "column" : "row",
                md: "row"
              },
              flexWrap: "nowrap",
            }}
          >
            <Grid
              sx={{
                height: {
                  xs: isLandscape ? "calc(100% - 36px)" : "100%",
                  md: "100%"
                },
                width: "100%",
                flex: 1
              }}
            >
              {peer}
            </Grid>

            <Grid>
              <Grid
                container
                justifyContent="center"
                justifyItems="center"
                spacing={1}
                sx={{
                  height: {
                    xs: isLandscape ? "36px" : "100%",
                    md: "100%"
                  },
                  width: "100%",
                  flexDirection: {
                    xs: isLandscape ? "row" : "column",
                    md: "column"
                  },
                }}
              >
                <Grid>
                  <ToggleVideo variant="icon" />
                </Grid>

                <Grid>
                  <ToggleAudio variant="icon" />
                </Grid>

                <Grid>
                  <ShowSettings variant="icon" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        return <Grid sx={{ height: "100%" }}>{peer}</Grid>;
      }
    } else {
      return <JoinScreen />;
    }
  }, [inRoom, chatCollapsed, audioTrack, videoTrack, userUuid, isLandscape]);

  return (
    <Grid
      sx={{
        height: {
          xs: isLandscape ? "calc(50% - 18px)" : "100%",
          md: "100%"
        },
        width: {
          xs: isLandscape ? "100%" : "172px",
          md: "172px"
        },
        paddingRight: {
          xs: isLandscape ? "0px" : "4px",
          md: "4px"
        },
      }}
    >
      {renderer}
    </Grid>
  );
}
