import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { VideoCodec, videoCodecs } from "./Settings/video_codecs";
import { VideoBitrates, videoBitrates } from "./Settings/video_bitrates";
import { videoQualities, VideoQuality } from "./Settings/video_qualities";
import {
  VideoResolutions,
  videoResolutions,
} from "./Settings/video_resolutions";
import {
  AudioChannelTypes, audioChannelTypes
} from "./Settings/audio_channels";
import useScreenDevices, { ScreenResizeMode } from "./Hooks/useScreenDevices";
import useUserMedia from "./Hooks/useUserMedia";
import useVideoCodecs from "./Hooks/useVideoCodecs";
import { DevicePermission } from "./Hooks/useMediaDevices";
import { MillicastCapabilities } from "@millicast/sdk";
import { VideoResizeMode } from "./Hooks/useVideoInputDevices";

interface Props {
  label?: string;
  color?: string;
  children: ReactNode;
}


export const CameraContext = React.createContext<Context>(null!);

export function CameraProvider(props: Props) {
  const {
    availableVideoCodecs,
    scalabilityLayers
  } = useVideoCodecs()

  // Screen Sharing
  const {
    screenConstraints,
    setScreenConstraints,
    showCursor,
    setShowCursor,
    screenResizeMode,
    setScreenResizeMode,
  } = useScreenDevices();


  const {
    // mediaStream,
    audioTrack,
    setAudioTrack,
    videoTrack,
    setVideoTrack,
    gettingAudioMedia,
    gettingVideoMedia,
    getUserMedia,
    stopAllMedia,
    stopAudioMedia,
    stopVideoMedia,

    // Devices
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission,
    getAvailableDevices,
    gettingDevices,
    
    // Video
    videoDeviceId,
    updateVideoDeviceId,
    videoFacingMode,
    updateVideoFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth,
    updateVideoWidth,
    videoQuality,
    updateVideoQuality,
    supportedVideoCapabilities,

    // Audio
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    audioProcessing,
    updateAudioProcessing,
    autoGainControl,
    updateAutoGainControl
  } = useUserMedia();

  return (
    <CameraContext.Provider
      value={{
        // Meta
        label: props.label,
        color: props.color,

        // Devices
        getAvailableDevices,
        gettingDevices,

        // Video
        videoInputDevices,
        videoDeviceId,
        updateVideoDeviceId,
        videoInputPermission,
        videoFacingMode,
        updateVideoFacingMode,
        videoResizeMode,
        updateVideoResizeMode,
        videoCodecs,
        availableVideoCodecs,
        videoCodec,
        updateVideoCodec,
        videoBitrates,
        videoBitrate,
        updateVideoBitrate,
        videoResolutions,
        videoQualities,
        videoQuality,
        updateVideoQuality,
        videoWidth,
        updateVideoWidth,
        supportedVideoCapabilities,
        scalabilityLayers,

        // Audio
        audioInputDevices,
        audioDeviceId,
        updateAudioDeviceId,
        audioInputPermission,
        audioChannelTypes,
        audioChannels,
        updateAudioChannels,
        echoCancellation,
        updateEchoCancellation,
        audioProcessing,
        updateAudioProcessing,
        autoGainControl,
        updateAutoGainControl,
        audioOutputDevices,
        audioOutputPermission,

        // Screen
        screenConstraints,
        setScreenConstraints,
        showCursor,
        setShowCursor,
        screenResizeMode,
        setScreenResizeMode,

        // Media Stream
        audioTrack,
        setAudioTrack,
        videoTrack,
        setVideoTrack,
        gettingAudioMedia,
        gettingVideoMedia,
        getUserMedia,
        stopAllMedia,
        stopVideoMedia,
        stopAudioMedia,
      }}
    >
      {props.children}
    </CameraContext.Provider>
  );
}

interface Context {
  label: string,
  color: string,

  // Devices
  getAvailableDevices: () => void,
  gettingDevices: boolean,

  // Video Devices
  videoInputDevices: MediaDeviceInfo[],
  videoDeviceId: string,
  updateVideoDeviceId: (id: string) => void,
  videoInputPermission: DevicePermission,
  videoFacingMode: VideoFacingModeEnum,
  updateVideoFacingMode: (facingMode: VideoFacingModeEnum) => void,
  videoResizeMode: VideoResizeMode,
  updateVideoResizeMode: (resizeMode: VideoResizeMode) => void,
  videoCodecs: VideoCodec[],
  availableVideoCodecs: string[],
  videoCodec: string,
  updateVideoCodec: (codec: string) => void,
  videoBitrates: VideoBitrates[],
  videoBitrate: number,
  updateVideoBitrate: (bitrate: number) => void,
  videoResolutions: VideoResolutions[],
  videoQualities: VideoQuality[],
  videoQuality: number,
  updateVideoQuality: (qualityId: number) => void,
  videoWidth: number,
  updateVideoWidth: (width: number) => void,
  supportedVideoCapabilities: MillicastCapabilities,
  scalabilityLayers: string[] | null,

  // Audio Devices
  audioInputDevices: MediaDeviceInfo[],
  audioDeviceId: string,
  updateAudioDeviceId: (id: string) => void,
  audioInputPermission: DevicePermission,
  audioChannelTypes: AudioChannelTypes[],
  audioChannels: number,
  updateAudioChannels: (channels: number) => void,
  echoCancellation: boolean,
  updateEchoCancellation: (checked: boolean) => void,
  audioProcessing: "noiseSuppression" | "voiceIsolation" | "none",
  updateAudioProcessing: (processing: string) => void,
  autoGainControl: boolean,
  updateAutoGainControl: (checked: boolean) => void,
  audioOutputDevices: MediaDeviceInfo[],
  audioOutputPermission: DevicePermission,

  // Screen Devices
  screenConstraints?: MediaTrackConstraints,
  setScreenConstraints: (constraints: MediaTrackConstraints) => void,
  showCursor: string,
  setShowCursor: (show: string) => void,
  screenResizeMode: ScreenResizeMode,
  setScreenResizeMode: (resizeMode: ScreenResizeMode) => void,

  // Media
  audioTrack: MediaStreamTrack | undefined,
  setAudioTrack: Dispatch<SetStateAction<MediaStreamTrack | undefined>>;
  videoTrack: MediaStreamTrack | undefined,
  setVideoTrack: Dispatch<SetStateAction<MediaStreamTrack | undefined>>;
  gettingVideoMedia: boolean,
  gettingAudioMedia: boolean,
  getUserMedia: () => Promise<void>,
  stopAllMedia: () => void,
  stopVideoMedia: () => void,
  stopAudioMedia: () => void
}

