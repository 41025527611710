export default function setMultiOpus(sdp) {
  // Get the audio line from the sdp
  let audioLine = /m=audio 9 UDP\/TLS\/RTP\/SAVPF (.*)\r\n/.exec(sdp);
  
  // Create new fmt number
  let fmtNum = Math.max(...audioLine[1].split(' ').map(Number)) + 1
  
  let audioHeader = audioLine[0]

  let newAudioHeader = audioHeader.replace(
    /\r\n/,
    ' ' + fmtNum + '\r\n' +
    'a=rtpmap:' + fmtNum + ' multiopus/48000/6\r\n' +
    'a=fmtp:' + fmtNum + ' minptime=10; useinbandfec=1; channel_mapping=0,4,1,2,3,5; num_streams=4; coupled_streams=2\r\n'
  )
  
  let newSdp = sdp.replace(
    audioHeader,
    newAudioHeader
  )

  return newSdp;
}
