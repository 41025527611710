import React, { FormEvent, SetStateAction, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import BrandLogo from "../Branding/BrandLogo";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
  })
);

interface TvOSLoginProps { 
  tvUuid: String; 
  setAccessApproved: React.Dispatch<SetStateAction<boolean>>;
  cssClasses: any; 
}

export default function TvOSLogin(props: TvOSLoginProps ) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [inProgress, setInProgress] = useState(false);
  const [nickName, setNickName] = useState<string>();
  const [room, setRoom] = useState<string>();
  const [uuid, setUuid] = useState<string>();
  const [link, setLink] = useState<string>();
  const [linkError, setLinkError] = useState<string>();
  const [hash, setHash] = useState<string>();
  const [needsCode, setNeedsCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [needsPass, setNeedsPass] = useState<boolean>(false);

  // Effects

  // Actions
  const validateLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newLink = e.target?.value;
    if (!newLink) {
      setLink("");
      return;
    }

    setLink(newLink);

    let linkUrl;
    try {
      linkUrl = new URL(newLink);
    } catch (error) {
      setLinkError("Parsing Error.  Please check the link and try again.");
      return;
    }

    let linkParts = linkUrl.pathname.split("/");
    console.log("Validate Link", newLink, linkParts?.length, linkParts);

    if (linkParts?.length < 3) {
      setLinkError("Invalid Link");
    } else {
      setRoom(linkParts[2]);
      if (linkError) {
        setLinkError(undefined);
      }
    }

    switch (linkParts?.length) {
      case 5: // Hash
        if (!linkParts[4]) {
          return;
        }
        setHash(linkParts[4]);
        setNeedsCode(false);
        setNeedsPass(false);
        break;
      case 4: // Email Invites
        if (!linkParts[3]) {
          return;
        }
        setUuid(linkParts[3]);
        setNeedsPass(true);
        setNeedsCode(false);
        break;
      case 3: // Link & Code
        if (!linkParts[2]) {
          return;
        }
        setNeedsCode(true);
        setNeedsPass(false);
        break;
    }
  };

  const loginTvOS = async (e: FormEvent) => {
    e.preventDefault();
    if (inProgress) {
      return;
    }

    if (!nickName) {
      return;
    }
    if (!room) {
      return;
    }
    if (!props.tvUuid) {
      return;
    }

    setInProgress(true);

    type ViewerType = "email" | "web"
    interface BodyData {
      nickName: String,
      room: String,
      code_uuid: String,
      user_uuid?: String,
      pass?: String,
      viewerType?: ViewerType,
      hash?: String,
      code?: String
    }

    try {
      let bodyData: BodyData = {
        nickName: nickName,
        room: room,
        code_uuid: props.tvUuid,
      };

      if (needsPass && pass && uuid) {
        // Email Invite
        bodyData.user_uuid = uuid;
        bodyData.pass = pass;
        bodyData.viewerType = "email";
      } else if (hash) {
        // WR w/ Hash
        bodyData.hash = hash;
        bodyData.viewerType = "web";
      } else if (needsCode && code) {
        // WR w/ Code
        bodyData.code = code;
        bodyData.viewerType = "web";
      } else {
        throw new Error("Data Error.  Please try again");
      }

      let fetchUrl = process.env.REACT_APP_HOST_URL + "/api/tvos/login";
      let fetchData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      };
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
        if (response.type === "success") {
          props.setAccessApproved(true);
        }
      } else {
        enqueueSnackbar("Error granting access.  Please try again", {
          variant: "warning",
        });
        console.error("Could not retrieve data ", res.status);
      }
    } catch (error) {
      enqueueSnackbar("Network Error.  Please try again", {
        variant: "warning",
      });
      console.error("Network error getting data ", error);
    } finally {
      setInProgress(false);
    }
  };

  // Render
  return (
    <Paper className={props.cssClasses.signInPaper}>
      <Grid
        container
        alignItems="center"
        className={props.cssClasses.signInBox}
        style={{ margin: "auto" }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item alignItems="center" style={{ margin: "auto" }}>
              <BrandLogo size="medium" showName={false} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={props.cssClasses.signInTitle} variant="h5">
            Request Access
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={loginTvOS}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="nickName"
              label="Your Name"
              type="text"
              id="nickName"
              value={nickName}
              onChange={(e) => setNickName(e.target?.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="link"
              label="Link"
              id="link"
              value={link}
              onChange={validateLink}
            />

            {
              // Show link errors
              linkError && <Typography color="error">{linkError}</Typography>
            }

            {
              // Request code if needed
              needsCode && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="code"
                  label="Access Code"
                  type="password"
                  id="code"
                  value={code}
                  onChange={(e) => setCode(e.target?.value)}
                />
              )
            }

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={inProgress}
            >
              Request Access
            </Button>

            <Grid container className={props.cssClasses.tableFooter}>
              <Grid
                item
                xs={12}
                className={props.cssClasses.tableCell}
                style={{ textAlign: "right" }}
              >
                By signing in you agree to our
                <br />
                <Link
                  href="https://setstream.io/terms-and-conditions/"
                  target="_blank"
                >
                  {" Terms and Conditions"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Backdrop open={inProgress} style={{ zIndex: 2001 }}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Paper>
  );
}
