import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
} from '@mui/material/';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripeProvider(props) {

  const appearance = {
    theme: 'night',
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: props.clientSecret,
        appearance: appearance
      }}
    >
      {props.children}
    </Elements>
  );

}
