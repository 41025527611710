import { useState } from 'react';

export enum ScreenResizeMode {
  CropAndScale = "crop-and-scale",
  Stretch = "stretch",
}

export default function useScreenDevices() {
  const [screenConstraints, setScreenConstraints] = useState<MediaTrackConstraints>();
  const [showCursor, setShowCursor] = useState<string>("always");
  const [screenResizeMode, setScreenResizeMode] = useState<ScreenResizeMode>(ScreenResizeMode.CropAndScale);

  return {
    screenConstraints,
    setScreenConstraints,
    showCursor,
    setShowCursor,
    screenResizeMode,
    setScreenResizeMode
  };
}
