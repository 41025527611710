import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Typography,
Tooltip,
IconButton,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => createStyles({
  linkContainer: {
    borderBottom: '1px solid ' + theme.palette.secondary.main,
    borderRadius: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  }
}));

export default function WaitingRoomLinks(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()

  // State
  const [canShare, setCanShare] = useState<Boolean>(navigator?.share ? true : false)
  const [showCodeLink, setShowCodeLink] = useState<Boolean>(false);
  const [showEmbeddedLink, setShowEmbeddedLink] = useState<Boolean>(false);

  // Effects

  // Actions
  const copyText = async (title, text) => {
    copy(text)
    enqueueSnackbar(title + " copied", { variant: 'info' })
  }

  const shareCodeLink = async () => {
    if (navigator?.share) {
      try {
        let shareObj = {
          title: 'SetStream.io Video Village Access',
          url: process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room,
          text: 'code:' + props.waitingRoomCode
        }
        await navigator.share(shareObj)
      } catch (error) {
        // enqueueSnackbar("Sharing error.  Please double check with your viewer if they received the link.", { variant: 'error' })
        console.error("Could not share link ", error);
      }
    }
  }

  const shareEmbeddedLink = async () => {
    if (navigator?.share) {
      try {
        let shareObj = {
          title: 'SetStream.io Video Village Access',
          url: process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room + '/wr/' + props.waitingRoomHash,
        }
        await navigator.share(shareObj)
      } catch (error) {
        // enqueueSnackbar("Sharing error.  Please double check with your viewer if they received the link.", { variant: 'error' })
        console.error("Could not share link ", error);
      }
    }
  }

  // Render
  return (
    <Grid container direction='column'>              
      {
        props.room &&
        props.waitingRoomCode &&
        <Grid item xs={12}>
          <Tooltip
            title="Seperate link and code.  The viewer must enter the code for access."
            placement='bottom-start'
          >
            <Typography
              display='inline'
            >
              Link and Code:
            </Typography>
          </Tooltip>
          {
            canShare &&
            <Tooltip
              title="Share"
              placement='bottom-end'
            >
              <IconButton onClick={shareCodeLink} size="large">
                <ShareIcon color="secondary" />
              </IconButton>
            </Tooltip>
          }

          <Tooltip
            title="Copy"
            placement='bottom-end'
          >
            <IconButton
              onClick={() => copyText("Link and Code",
                "LINK:" + process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room + " \nCODE:" + props.waitingRoomCode
              )}
              size="large">
              <FileCopyIcon color="secondary" />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Show"
            placement='bottom-end'
          >
            <IconButton onClick={() => setShowCodeLink(!showCodeLink)} size="large">
              {
                showCodeLink &&
                <VisibilityIcon color="secondary" />
              }
              {
                !showCodeLink &&
                <VisibilityOffIcon color="secondary" />
              }
            </IconButton>
          </Tooltip>
        </Grid>
      }
      {
        showCodeLink &&
        props.room &&
        props.waitingRoomCode &&
        <Grid item xs={12} className={classes.linkContainer}>
          <Typography
            color='primary'
          >
            Link:
          </Typography>
          <Typography
            variant='subtitle2'
            paragraph
            style={{
              overflowWrap: 'anywhere'
            }}
          >
            <Tooltip
              title="Copy"
              placement='bottom-end'
            >
              <IconButton 
                onClick={() => copyText("Link Only",
                process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room
                )}
                size="small"
                >
                <FileCopyIcon color="secondary" />
              </IconButton>
            </Tooltip>
            {process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room}
          </Typography>

          <Typography
            color='primary'
          >
            Access Code:
          </Typography>
          <Typography
            variant='h5'
            style={{
              overflowWrap: 'anywhere'
            }}
          >
            <Tooltip
              title="Copy"
              placement='bottom-end'
            >
              <IconButton 
                onClick={() => copyText("Code Only",
                props.waitingRoomCode
                )}
                size="small"
                >
                <FileCopyIcon color="secondary" />
              </IconButton>
            </Tooltip>
            {props.waitingRoomCode}
          </Typography>
        </Grid>
      }

      {
        props.room &&
        props.waitingRoomHash &&
        <Grid item xs={12}>
          <Tooltip
            title="Single link, no code needed.  This option is a little less secure and more convenient than the seperate link + code option.  If Auto Apporve is turned on anyone with this link will have immediate access to your project."
            placement='bottom-start'
          >
            <Typography
              display='inline'
            >
              Embedded Link:
            </Typography>
          </Tooltip>
          {
            canShare &&
            <Tooltip
              title="Share"
              placement='bottom-end'
            >
              <IconButton onClick={shareEmbeddedLink} size="large">
                <ShareIcon color="secondary" />
              </IconButton>
            </Tooltip>
          }

          <Tooltip
            title="Copy"
            placement='bottom-end'
          >
            <IconButton
              onClick={() => copyText("Embedded Link", process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room + '/wr/' + props.waitingRoomHash)}
              size="large">
              <FileCopyIcon color="secondary" />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Show"
            placement='bottom-end'
          >
            <IconButton onClick={() => setShowEmbeddedLink(!showEmbeddedLink)} size="large">
              {
                showEmbeddedLink &&
                <VisibilityIcon color="secondary" />
              }
              {
                !showEmbeddedLink &&
                <VisibilityOffIcon color="secondary" />
              }
            </IconButton>
          </Tooltip>
            
        </Grid>
      }

      {
        showEmbeddedLink &&
        props.room &&
        props.waitingRoomHash &&
        <Grid item xs={12} className={classes.linkContainer}>
          <Typography
            color='primary'
          >
            Embedded Link:
          </Typography>
          <Typography
            variant='subtitle2'
            display="inline"
            style={{
              overflowWrap: 'anywhere'
            }}
          >
            {process.env.REACT_APP_HOST_URL + "/VideoVillage/" + props.room + '/wr/' + props.waitingRoomHash}
          </Typography>
        </Grid>
      }
    </Grid>
  );

}
