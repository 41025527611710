import { useContext } from 'react';
import { CameraContext } from './CameraProvider';

export default function useCamera() {
  const context = useContext(CameraContext);
  if (!context) {
    console.error('No Camera provider found');
  }
  return context;
}
