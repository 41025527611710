import Debug from "debug"; const debug = Debug("SS:Project:WaitingRoomList");
import React, { useEffect, useState } from "react";
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material/";
import { useSnackbar } from "notistack";
import WaitingRoomViewer from "./WaitingRoomViewer";
import WaitingRoomNewCode from "./WaitingRoomNewCode";
import WaitingRoomAutoApprove from "./WaitingRoomAutoApprove";
import WaitingRoomLinks from "./WaitingRoomLinks";
import Pusher, { PresenceChannel, Channel } from "pusher-js"

type Viewer = {
  uuid: String;
};

type Props = {
  cssClasses: any;
  publisher_uuid: String;
  userUuid: String;
  room: string;
  pusher: Pusher;
  soloroom: Channel;
  presenceChannel: PresenceChannel;
  setWaitingRoomLength: (length: number) => void
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    newCodeBox: {
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
  })
);

export default function WaitingRoomList(props: Props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [inProgress, setInProgress] = useState<Boolean>(false);
  const [waitingRoomCode, setWaitingRoomCode] = useState<string>();
  const [waitingRoomHash, setWaitingRoomHash] = useState<string>();
  const [waitingRoom, setWaitingRoom] = useState<Viewer[]>([]);
  const [autoApprove, setAutoApprove] = useState<Boolean>(false);
  const [viewerOnline, setViewerOnline] = useState<Viewer>();
  const [viewerOffline, setViewerOffline] = useState<Viewer>();

  // Effects
  useEffect(() => {
    getWaitingRoom();
  }, []);

  useEffect(() => {
    if (viewerOnline) {
      let currentWr = waitingRoom;
      let foundViewer = currentWr.findIndex(
        (aUser) => aUser.uuid === viewerOnline.uuid
      );

      // Update or add viewer
      if (foundViewer === -1) {
        currentWr.push(viewerOnline);
      } else {
        currentWr[foundViewer] = viewerOnline;
      }

      setWaitingRoom(currentWr);
      props.setWaitingRoomLength(currentWr.length);

      setViewerOnline(undefined);
    }
  }, [viewerOnline]);

  useEffect(() => {
    if (viewerOffline) {
      let currentWr = waitingRoom.filter(
        (aUser) => aUser.uuid !== viewerOffline.uuid
      );
      setWaitingRoom(currentWr);
      props.setWaitingRoomLength(currentWr.length);

      setViewerOffline(undefined);
    }
  }, [viewerOffline]);

  useEffect(() => {
    props.pusher
      ?.subscribe("presence-" + props.room + "_wr")
      .bind("pusher:member_added", (message) => {
        debug("New Member", message)
        if (
          message.id !== props.publisher_uuid &&
          message.id !== props.userUuid &&
          message.info &&
          message.info.viewerType &&
          message.info.viewerType !== "project" &&
          message.info.viewerType !== "broadcaster" &&
          message.info.viewerType !== "producer"
        ) {
          let newViewer = {
            uuid: message.id,
            nickName: message.info?.name,
          };
          setViewerOnline(newViewer);
          enqueueSnackbar("New viewer in the waiting room");
        }
      })
      .bind("pusher:member_removed", (message) => {
        setViewerOffline({ uuid: message.id, nickName: message.info?.name });
      });
  }, [props.pusher]);

  // Actions
  const getWaitingRoom = async () => {
    try {
      setInProgress(true);
      let fetchUrl =
        process.env.REACT_APP_API_URL +
        "/api/waiting_room/list/" +
        props.publisher_uuid;
      let res = await fetch(fetchUrl, { credentials: "include" });
      if (res.ok) {
        let response = await res.json();
        setInProgress(false);

        if (response.type !== "success") {
          console.error("Could not get waiting room");
          enqueueSnackbar(
            "We had trouble getting the waiting room.  Please refresh the page and try again",
            { variant: "warning" }
          );
          // TODO: Log to server
        }

        // Store response in state
        if (response.waitingRoomCode) {
          setWaitingRoomCode(response.waitingRoomCode);
        }
        if (response.waitingRoomHash) {
          setWaitingRoomHash(response.waitingRoomHash);
        }
        if (typeof response.autoApprove === "boolean") {
          setAutoApprove(response.autoApprove);
        }
        if (response.waitingRoom.length > waitingRoom?.length) {
          setWaitingRoom(response.waitingRoom);
          props.setWaitingRoomLength(response.waitingRoom.length);
        }
      } else {
        setInProgress(false);
        console.error("Could not retrieve data ", res.status);
      }
    } catch (error) {
      setInProgress(false);
      console.error("Network error getting data ", error);
    }
  };

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>
        <Grid container className={props.cssClasses.tableHeader}>
          <Typography>Shareable Link</Typography>
        </Grid>

        <Grid container className={props.cssClasses.tableRow}>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            xl={10}
            className={props.cssClasses.tableCell}
          >
            <WaitingRoomLinks
              waitingRoomCode={waitingRoomCode}
              waitingRoomHash={waitingRoomHash}
              {...props}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            xl={2}
            className={props.cssClasses.tableCell}
          >
            <Grid container className={classes.newCodeBox}>
              <Grid item xs={6} md={12}>
                <WaitingRoomNewCode
                  setWaitingRoomCode={setWaitingRoomCode}
                  setWaitingRoomHash={setWaitingRoomHash}
                  {...props}
                />
              </Grid>
              <Grid item xs={6} md={12}>
                <WaitingRoomAutoApprove
                  autoApprove={autoApprove}
                  setAutoApprove={setAutoApprove}
                  {...props}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={props.cssClasses.tableRow}>
          <Grid item xs={12} className={props.cssClasses.tableCell}>
            {waitingRoom?.map((viewer) => (
              <WaitingRoomViewer
                viewer={viewer}
                publisher_uuid={props.publisher_uuid}
              />
            ))}
            {waitingRoom?.length === 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Waiting Room is empty</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
