import Debug from "debug";
const debug = Debug("SS:VideoChat:PeerList");
import { useMemo } from "react";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";

import Peer from "../Peer/Peer";

import Grid from "@mui/material/Unstable_Grid2";
import { Theme, useMediaQuery } from "@mui/material";

export default function PeerList() {
  const { sourceIdList, chatCollapsed, activeSpeaker } = useVideoChat();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const isLandscape = useMediaQuery("(orientation:landscape)");

  const renderer = useMemo(() => {
    const peers = [];
    const offScreenPeers = [];
    for (const peer of sourceIdList.values()) {
      if (
        isMobile && 
        activeSpeaker && 
        peer.sourceId !== activeSpeaker
      ) {
        offScreenPeers.push(
          <Grid
            sx={{
              height: 0,
              width: 0,
              display: "none",
            }}
            key={"grid_" + peer.sourceId}
            id={peer.sourceId}
          >
            <Peer peer={peer} offscreen={true} key={"peer_" + peer.sourceId} />
          </Grid>
        );
      } else {
        peers.push(
          <Grid
            sx={{
              height: "100%",
              minWidth: "136px",
              maxWidth: "136px",
              width: "136px",
            }}
            key={"grid_" + peer.sourceId}
            id={peer.sourceId}
          >
            <Peer peer={peer} key={"peer_" + peer.sourceId} />
          </Grid>
        );
      }
    }

    return (
      <Grid
        sx={{
          flex: 1,
          height: {
            xs: isLandscape ? "calc(50% - 18px)" : "100%",
            md: "100%",
          },
          width: "100%",
          marginRight: {
            xs: isLandscape ? "0px" : "4px",
            md: "4px",
          },
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            width: 1,
            height: "100%",
            borderLeft: "1px solid black",
            paddingLeft: 4,
            overflowX: "auto",
            overflowY: "hidden",
            flexWrap: "nowrap",
          }}
          id="PeerListContainer"
        >
          {peers}
          {isMobile && offScreenPeers}
        </Grid>
      </Grid>
    );
  }, [sourceIdList, chatCollapsed, activeSpeaker, isLandscape]);

  return renderer;
}
