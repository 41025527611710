import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Select,
MenuItem,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import formatExpiration from '../../../../utils/format_expiration';
import AttachPassToProjectSelect from '../Utils/attach_pass_to_project_select';
import useDashboardAccount from '../../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  spacer: {
    paddingTop: theme.spacing(12)
  }
}));

export default function AvailablePassesList(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { availablePasses } = useDashboardAccount();
  const [selected, setSelecte] = useState();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>

        {/* Desktop */}
        <Hidden mdDown>
          <Grid container className={props.cssClasses.tableHeader}>
            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Pass
              </Typography>
            </Grid>

            <Grid item xs={1} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Viewers
              </Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Duration
              </Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Purchased
              </Typography>
            </Grid>

            <Grid item xs={2} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Activate On Project
              </Typography>
            </Grid>

            <Grid item xs={3} align="center" className={props.cssClasses.tableCell}>
              <Typography>
                Pass ID
              </Typography>
            </Grid>
          </Grid>

          {
            availablePasses?.map((pass, i) => (
              <Grid container key={'pass1'+i} className={props.cssClasses.tableRow}>
                <Grid item xs={2} key={'pass2'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass3'+i}>
                    {pass.pass_type}
                  </Typography>
                </Grid>

                <Grid item xs={1} key={'pass4'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass5'+i}>
                    {pass.max_allowed_users}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={'pass5'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass6'+i}>
                    {pass.active_duration + ' ' + pass.duration_units}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={'pass7'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass8'+i}>
                    {formatExpiration(pass.purchased_on)}
                  </Typography>
                  <Typography key={'pass8A'+i}>
                    Order #: {pass.order_id}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={'pass9'+i} align="center" className={props.cssClasses.tableCell}>
                  <AttachPassToProjectSelect
                    key={'pass10'+i}
                    pass={pass}
                    {...props}
                  />
                </Grid>

                <Grid item xs={3} key={'pass11'+i} align="center" className={props.cssClasses.tableCell}>
                  <Typography key={'pass12'+i}>
                    {pass._id}
                  </Typography>
                </Grid>
                
              </Grid>
            ))
          }
        </Hidden>

        {/* Mobile */}
          {
            availablePasses &&
            <Hidden mdUp>
              {availablePasses?.map((pass, i) => (
                <Grid container key={'pass-5'+i} className={props.cssClasses.tableRow}>
                  <Grid container key={'pass-4'+i} className={props.cssClasses.tableHeader}>
                    <Grid item xs={12} key={'pass-3'+i} className={props.cssClasses.tableCell}>
                    <Typography key={'pass-2'+i}>
                      Pass
                    </Typography>
                    </Grid>
                  </Grid>
                  
                  <Grid item xs={6} key={'pass-1'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass0'+i}>
                      Viewers
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass1'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass2'+i}>
                      Duration
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass3'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass5'+i}>
                      {pass.max_allowed_users}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass4'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass5'+i}>
                      {pass.active_duration + ' ' + pass.duration_units}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} key={'pass5A'+i} className={classes.spacer}></Grid>

                  <Grid item xs={6} key={'pass5B'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass5C'+i}>
                      Purchased
                    </Typography>
                  </Grid>
                  <Grid item xs={6} key={'pass6'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass7'+i}>
                      {formatExpiration(pass.purchased_on)}
                    </Typography>
                    <Typography key={'pass7A'+i}>
                      Order #: {pass.order_id}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} key={'pass7B'+i} className={classes.spacer}></Grid>

                  <Grid item xs={12} key={'pass8'+i} align="center" className={props.cssClasses.tableCell}>
                    <AttachPassToProjectSelect
                      key={'pass9'+i}
                      pass={pass}
                      {...props}
                    />
                  </Grid>

                  <Grid item xs={12} key={'pass9A'+i} className={classes.spacer}></Grid>

                  <Grid item xs={12} key={'pass10'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass10A'+i}>
                      Pass ID
                    </Typography>
                  </Grid>
                  <Grid item xs={12} key={'pass11'+i} align="center" className={props.cssClasses.tableCell}>
                    <Typography key={'pass12'+i}>
                      {pass._id}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} key={'pass13'+i} className={classes.spacer}></Grid>

                </Grid>
              ))}
          </Hidden>
        }

      </Grid>
    </Grid>
  );

}
