import { useState, useEffect } from "react";
import { PeerConnection } from "@millicast/sdk"
import Debug from "debug"; const debug = Debug("SS:VideoChat:useVideoCodecs");

export default function useVideoCodecs() {
  const [availableVideoCodecs, setAvailableVideoCodecs] = useState<string[]>(["h264", "vp8", "vp9"])
  const [scalabilityLayers, setScalabilityLayers] = useState<string[] | null>(null);

  useEffect(() => {
    const supportedVideoCapabilities = PeerConnection.getCapabilities("video")
    debug("Supported capabilities MC: %o", supportedVideoCapabilities)
    if (!supportedVideoCapabilities) { 
      debug("Error getting supported video capabilities")
      return 
    }

    if (
      supportedVideoCapabilities.scalabilityModes
    ) {
      debug("Scaleability modes", supportedVideoCapabilities.scalabilityModes)
      // TODO: How to choose the correct mode?
      setScalabilityLayers(supportedVideoCapabilities.scalabilityModes)
    }

    if (supportedVideoCapabilities.codecs.length > 0) {
      const availableCodecs = supportedVideoCapabilities.codecs.map(codec => codec.codec)
      setAvailableVideoCodecs(availableCodecs)
      debug("Available Codecs ", availableCodecs)
    }
  }, []);

  return { availableVideoCodecs, scalabilityLayers }
}