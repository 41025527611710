import { Divider, Paper, useMediaQuery } from "@mui/material";

import Connection from "../Connection/Connection";
import ShowSettings from "../ShowSettings/ShowSettings";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import CollapseChat from "../CollapseChat/CollapseChat";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMemo } from "react";
import ToggleVideoEnabled from "../Video/ToggleVideoEnabled/ToggleVideoEnabled";
import ToggleAudioEnabled from "../Audio/ToggleAudioEnabled/ToggleAudioEnabled";

export default function MyControls() {
  const {
    pubConnectionStatus,
    pubReconnecting,
    subConnectionStatus,
    subReconnecting,
    chatCollapsed,
  } = useVideoChat();

  const isLandscape = useMediaQuery("(orientation:landscape)")

  const renderer = useMemo(() => {
    let controls = [];
    if (
      pubConnectionStatus === "connected" ||
      subConnectionStatus === "connected" ||
      pubReconnecting ||
      subReconnecting
    ) {
      controls.push(
        <Grid>
          <Connection variation="icon" />
        </Grid>
      );
      if (!chatCollapsed) {
        controls.push(
          <Grid
            sx={{
              width: {
                xs: isLandscape ? "1px" : "100%",
                md: "100%"
              },
              height: {
                xs: isLandscape ? "100%" : "1px",
                md: "1px"
              }
            }}
          >
            <Divider />
          </Grid>
        );
        controls.push(
          <Grid>
            <ToggleVideoEnabled variant="icon" />
          </Grid>
        );
        controls.push(
          <Grid>
            <ToggleAudioEnabled variant="icon" />
          </Grid>
        );
        controls.push(
          <Grid
            sx={{
              width: {
                xs: isLandscape ? "1px" : "100%",
                md: "100%"
              },
              height: {
                xs: isLandscape ? "100%" : "1px",
                md: "1px"
              }
            }}
          >
            <Divider />
          </Grid>
        );
        controls.push(
          <Grid>
            <ShowSettings variant="icon" />
          </Grid>
        );
      }
    }

    return (
      <Grid
        container
        justifyContent="center"
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          flexDirection: {
            xs: isLandscape ? "row" : "column",
            md: "column"
          },
          // flexWrap: "nowrap"
        }}
      >
        <Grid
          sx={{
            flex: 1,
          }}
        >
          <CollapseChat />
        </Grid>

        {controls}
      </Grid>
    );
  }, [
    pubConnectionStatus,
    pubReconnecting,
    subConnectionStatus,
    subReconnecting,
    chatCollapsed,
    isLandscape
  ]);

  return (
    <Grid
      sx={{
        height: {
          xs: isLandscape ? "36px" : "100%",
          md: "100%"
        },
        width: {
          xs: isLandscape ? "100%" : "36px",
          md: "36px"
        },
        paddingRight: "4px",
      }}
    >
      <Paper
        sx={{
          height: "100%",
          backgroundColor: "background.dark",
        }}
      >
        {renderer}
      </Paper>
    </Grid>
  );
}
