import {
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";

import useCamera from "../../../Context/CameraProvider/useCamera";

export default function AdvancedAudioSettings() {
  const supportedConstraints: MediaTrackSupportedConstraints =
    navigator.mediaDevices.getSupportedConstraints();

  // Hooks
  const {
    echoCancellation,
    updateEchoCancellation,
    audioProcessing,
    updateAudioProcessing,
    autoGainControl,
    updateAutoGainControl,
    gettingAudioMedia,
  } = useCamera();

  // Render
  return (
    <Grid container spacing={2}>
      <Grid
        xs={12}
        sx={{
          textAlign: "center",
          borderBottom: "1px solid",
          borderColor: "secondary.main",
          marginBottom: "12px",
        }}
      >
        <Typography>Audio Settings</Typography>
      </Grid>

      {supportedConstraints.echoCancellation && (
        <Grid xs={12}>
          <FormControlLabel
            label="Echo Cancellation"
            labelPlacement="end"
            control={
              <Switch
                checked={echoCancellation}
                id="echoCancellation"
                color="secondary"
                onChange={() => updateEchoCancellation(!echoCancellation)}
                disabled={gettingAudioMedia}
              />
            }
          />
        </Grid>
      )}

      {supportedConstraints.autoGainControl && (
        <Grid xs={12}>
          <FormControlLabel
            label="Auto Gain"
            labelPlacement="end"
            control={
              <Switch
                checked={autoGainControl}
                id="autoGainControl"
                color="secondary"
                onChange={() => updateAutoGainControl(!autoGainControl)}
                disabled={gettingAudioMedia}
              />
            }
          />
        </Grid>
      )}

      {(
        supportedConstraints.noiseSuppression ||
          // @ts-expect-error in Chrome, not in spec
        supportedConstraints.voiceIsolation
      ) && (
        <Grid xs={12}>
          <Select
            id="audioProcessing"
            value={audioProcessing}
            label="Audio Processing"
            variant="outlined"
            onChange={(ap) => updateAudioProcessing(ap.target.value)}
            disabled={gettingAudioMedia}
          >
            {supportedConstraints.noiseSuppression && (
              <MenuItem value="noiseSuppression">Noise Suppression</MenuItem>
            )}
            
            {// @ts-expect-error in Chrome, not in spec
            supportedConstraints.voiceIsolation && (
              <MenuItem value="voiceIsolation">
                Voice Isolation (BETA - Chrome Only)
              </MenuItem>
            )}

            <MenuItem value="none">None</MenuItem>
          </Select>
        </Grid>
      )}
    </Grid>
  );
}
