import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
Button,
} from '@mui/material/';
import { useSnackbar } from 'notistack';

type Viewer = {
  uuid: String
}

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  textBox: {
    margin: 'auto'
  },
  buttonBox: {
    margin: 'auto',
    textAlign: 'right'
  }
}));

export default function WaitingRoomViewer(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()

  // State
  

  // Effects

  // Actions
  const approveViewer = async () => {
    try {
      if (!props.viewer?.uuid) { throw new Error("No viewer found to approve", {cause: props.viewer})}

      let fetchUrl = process.env.REACT_APP_API_URL + '/api/waiting_room/access/' + props.publisher_uuid
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          uuidToApprove: props.viewer.uuid
        })
      }
      let res = await fetch(fetchUrl, fetchData);
      let response = await res.json();
      if (!res.ok) {
        console.error('Could not retrieve data ', res.status);
        enqueueSnackbar("Error - " + response.message, { variant: "warning" })
      }  
    } catch (error) {
      console.error('Network error getting data ', error);
    }
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={8} className={classes.textBox}>
        <Grid container>
          <Grid item xs={12} md={4}>
            {props.viewer?.nickName}
          </Grid>

          <Grid item xs={12} md={8}>
            {props.viewer?.uuid}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4} className={classes.buttonBox}>
        <Button
          onClick={approveViewer}
          color="primary"
          size='small'
        >
          Admit Viewer
        </Button>
      </Grid>

    </Grid>
  );

}
