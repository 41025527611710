import Debug from "debug"; const debug = Debug("SS:Sketches:DrawHistory");
import clearCanvas from './clearCanvas';
import setupCanvas from './setupCanvas';
import drawRoundRect from './drawRoundRect';
import { Sketch } from '../sketches_types';
import { Members } from 'pusher-js';

// Redraw downloaded history
export default function drawSketchHistory(
  canvasContext: CanvasRenderingContext2D,
  sketchHistory: Array<Sketch>,
  uuid: string,
  layer: string,
  excludes: Array<string>,
  members?: Members | undefined
) {
  // Erase current canvas
  debug("Clearing Canvas")
  clearCanvas(canvasContext);

  // Redraw from history
  sketchHistory.forEach((sketch: Sketch) => {
    debug("Drawing Sketch", sketch)
    // Ignore all markers
    if (sketch.sketchType?.toLowerCase() === 'marker') { return; }
    // Ignore hidden and undone sketches
    if (!sketch.visible || sketch.undone) { return; }
    // Only show active viewers
    if (members && !members.get(sketch.viewerUuid)) { return; }

    let drawSketch = false;

    switch (layer) {
      case 'local':
        drawSketch = sketch.uuid === uuid
        break;
      case 'remote':
        drawSketch = sketch.uuid !== uuid
        break;
      case 'drawing':
        drawSketch = !excludes.includes(sketch.uuid);
        break;
    }

    debug("Draw Sketch? ", drawSketch, layer, sketch.uuid, uuid)
    if (drawSketch) {
      setupCanvas(canvasContext, sketch.color);

      // Scale
      let scaleX = canvasContext.canvas.width / sketch.canvasWidth;
      let scaleY = canvasContext.canvas.height / sketch.canvasHeight;

      // Draw
      canvasContext.beginPath();

      switch (sketch.sketchType) {
        default:
        case 'sketch':
          sketch.mEvents.forEach((move, i) => {
            if (i === 0) {
              canvasContext.moveTo(move.x * scaleX, move.y * scaleY);
            } else {
              canvasContext.lineTo(move.x * scaleX, move.y * scaleY);
            }
          });
          canvasContext.stroke();
          break;
        case 'line':
          if (sketch.mEvents?.length >=2) {
            canvasContext.moveTo(
              sketch.mEvents[0].x * scaleX,
              sketch.mEvents[0].y * scaleY
            );
            canvasContext.lineTo(
              sketch.mEvents[sketch.mEvents.length - 1].x * scaleX,
              sketch.mEvents[sketch.mEvents.length - 1].y * scaleY,
            );
            canvasContext.stroke();
          }
          break;
        case 'box':
            if (sketch.mEvents?.length >= 2) {
              drawRoundRect(
                canvasContext,
                sketch.mEvents[0],
                sketch.mEvents[sketch.mEvents.length - 1],
                scaleX,
                scaleY
              );
            }
          break;
      }
    }
  });
}
