import Debug from "debug"; const debug = Debug("SS:Camera:useUserMedia");
import { useCallback, useEffect, useState } from 'react';
import { useVideoChat } from '../../VideoChatProvider/VideoChatProvider';
import useEvents from '../../Events/useEvents';
import useAudioInputDevices from './useAudioInputDevices';
import useVideoInputDevices from './useVideoInputDevices';
import useMediaDevices, { DevicePermission } from './useMediaDevices';
import { useSnackbar } from "notistack";

export default function useUserMedia() {
  // Hack borrowed from https://stackoverflow.com/questions/57660234/how-can-i-check-if-a-browser-is-chromium-based
  // @ts-expect-error Chrome specific
  const isChrome = !!window.chrome

  // User Media
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>()
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack>()

  const [gettingAudioMedia, setGettingAudioMedia] = useState<boolean>(false);
  const [gettingVideoMedia, setGettingVideoMedia] = useState<boolean>(false);

  const { userUuid } = useVideoChat()
  const { dispatch } = useEvents()
  const { enqueueSnackbar } = useSnackbar()

  const {
    isActive,
    withAudio,
    setWithAudio,
    withVideo,
    setWithVideo
  } = useVideoChat()

  // Media Devices
  const {
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission,
    getAvailableDevices,
    gettingDevices
  } = useMediaDevices();

  // Audio
  const {
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    audioProcessing,
    updateAudioProcessing,
    autoGainControl,
    updateAutoGainControl,
    getAudioConstraints
  } = useAudioInputDevices(
    "VC",
    userUuid,
    audioInputDevices,
  );

  // Video
  const {
    videoDeviceId,
    updateVideoDeviceId,
    videoFacingMode,
    updateVideoFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth,
    updateVideoWidth,
    videoQuality,
    updateVideoQuality,
    getVideoConstraints,
    supportedVideoCapabilities
  } = useVideoInputDevices("VC", userUuid, videoInputDevices);

  // Actions
  const getUserMedia = useCallback(
    async ({
      audio = false,
      video = false
    }: {
      audio?: boolean,
      video?: boolean
    } = {
        audio: false,
        video: false
      }) => {
      const reqId = Date.now()
      try {
        debug("Getting User media", isActive, audio, video, getAudioConstraints, getVideoConstraints, reqId)
        if (!navigator.mediaDevices) {
          enqueueSnackbar("It appears your browser does not support media devices.  If you are on iOS try using Safari or Chrome.")
          throw new Error("It appears your browser does not support media devices.  If you are on iOS try using Safari or Chrome.")
        }

        if (!isActive) {
          debug("VC Not Active", reqId)
          return
        }

        // Make sure we want user media
        if (audio === false && video === false) {
          debug("Audio and Video are both disabled.  Skipping", reqId)
          return
        }

        const constraints: {
          audio: MediaTrackConstraints | boolean,
          video: MediaTrackConstraints | boolean
        } = {
          audio: false,
          video: false
        }

        if (audio) {
          setGettingAudioMedia(true)
          constraints.audio = getAudioConstraints
        }

        if (video) {
          setGettingVideoMedia(true)
          constraints.video = getVideoConstraints
        }

        if (!constraints.video && !constraints.audio) {
          debug("All constraints are false.  Skipping", constraints, reqId)
          return
        }

        // Stop current tracks before we get new ones
        debug("Removing existing tracks", audioTrack, videoTrack)
        if (audio && audioTrack) {
          debug("Prepping Gum, stopping audio track", audioTrack)
          audioTrack.stop()
        }
        if (video && videoTrack) {
          debug("Prepping Gum, stopping video track", videoTrack)
          videoTrack.stop()
        }

        // Get User Media
        debug("Getting user media with", constraints, reqId)
        const videoMedia = await navigator.mediaDevices.getUserMedia(constraints);
        debug("Got user media", videoMedia, reqId)

        const newVideoTracks = videoMedia.getVideoTracks();
        const newAudioTracks = videoMedia.getAudioTracks();

        // Update audio tracks
        if (audio && newAudioTracks?.length > 0) {
          dispatch.emit("newTrack", { track: newAudioTracks[0] })
          // We are only allowed 1 track with the MC SDK
          if (audioTrack) {
            debug("New Audio Track.  Stopping old", audioTrack, newAudioTracks[0])
            audioTrack.stop()
          }
          setAudioTrack(newAudioTracks[0])
          debug("Audio NewTrack added to stream", newAudioTracks[0], newAudioTracks[0].getSettings(), reqId);
          // DEBUG
          window.at = newAudioTracks[0]
        }

        // Add new video tracks
        if (video && newVideoTracks?.length > 0) {
          dispatch.emit("newTrack", { track: newVideoTracks[0] })
          // We are only allowed 1 track with the MC SDK
          if (videoTrack) {
            debug("New Video Track.  Stopping old", videoTrack, newVideoTracks[0])
            videoTrack.stop()
          }
          setVideoTrack(newVideoTracks[0])
          debug("Video NewTrack Emitted", newVideoTracks[0], newVideoTracks[0].getSettings(), reqId);
        }

        debug("User Media Stream captured ", newAudioTracks, newVideoTracks, reqId);
      } catch (error: unknown) {

        // TODO: Alert user of error
        // TODO: log user media errors to server?

        debug("Error getting user media", error, reqId)
        if (audio) { setWithAudio(false) }
        if (video) { setWithVideo(false) }

        // TODO: Handle 
        switch ((error as Error).name) {
          case 'AbortError':
            // Nothing seemed wrong but we failed.
            // Try again
            break;

          case 'NotAllowedError':
            // Permission denied.  This should already be handled by 
            // useDevices
            break;

          case 'NotFoundError':
            // We succeeded but got no tracks
            // Notify user to update settings
            break;

          case 'NotReadableError':
            // Something went wrong with the HW/OS/App
            // Try once more then notify?
            break;

          case 'OverconstrainedError':
            debug("overconstrained error ", (error as OverconstrainedError).constraint, error, reqId);
            // Check the constraint with an issue and adress or custom notify
            switch ((error as OverconstrainedError).constraint) {
              case "deviceId":

                break;
              // Video
              case "aspectRatio":
              case "frameRate":
              case "videoFacingMode":
              case "resizeMode":
              case "width":
              case "height":

              // Audio
              // eslint-disable-next-line no-fallthrough
              case "autoGainControl":
              case "echoCancellation":
              case "noiseSuppression":
              case "channelCount":


              // eslint-disable-next-line no-fallthrough
              default:
                // Notify with error.constraint
                debug("%s Constraint Error - %s", (error as OverconstrainedError).constraint, (error as OverconstrainedError).message)
                break;
            }
            break;

          case 'SecurityError':
            // user media is disabled at the document level
            // TODO: Research if there are browser settings that do this
            // and the specifics of each browser
            break;

          case 'TypeError':
            // Our constraints are empty
            // Or the context is insecure (shouldn't happen outside of dev ever)
            // And an error should have already prevented us from reaching this point
            break;

          default:

            break;
        }

        debug('Could not get user media ', error, reqId);
      } finally {
        if (audio) { setGettingAudioMedia(false) }
        if (video) { setGettingVideoMedia(false) }
      }
    }, [isActive, audioTrack, videoTrack, getVideoConstraints, getAudioConstraints])


  const stopAudioMedia = useCallback(() => {
    debug("Stopping Audio Media", audioTrack)
    if (audioTrack) {
      audioTrack.stop()
      debug("Audio Track Stopped", audioTrack)
    }
    setWithAudio(false)
    setAudioTrack(undefined)
  }, [audioTrack])

  const stopVideoMedia = useCallback(() => {
    debug("Stopping Video Media", videoTrack)
    if (videoTrack) {
      videoTrack.stop()
      debug("Video Track Stopped", videoTrack)
    }
    setWithVideo(false)
    setVideoTrack(undefined)
  }, [videoTrack])

  const stopAllMedia = useCallback(() => {
    debug("Stopping All Media")
    stopAudioMedia()
    stopVideoMedia()
  }, [stopAudioMedia, stopVideoMedia])


  // ** Effects **
  useEffect(() => {
    // Ensure we stop capturing media when the component in not rendered
    return () => {
      debug("Cleaning up media")
      stopAllMedia()
    }
  }, [])


  // ** Audio **
  useEffect(() => {
    if (audioTrack) {
      // Update settings to reflect the audio track settings
      const settings = audioTrack.getSettings()
      debug("Updating Settings", settings)
      if (settings.deviceId && settings.deviceId !== "") {
        updateAudioDeviceId(settings.deviceId)
      }

      if (typeof settings.channelCount === "number") {
        updateAudioChannels(settings.channelCount)
      }

      if (typeof settings.echoCancellation === "boolean") {
        updateEchoCancellation(settings.echoCancellation)
      }

      if (
        // @ts-expect-error In chrome but not in spec
        settings.voiceIsolation === true
      ) {
        updateAudioProcessing("voiceIsolation")
      } else if (
        settings.noiseSuppression === true
      ) {
        updateAudioProcessing("noiseSuppression")
      }

      if (typeof settings.autoGainControl === "boolean") {
        updateAutoGainControl(settings.autoGainControl)
      }
    }

    const handleEnded = (event: Event) => {
      debug("Audio Track Ended", event)
      setWithAudio(false)
    }

    audioTrack?.addEventListener("ended", handleEnded)
    return (() => {
      audioTrack?.removeEventListener("ended", handleEnded)
    })
  }, [audioTrack]);

  // Activation
  useEffect(() => {
    if (!isActive) { return }
    if (!withAudio) { return }
    if (audioTrack) { return } // Prevent multiple requests
    if (
      audioInputPermission === DevicePermission.allowed &&
      !gettingAudioMedia
    ) {
      debug("Getting Audio Media as we are now active")
      getUserMedia({
        audio: audioInputPermission === DevicePermission.allowed,
        video: false
      }).catch(error => {
        debug("Error getting audio media", error)
      })
    } else if (
      audioInputPermission === DevicePermission.unknown &&
      !gettingDevices
    ) {
      debug("Getting available devices as permissions unkown")
      getAvailableDevices()
        .catch(error => {
          debug("Error getting available devices", error)
        })
    }
  }, [isActive, withAudio, audioTrack, audioInputPermission, gettingAudioMedia, getUserMedia, getAvailableDevices, gettingDevices])

  // Applying constraints fails on chromium browsers
  // A new GUM call must be made instead
  // https://issues.chromium.org/issues/40555809

  // Device ID
  useEffect(() => {
    if (!audioDeviceId) { return }
    if (!audioTrack) { return }
    if (gettingAudioMedia) { return }

    const settings = audioTrack.getSettings()
    if (
      settings.deviceId &&
      settings.deviceId !== audioDeviceId) {
      debug("Updating Audio Device ID", audioDeviceId)
      getUserMedia({ audio: true }).catch(error => {
        debug("Error updating audio device id", error)
      })
    }
  }, [audioDeviceId, audioTrack, gettingAudioMedia, getUserMedia])

  // Channel Count
  useEffect(() => {
    if (!audioChannels) { return }
    if (!audioTrack) { return }
    if (gettingAudioMedia) { return }

    let settings = audioTrack.getSettings()
    let constraints = audioTrack.getConstraints()
    if (
      typeof settings.channelCount === "number" &&
      settings.channelCount !== audioChannels
    ) {
      debug("Updating audio channel count", audioChannels)

      if (isChrome) {
        getUserMedia({ audio: true }).catch(error => {
          debug("Error updating audio channel count")
        })
      } else {
        constraints.channelCount = audioChannels
        audioTrack
          .applyConstraints(constraints)
          .then(() => {
            debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
            const settings = audioTrack.getSettings()
            if (settings.channelCount !== constraints.channelCount) {
              debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            }
          })
          .catch(error => {
            debug("Error updating audio channels", audioChannels, error, constraints, audioTrack)
          })
      }

    }
  }, [audioChannels, audioTrack, gettingAudioMedia, getUserMedia])

  // Echo Cancellation
  useEffect(() => {
    if (typeof echoCancellation !== "boolean") { return }
    if (!audioTrack) { return }
    if (gettingAudioMedia) { return }

    let settings = audioTrack.getSettings()
    let constraints = audioTrack.getConstraints()
    if (
      typeof settings.echoCancellation === "boolean" &&
      settings.echoCancellation !== echoCancellation
    ) {
      debug("Updating audio echo cancellation", echoCancellation, settings, constraints)

      if (isChrome) {
        getUserMedia({ audio: true }).catch(error => {
          debug("Error updating audio echo cancellation", error)
        })
      } else {
        constraints.echoCancellation = echoCancellation
        audioTrack
          .applyConstraints(constraints)
          .then(() => {
            debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
            const settings = audioTrack.getSettings()
            if (settings.echoCancellation !== constraints.echoCancellation) {
              // Settings did not update, recapture stream
              debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            }
          })
          .catch(error => {
            debug("Error updating audio echo cancellation", audioChannels, error, constraints, audioTrack)
          })
      }
    }
  }, [echoCancellation, audioTrack, gettingAudioMedia, getUserMedia])

  useEffect(() => {
    if (!audioProcessing) { return }
    if (
      audioProcessing !== "noiseSuppression" &&
      audioProcessing !== "voiceIsolation" &&
      audioProcessing !== "none"
    ) { return }
    if (!audioTrack) { return }
    if (gettingAudioMedia) { return }

    let settings = audioTrack.getSettings()
    let constraints = audioTrack.getConstraints()
    let applyConstraints = false

    if (audioProcessing === "noiseSuppression") {
      if (
        typeof settings.noiseSuppression === "boolean" &&
        settings.noiseSuppression === false
      ) {
        constraints.noiseSuppression = true
        applyConstraints = true
        // @ts-expect-error in Chrome but not in spec
        if (constraints.voiceIsolation) {
          // @ts-expect-error in Chrome but not in spec
          constraints.voiceIsolation = false
        }
      }
    } else if (audioProcessing === "voiceIsolation") {
      if (
        // @ts-expect-error in Chrome but not in spec
        typeof settings.voiceIsolation === "boolean" &&
        // @ts-expect-error in Chrome but not in spec
        settings.voiceIsolation === false
      ) {
        // @ts-expect-error in Chrome but not in spec
        constraints.voiceIsolation = true
        applyConstraints = true
        if (constraints.noiseSuppression === true) {
          constraints.noiseSuppression = false
        }
      }
    } else if (audioProcessing === "none") {
      if (settings.noiseSuppression === true) {
        constraints.noiseSuppression = false
        applyConstraints = true
      }
      // @ts-expect-error in Chrome but not in spec
      if (settings.voiceIsolation === true) {
        // @ts-expect-error in Chrome but not in spec
        constraints.voiceIsolation = false
        applyConstraints = true
      }
    }

    if (applyConstraints) {
      debug("Updating audio processing", audioProcessing, settings, constraints)

      if (isChrome) {
        getUserMedia({ audio: true }).catch(error => {
          debug("Error updating audio voice isolation", error)
        })
      } else {
        audioTrack
          .applyConstraints(constraints)
          .then(() => {
            debug("Constraints updated ", constraints, audioTrack.getSettings(), audioTrack.getConstraints())
            const settings = audioTrack.getSettings()

            if (
              settings.noiseSuppression !== constraints.noiseSuppression ||
              // @ts-expect-error in Chrome but not in spec
              settings.voiceIsolation !== constraints.voiceIsolation
            ) {
              // Settings did not update, recapture stream
              debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            }
          })
          .catch(error => {
            debug("Error updating audio voice isolation", audioChannels, error, constraints, audioTrack)
          })
      }
    }
  }, [audioProcessing, audioTrack, gettingAudioMedia, getUserMedia])

  // Auto Gain Control
  useEffect(() => {
    debug("Update auto gain", autoGainControl, audioTrack, gettingAudioMedia, audioTrack?.getSettings())
    if (typeof autoGainControl !== "boolean") { return }
    if (!audioTrack) { return }
    if (gettingAudioMedia) { return }


    // Update constraints if they differ
    let settings = audioTrack.getSettings()
    let constraints = audioTrack.getConstraints()
    if (
      typeof settings.autoGainControl === "boolean" &&
      settings.autoGainControl !== autoGainControl
    ) {
      debug("Update Audio Auto Gain Control", autoGainControl)
      constraints.autoGainControl = autoGainControl

      if (isChrome) {
        getUserMedia({ audio: true }).catch(error => {
          debug("Error updating audio channel count", error)
        })
      } else {
        audioTrack
          .applyConstraints(constraints)
          .then(() => {
            debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
            const settings = audioTrack.getSettings()
            if (
              settings.autoGainControl &&
              settings.autoGainControl !== constraints.autoGainControl
            ) {
              // Settings did not update, recapture stream
              debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            }
          })
          .catch(error => {
            debug("Error updating audio channels", autoGainControl, error, constraints, audioTrack)
          })
      }
    }
  }, [autoGainControl, audioTrack, gettingAudioMedia, getUserMedia]);

  // ** Video **
  useEffect(() => {
    if (videoTrack) {
      const settings = videoTrack.getSettings()

      if (settings.deviceId && settings.deviceId !== "") {
        updateVideoDeviceId(settings.deviceId)
      }
    }

    const handleEnded = (event: Event) => {
      debug("Video Track Ended", event)
      setWithVideo(false)
    }

    videoTrack?.addEventListener("ended", handleEnded)
    return (() => {
      videoTrack?.removeEventListener("ended", handleEnded)
    })
  }, [videoTrack]);

  useEffect(() => {
    if (!isActive) { return }
    if (!withVideo) { return }
    if (videoTrack) { return } // Prevent Duplicates
    if (
      videoInputPermission === DevicePermission.allowed &&
      !gettingVideoMedia
    ) {
      debug("Getting Video Media as we are now active")
      getUserMedia({ video: true }).catch(error => {
        debug("Error getting video media", error)
      })
    } else if (
      videoInputPermission === DevicePermission.unknown &&
      !gettingDevices
    ) {
      debug("Getting available devices as permissions unkown")
      getAvailableDevices()
        .catch(error => {
          debug("Error getting available devices", error)
        })
    }
  }, [isActive, withVideo, videoInputPermission, gettingVideoMedia, getUserMedia, getAvailableDevices, gettingDevices])

  useEffect(() => {
    // We must re-call getUserMedia to change deviceId
    if (!videoDeviceId) { return }
    if (!videoTrack) { return }
    if (gettingVideoMedia) { return }

    const settings = videoTrack.getSettings()
    if (
      settings.deviceId &&
      settings.deviceId !== videoDeviceId
    ) {
      debug("Update video device id", videoDeviceId)
      getUserMedia({ video: true }).catch(error => {
        debug("Error getting new video constraints media", error)
      })
    }
  }, [videoDeviceId, videoTrack, gettingVideoMedia, getUserMedia])

  return {
    // Devices
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission,
    getAvailableDevices,
    gettingDevices,

    // Video
    videoDeviceId,
    updateVideoDeviceId,
    videoFacingMode,
    updateVideoFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth,
    updateVideoWidth,
    videoQuality,
    updateVideoQuality,
    getVideoConstraints,
    supportedVideoCapabilities,

    // Audio
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    audioProcessing,
    updateAudioProcessing,
    autoGainControl,
    updateAutoGainControl,
    getAudioConstraints,

    audioTrack,
    setAudioTrack,
    videoTrack,
    setVideoTrack,
    gettingAudioMedia,
    gettingVideoMedia,
    getUserMedia,
    stopAllMedia,
    stopVideoMedia,
    stopAudioMedia,
  };
}
