import { ReactNode } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

/**
 * This component should always be used inside an AppBox
 * Enclose children in <Grid> from Grid2
 * @returns
 */
export default function AppTopBar({ children }: { children: ReactNode }) {
  return (
    <Grid
      sx={{
        flex: 0,
        overflow: "hidden",
      }}
      style={{
        height: "var(--AppBarHeight, 46px)",
        minHeight: "var(--AppBarHeight, 46px)",
        maxHeight: "var(--AppBarHeight, 46px)",
      }}
      id="AppTopBar"
    >
      {children}
    </Grid>
  );
}
