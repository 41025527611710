import { useContext } from 'react';
import { BrandingContext } from './BrandingProvider';

export default function useCustomBranding() {
  const context = useContext(BrandingContext);
  if (!context) {
    console.error('No Custom Branding provider found');
  }
  return context;
}
