// Prices by viewers
export const getPriceListByViewers = (active_duration, duration_units) => {
  // Set price according to pass type and duration
  if (duration_units === 'months') { return monthly_prices }
  if (duration_units === 'days') { return daily_prices }
  if (duration_units === 'hours') {
    if (active_duration === 1) { // 1 Hour
      return one_hour_prices
    }
    if (active_duration > 1 && active_duration <= 3) { // 3 Hour
      return three_hour_prices;
    }
    if (active_duration > 3) { // 5 Hour +
      return five_hour_prices;
    }
    if (active_duration > 7) { // Daily
      return twenty_four_hour_prices
    }
  }
  if (duration_units === 'minutes') {
    if (active_duration === 60) { // 1 Hour
      return sixty_minute_prices
    }
    if (active_duration > 60 && active_duration <= 180) { // 3 Hour
      return one_eighty_minute_prices;
    }
    if (active_duration > 180) { // 5 Hour +
      return three_hundered_minute_prices;
    }
    if (active_duration > 420) { // Daily
      return fourteen_forty_minute_prices
    }
  }
}

// Minutely
export const sixty_minute_prices = {
  1: 5 / 60,
  2: 10 / 60,
  3: 15 / 60,
  5: 25 / 60,
  10: 40 / 60,
  20: 65 / 60,
  30: 85 / 60,
  45: 105 / 60,
  100: 195 / 60,
  name: 'sixty_minute_prices'
}

export const one_eighty_minute_prices = {
  1: 15 / 180,
  2: 25 / 180,
  3: 35 / 180,
  5: 50 / 180,
  10: 75 / 180,
  20: 110 / 180,
  30: 150 / 180,
  45: 195 / 180,
  100: 295 / 180,
  name: 'one_eighty_minute_prices'
}

export const three_hundered_minute_prices = {
  1: 25 / 300,
  2: 40 / 300,
  3: 50 / 300,
  5: 75 / 300,
  10: 125 / 300,
  20: 195 / 300,
  30: 250 / 300,
  45: 295 / 300,
  100: 495 / 300,
  name: 'three_hundered_minute_prices'
}

export const fourteen_forty_minute_prices = {
  1: 25 / 1440,
  2: 50 / 1440,
  3: 75 / 1440,
  5: 125 / 1440,
  10: 195 / 1440,
  20: 295 / 1440,
  30: 395 / 1440,
  45: 495 / 1440,
  100: 795 / 1440,
  name: 'fourteen_forty_minute_prices',
}

// Hourly
export const one_hour_prices = {
  1: 5,
  2: 10,
  3: 15,
  5: 25,
  10: 40,
  20: 65,
  30: 85,
  45: 105,
  100: 195,
  name: 'one_hour_prices'
}

export const three_hour_prices = {
  1: 15 / 3,
  2: 25 / 3,
  3: 35 / 3,
  5: 50 / 3,
  10: 75 / 3,
  20: 110 / 3,
  30: 150 / 3,
  45: 195 / 3,
  100: 295 / 3,
  name: 'three_hour_prices'
}

export const five_hour_prices = {
  1: 25 / 5,
  2: 40 / 5,
  3: 50 / 5,
  5: 75 / 5,
  10: 125 / 5,
  20: 195 / 5,
  30: 250 / 5,
  45: 295 / 5,
  100: 495 / 5,
  name: 'five_hour_prices'
}

// Daily
export const twenty_four_hour_prices = {
  1: 25 / 24,
  2: 50 / 24,
  3: 75 / 24,
  5: 125 / 24,
  10: 195 / 24,
  20: 295 / 24,
  30: 395 / 24,
  45: 495 / 24,
  100: 795 / 24,
  name: 'twenty_four_hour_prices'
}

export const daily_prices = {
  1: 25,
  2: 50,
  3: 75,
  5: 125,
  10: 195,
  20: 295,
  30: 395,
  45: 495,
  100: 795,
  name: 'daily_prices'
}

// Monthly
export const monthly_prices = {
  1: 95,
  2: 195,
  3: 295,
  5: 495,
  10: 795,
  20: 1195,
  30: 1495,
  45: 1745,
  100: 2795,
  name: 'monthly_prices'
}
