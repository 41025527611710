export default function formatTimeStamp(timestamp) {
  // Make timestamp human readable
  let stamp = new Date(Date.parse(timestamp));
  let elapsed = Date.now() - stamp;
  let toDisp;

  if (elapsed > (1000 * 60 * 60 * 24 * 365)) {toDisp = Math.round(elapsed / (1000 * 60 * 60 * 24 * 365)).toString() + 'Y ago'}
  else if (elapsed > (1000 * 60 * 60 * 24 * 31)) {toDisp = Math.round(elapsed / (1000 * 60 * 60 * 24 * 31)).toString() + 'M ago'}
  else if (elapsed > (1000 * 60 * 60 * 24 * 7)) {toDisp = Math.round(elapsed / (1000 * 60 * 60 * 24 * 7)).toString() + 'W ago'}
  else if (elapsed > (1000 * 60 * 60 * 24)) {toDisp = Math.round(elapsed / (1000 * 60 * 60 * 24)).toString() + 'D ago'}
  else if (elapsed > (1000 * 60 * 60)) {toDisp = Math.round(elapsed / (1000 * 60 * 60)).toString() + 'H ago'}
  else if (elapsed > (1000 * 60)) {toDisp = Math.round(elapsed / (1000 * 60)).toString() + 'm ago'}
  else if (elapsed > 1000) {toDisp = Math.round(elapsed / 1000).toString() + 's ago'}
  else { toDisp = 'now'}

  return toDisp;
}
