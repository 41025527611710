import { Component } from 'react';
import { Grid } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import DisplayActivePasses from './Elements/display_active_passes';
import DisplayScheduledPasses from './Elements/display_scheduled_passes';
import ManageAccessStepperDialog from './Dialogs/manage_access_stepper_dialog';

class InviteViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cookies: Cookies.get(props.publisher_uuid),
      confirm_open: false,
      modalAction: 'activate',
      selectedAccessPass: this.props.availablePasses.length > 0 ? this.props.availablePasses[0]._id : '',
      expires: '',
      passButton: false,
    }

    this.channel = props.channel;
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  // Reload the page after expiration
  timeOut() {
    console.log('Pass has expired');
    window.location.reload(false);
  }

  // Modal handlers
  handleOpen(action) {
    this.setState({
      modalAction: action,
      confirm_open: true
    });
  };

  handleClose() {
    this.setState({
      confirm_open: false
    })
  };


  render() {

    return (
      <Grid
        container
        elevation={12}
        className={this.props.cssClasses.passesDisplay}
      >

        {
          this.props.is_broadcast_only &&
          <Grid item xs={12} md={6} className={this.props.cssClasses.passDisplay}>
            <DisplayActivePasses
              activePasses={this.props.activePasses}
              activeSubs={this.props.activeSubs}
              availablePasses={this.props.availablePasses}
              availableSubs={this.props.availableSubs}
              subsProjectNames={this.props.subsProjectNames}
              isBroadcastOnly={this.props.isBroadcastOnly}
              projectId={this.props.projectId}
              cssClasses={this.props.cssClasses}
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              channel={this.props.channel}
              presenceRoom={this.props.presenceRoom}
            />
          </Grid>
        }

        {
          this.props.is_broadcast_only &&
          <Grid item xs={12} md={6} className={this.props.cssClasses.passDisplay}>
            <DisplayScheduledPasses
              scheduledPasses={this.props.scheduledPasses}
              availablePasses={this.props.availablePasses}
              availableSubs={this.props.availableSubs}
              subsProjectNames={this.props.subsProjectNames}
              isBroadcastOnly={this.props.isBroadcastOnly}
              projectId={this.props.projectId}
              cssClasses={this.props.cssClasses}
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
            />
          </Grid>
        }

        {
          !this.props.is_broadcast_only &&
          <Grid item xs={12} md={6} className={this.props.cssClasses.passDisplay}>
            <DisplayActivePasses
              activePasses={this.props.activePasses}
              activeSubs={this.props.activeSubs}
              availablePasses={this.props.availablePasses}
              availableSubs={this.props.availableSubs}
              subsProjectNames={this.props.subsProjectNames}
              isBroadcastOnly={this.props.isBroadcastOnly}
              projectId={this.props.projectId}
              cssClasses={this.props.cssClasses}
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              channel={this.props.channel}
              presenceRoom={this.props.presenceRoom}
            />
          </Grid>
        }

        {
          !this.props.is_broadcast_only &&
          <Grid item xs={12} md={6} className={this.props.cssClasses.passDisplay}>
            <DisplayScheduledPasses
              scheduledPasses={this.props.scheduledPasses}
              availablePasses={this.props.availablePasses}
              availableSubs={this.props.availableSubs}
              subsProjectNames={this.props.subsProjectNames}
              isBroadcastOnly={this.props.isBroadcastOnly}
              projectId={this.props.projectId}
              cssClasses={this.props.cssClasses}
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
            />
          </Grid>
        }

        {
          !this.props.is_broadcast_only &&
          <ManageAccessStepperDialog
            isOpen = {this.state.confirm_open}
            handleClose = {this.handleClose}
            selectedPass = {this.state.selectedAccessPass}
            cssClasses={this.props.cssClasses}
            uuid={this.props.publisher_uuid}
            availablePasses={this.props.availablePasses}
            activePasses={this.props.activePasses}
            scheduledPasses={this.props.scheduledPasses}
            activeSubs={this.props.activeSubs}
            availableSubs={this.props.availableSubs}
            subsProjectNames={this.props.subsProjectNames}
            projectId={this.props.projectId}
            closePassDialog={this.props.closePassDialog}
            modalAction={this.state.modalAction}
            isPartner={this.props.isPartner}
          />
        }

      </Grid>
    );
  }
}

export default withRouter((withSnackbar(InviteViewer)))
