import { ReactNode, useCallback, useState, useMemo, useEffect } from "react";
import Debug from "debug"; const debug = Debug("SS:VideoChat:ToggleAudioEnabled");
import {
  Tooltip,
  Button,
  IconButton,
  Alert,
  CircularProgress,
  Typography,
} from "@mui/material";

import useCamera from "../../../Context/CameraProvider/useCamera";
import { Mic, MicOff, ReportOff } from "@mui/icons-material";
import { DevicePermission } from "../../../Context/CameraProvider/Hooks/useMediaDevices";
import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";

export default function ToggleAudioEnabled({
  variant = "button",
}: {
  variant?: "icon" | "button";
}) {

  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  
  const {
    withAudio,
    setWithAudio
  } = useVideoChat()
  
  const { 
    audioTrack,
    audioInputPermission, 
    gettingAudioMedia, 
    gettingDevices 
  } = useCamera();

  // Effects
  useEffect(() => {
    if (!withAudio) {
      setIsEnabled(false)
    }
    else if (audioTrack && audioTrack.readyState === "live") {
      setIsEnabled(audioTrack.enabled)
    }
  }, [audioTrack, withAudio])

  // Callbacks
  const toggleAudio = useCallback(() => {
    debug("Audio Enabled? ", audioTrack?.enabled)
    if (!withAudio) {
      setWithAudio(true)
    }
    else if (audioTrack && audioTrack.readyState === "live") {
      audioTrack.enabled = !audioTrack.enabled
      setIsEnabled(audioTrack.enabled)
      debug("Audio Enabled updated?", audioTrack.enabled)
    }
  }, [audioTrack]);

  // Components
  const icon = useMemo(() => {
    if (gettingDevices || gettingAudioMedia) {
      const color = variant === "button" ? "inherit" : "primary";

      return (
        <CircularProgress variant="indeterminate" size="18px" color={color} />
      );
    }

    if (audioInputPermission === DevicePermission.allowed) {
      if (isEnabled) {
        return <Mic />;
      } else {
        return <MicOff color="error" />;
      }
    }

    return <ReportOff color="error" />;
  }, [
    audioInputPermission,
    isEnabled,
    gettingAudioMedia,
    gettingDevices,
    variant,
  ]);

  const button = useMemo(() => {
    let disabled = false;
    if (
      audioInputPermission !== DevicePermission.allowed ||
      gettingAudioMedia ||
      gettingDevices
    ) {
      disabled = true;
    }

    switch (variant) {
      case "icon":
        return (
          <IconButton
            color={isEnabled ? "primary" : "inherit"}
            onClick={toggleAudio}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        );

      case "button":
      default:
        return (
          <Button
            color={isEnabled ? "primary" : "inherit"}
            onClick={toggleAudio}
            disabled={disabled}
            variant="contained"
          >
            {icon}
          </Button>
        );
    }
  }, [
    audioInputPermission,
    isEnabled,
    gettingAudioMedia,
    gettingDevices,
    variant,
  ]);

  const renderer = useMemo(() => {
    let title = (
      <Typography>
        "Toggle Audio Enable"
      </Typography>
    )
    if (audioInputPermission !== DevicePermission.allowed) {
      title = (
        <Alert severity="error" icon={<ReportOff color="error" />}>
          Audio Permission Denied. Please check your browser settings.
        </Alert>
      )
    }

    return (
      <Tooltip
        title={title}
        placement={variant === "button" ? "top" : "right-start"}
      >
        <span>{button}</span>
      </Tooltip>
    );
  }, [audioInputPermission, icon, variant]);

  return renderer;
}
