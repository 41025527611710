import Debug from "debug";
const debug = Debug("SS:Chat");
import { Component } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { withSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Cookies from "js-cookie";
import ChatInput from "./chat_input";
import ChatBubble from "./chat_bubble";
import ToggleTextChatButton from "./ToggleTextChatButton/ToggleTextChatButton";

const axios = require("axios").default;

// Chat Component
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: "",
      role: props.role,
      status: "Welcome!!",
      channelId: props.room,
      channel: props.channel,
      members: [
        {
          // (members[0] is always self)
          uuid: props.uuid,
          room: props.room,
          nickName: props.nickName,
          color: props.color,
        },
      ],
      newMessage: [],
      // chatOpen: false,
      withActiveSpeaker: true,
      unreadChats: 0,
      notify_is_allowed: false,
    };

    this.updateMessageState = this.updateMessageState.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.openChat = this.openChat.bind(this);
  }

  setWithActiveSpeaker(withSpeaker) {
    this.setState({
      withActiveSpeaker: withSpeaker,
    });
  }

  listenForChats() {
    // On Success
    this.props.channel.bind("pusher:subscription_succeeded", (success) => {
      // console.log('Chat subscribed, update history ');
      // Get missed messages
      this.getChatHistory();
    });

    // Receive chats
    this.props.channel.bind("chat", (message) => {
      if (typeof message === "object") {
        let toPush = this.state.newMessage;
        toPush.push({
          nickName: message.nickName,
          color: message.color,
          message: message.message,
          uuid: message.userId,
          userId: message.userId,
          timestamp: message.timestamp,
        });
        this.setState({
          newMessage: toPush,
        });

        // Scroll to the bottom of the chat
        // let listElement = document.getElementById("scrollBox");
        // if (listElement) {
        //   listElement.scrollTop = listElement.scrollHeight + 100;
        // } else {
        //   // console.log('DoNT ScROLL ', listElement);
        // }

        // Update unread count if not my message
        if (message.uuid !== this.props.uuid) {
          this.setState({ unreadChats: this.state.unreadChats + 1 });
        }

        // Don't show notification if this is your message
        if (message.uuid !== this.props.uuid && window.Notification) {
          // console.log('Trying notification ', message.uuid, this.props.uuid);
          try {
            new Notification("New message from " + message.nickName, {
              body: message.message,
              icon: "https://setstream.io/wp-content/uploads/2020/07/setstream_brand_Icon_512_01.png",
              badge:
                "https://setstream.io/wp-content/uploads/2020/07/setstream_brand_logo_color_512_01.png",
            });
          } catch (e) {
            console.log("Could not set notification ", e);
          }
        }
      } else {
        console.log(
          "CHAT Invalid message type ",
          typeof message,
          message,
          this.props.uuid
        );
      }
    });

    // Update user info
    this.props.channel.bind("update_user_info", (message) => {
      debug("Updating user info", message);
      if (!message.userId) {
        return;
      }
      const newMessages = this.state.newMessage.map((mess) => {
        if (mess.userId === message.userId) {
          const newMes = mess;
          if (message.nickName) {
            newMes.nickName = message.nickName;
          }
          if (message.color) {
            newMes.color = message.color;
          }
          return newMes;
        } else {
          return mess;
        }
      });
      this.setState({
        newMessage: newMessages,
      });
    });

    // Update chat list when history is deleted
    this.props.channel.bind("chat_history_deleted", (message) => {
      console.log("Deleting Chats ", message);
      this.getChatHistory();
    });
  }

  // Update state
  updateMessageState(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  sendChat(target, source, message) {
    debug("Sending chat", message)
    
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/pusher/chat/" +
          this.state.channelId +
          "/" +
          this.props.authUuid,
        message,
        { withCredentials: true }
      )
      .then((result) => {
        switch (result.data.type) {
          case "success":
            // console.log('Chat sent ', result.data.message);
            break;
          case "logged_out":
            this.props.enqueueSnackbar(
              "Looks like you are logged out.  Please log in again.",
              { variant: "info" }
            );
            console.log("Logged out ", result.data.message);
            window.location.reload(false);
            break;
          case "unauthorized":
            Cookies.remove(this.state.subscriber_uuid, {
              domain: process.env.REACT_APP_DOMAIN,
              path: "/",
            });
            this.props.enqueueSnackbar(
              "Access has been removed.  If you think this is an error please contact your project manager.",
              { variant: "warning" }
            );
            console.log("Access denied");
            // window.location.reload(false);
            break;
          case "error":
            console.error("Could not store chat in history");
            break;
          default:
          // Nothing happens
        }
      })
      .catch((err) => {
        // this.props.enqueueSnackbar('There was an error sending your message', { variant: 'warning' });
        console.error("Error sending chat- ", err);
      });
  }

  sendMessage(event) {
    event.preventDefault();
    if (
      event.target &&
      event.target[0].value &&
      event.target[0].value.length > 0
    ) {
      // Ask for a nickName if we don't already have it
      let nickName = this.props.nickName;
      if (!nickName) {
        nickName = window.prompt("Please identify yourself for others");
        if (nickName && nickName !== "") {
          localStorage.setItem("ss_nickname", nickName);
          this.props.updateNickName(nickName);
        }
      }
      let now = new Date();
      let constructed = {
        uuid: this.props.uuid,
        userId: this.props.uuid,
        nickName: nickName,
        color: this.props.color,
        message: event.target[0].value,
        timestamp: now,
      };
      this.sendChat("All", this.props.uuid, constructed);
      this.setState({
        inputText: "",
      });
    }

    // // Scroll to the bottom of the chat
    // let listElement = document.getElementById("scrollBox");
    // if (listElement) {
    //   listElement.scrollTop = listElement.scrollHeight + 100;
    // }
  }

  openChat() {
    if (!this.props.isChatOpen) {
      this.setState({
        unreadChats: 0
      })
    }
    this.props.setIsChatOpen();
    // let listElement = document.getElementById("scrollBox");
    // if (listElement) {
    //   listElement.scrollTop = listElement.scrollHeight + 100;
    // }
  }

  // Fix for Safari using callbacks instead of promises for Notification permissions
  checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  }

  // Download Chat history
  getChatHistory() {
    debug('Getting chat history ', this.state.channelId);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/chat_history/" +
          this.props.authUuid,
        { withCredentials: true }
      )
      .then((chats) => {
        debug('Received chat history ', chats.data);

        this.setState({
          newMessage: chats.data.chatMessages,
          status: chats.data.status,
        });
        // let listElement = document.getElementById("scrollBox");
        // if (listElement) {
        //   listElement.scrollTop = listElement.scrollHeight + 100;
        // }
      })
      .catch((err) => {
        console.error("Chat API Error - ", err);
      });
  }

  // // Close chat drawer
  // closeChatDrawer() {
  //   this.props.setIsChatOpen();
  //   this.setState({
  //     unreadChats: 0,
  //   });
  // }

  // Lifecycle
  componentDidMount() {
    this.getChatHistory();
    this.listenForChats();

    // Get permission for notifications
    if (
      window.Notification &&
      Notification.permission &&
      typeof Notification.requestPermission === "function"
    ) {
      switch (Notification.permission) {
        case "granted":
          this.setState({
            notify_is_allowed: true,
          });
          // console.log('Notifications permission granted');
          break;
        case "denied":
          console.log("Notifications permission denied");
          break;
        case "default":
          if (typeof Notification.requestPermission === "function") {
            if (this.checkNotificationPromise()) {
              Notification.requestPermission()
                .then((permission) => {
                  if (permission === "granted") {
                    this.setState({
                      notify_is_allowed: true,
                    });
                  }
                })
                .catch((err) => {
                  console.error(
                    "Error - Could not get permission for notifications",
                    err
                  );
                });
            } else {
              Notification.requestPermission((permission) => {
                if (permission === "granted") {
                  this.setState({
                    notify_is_allowed: true,
                  });
                }
              });
            }
          }
      }
    }

    // // To Scroll, or not to scroll ???
    // let listElement = document.getElementById("scrollBox");
    // if (listElement) {
    //   listElement.scrollTop = listElement.scrollHeight + 100;
    // }
  }

  componentDidUpdate() {
    // This could potentially be annoying for the user
    // Everytime a new text comes in it will scroll
    // If a user is reading a text farther up the list then it will 
    // remove that from their view
    // With a lot of messages happening at the same time it could be 
    // hard to read old messages.
    let listElement = document.getElementById("scrollBox");
    if (listElement) {
      listElement.scrollTop = listElement.scrollHeight + 100;
    }

    // Auto clear unread text count if the drawer is open
    if (this.props.isChatOpen && this.state.unreadChats !== 0) {
      this.setState({
        unreadChats: 0
      })
    }
  }

  render() {
    return (
      <Grid
        sx={{
          width: {
            xs: this.props.isChatOpen ? "100%" : "0px",
            sm: this.props.isChatOpen ? "302px" : "39px",
          },
          maxWidth: {
            xs: this.props.isChatOpen ? "100%" : "0px",
            sm: this.props.isChatOpen ? "302px" : "39px",
          },
          height: {
            xs: this.props.isChatOpen ? "calc(100vh - (100vw * 9/16))" : "0px",
            sm: "100%",
          },
          overflow: "hidden",
          borderLeft: "1px solid",
          borderColor: "secondary.dark",
          borderRadius: "8px 0px 0px 8px",
          marginLeft: {
            xs: 0,
            sm: 2,
          },
          backgroundColor: {
            xs: "shades.superDark",
            sm: "background.none",
          },
          position: {
            xs: "fixed",
            sm: "static",
          },
          bottom: {
            xs: 0,
            sm: "auto",
          },
          left: {
            xs: 0,
            sm: "auto",
          },
          zIndex: {
            xs: 1001,
            sm: "auto",
          },
          padding: {
            xs: this.props.isChatOpen ? 8 : 0,
            sm: 0,
          },
        }}
        id="TextChatColumn"
      >
        <Grid
          container
          direction="column"
          id="communications"
          sx={{
            height: "100%",
          }}
        >
          {/* Status */}
          <Grid
            xs={12}
            sx={{
              height: "82px",
              minHeight: "82px",
              maxHeight: "82px",
              margin: 0,
              flex: 0,
              backgroundColor: "background.paper",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              sx={{
                height: "100%",
              }}
            >
              <Grid
                sx={{
                  width: "38px",
                  minWidth: "38px",
                  maxWidth: "38px",
                  flex: 0,
                  marginTop: {
                    xs: "auto",
                    sm: 0,
                  },
                  position: {
                    xs: "fixed",
                    sm: "static",
                  },
                  bottom: {
                    xs: this.props.isChatOpen
                      ? "calc(100vh - (100vw * 9/16) - 38px)"
                      : 2,
                    sm: "auto",
                  },
                  right: {
                    xs: 2,
                    sm: "auto",
                  },
                }}
              >
                <ToggleTextChatButton
                  unreadChats={this.state.unreadChats}
                  isChatOpen={this.props.isChatOpen}
                  openChat={this.openChat}
                />
              </Grid>

              {this.props.isChatOpen && (
                <Grid
                  sx={{
                    flex: 1,
                  }}
                >
                  {this.props.children}
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Messages */}
          {this.props.isChatOpen && (
            <Grid
              xs={12}
              id="scrollBox"
              sx={{
                // height: "100%",
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
                scrollBehavior: "smooth",
                padding: 2,
                transition: "height 300ms",
              }}
            >
              <Grid container direction="column" justifyContent="flex-end">
                {this.state.newMessage.map((message, i) => (
                  <ChatBubble
                    key={"CB" + i}
                    message={message}
                    isLocal={message.uuid === this.props.uuid}
                  />
                ))}
              </Grid>
            </Grid>
          )}

          {/* Input */}
          {this.props.isChatOpen && (
            <Grid
              sx={{
                flex: 0,
              }}
            >
              <ChatInput onSend={this.sendMessage} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(Chat);
// export default withStyles(useStyles)(withSnackbar(Chat))
