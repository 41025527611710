import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
TextField,
Typography,
Button,
Dialog,
DialogTitle,
DialogContentText,
DialogContent,
DialogActions,
Backdrop,
CircularProgress,
} from '@mui/material/';
import EmailListItem from './email_list_item';
import {useSnackbar} from 'notistack';
import isValidEmail from '../../../utils/isValidEmail';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
  item: {
    margin: 'auto',
    padding: theme.spacing(2)
  },
  verticalButtons: {
    margin: theme.spacing(2),
  },
  dtitle: {
    margin: 'auto',
    borderRadius: theme.spacing(2),
  },
  dcontent: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6),
    margin: theme.spacing(6)
  },
  dactions: {
    borderRadius: theme.spacing(2)
  },
}));

const emailDelineators = /\r\n|\r|\n|,| |mailto:/
const emailFilters = [' ', '']

export default function InviteViewers(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [emailList, setEmailList] = useState('');
  const [emailArray, setEmailArray] = useState([]);
  const [allowInvites, setAllowInvites] = useState(false);
  const [canResendAllInvites, setCanResendAllInvites] = useState(false);
  const [message, setMessage] = useState('');
  const [showInviteList, setShowInviteList] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  // Create a filtered array from input for display and processing
  useEffect(() => {
    let listArray = emailList
      .split(emailDelineators);
    setEmailArray(listArray.filter(item => emailFilters.indexOf(item) === -1));
  }, [emailList]);

  useEffect(() => {
    if (emailArray.filter(email => !isValidEmail(email)).length > 0) {
      setAllowInvites(false);
    } else {
      setAllowInvites(true);
    }
  }, [emailArray]);

  useEffect(() => {
    if (props.authorized_users?.length > 0) {
      setCanResendAllInvites(true);
    } else {
      setCanResendAllInvites(false)
    };
  }, [props.authorized_users]);

  // Actions
  const updateEmail = (index, newValue) => {
    let newEmailArray = emailArray;
    newEmailArray[index] = newValue;
    setEmailList(newEmailArray.join('\n'));
  }

  const removeEmail = (index) => {
    let newEmailArray = emailArray.filter((item, i) => (i != index));
    setEmailList(newEmailArray.join('\n'));
  }

  // API Calls
  const inviteViewers = async () => {
    setInProgress(true);

    try {
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          emailsToInvite: emailArray,
          customMessage: message,
        })
      }

      let response = await fetch(process.env.REACT_APP_API_URL + '/api/invite_viewers/' + props.publisher_uuid, fetchData)
      if (response.ok) {
        let result = await response.json();

        if (
          result.type.toLowerCase() === 'success' &&
          result.emailsSent &&
          result.emailsSent.length > 0
        ) {
          let message = 'Viewers Invited: ';

          result.emailsSent.forEach((email, i) => {
            message += email + ', '
          });
          enqueueSnackbar(message, { variant: 'info' });

          props.getAuthUsers(); // Update viewer list
        } else {
          // Errors inviting viewers
          if (result.emailsErrored && result.emailsErrored.length > 0) {
            let message = 'There was an error with the following emails: ';

            result.emailsErrored.forEach((email, i) => {
              message += email + ', '
            });

            enqueueSnackbar(message, { variant: 'warning' });
          }

          props.getAuthUsers(); // Update viewer list
        }

        // Notify about viewers that already have access
        if (result.emailsAlreadyInvited && result.emailsAlreadyInvited.length > 0) {
          let message = '';

          result.emailsAlreadyInvited.forEach((email, i) => {
            message += email + ', '
          });
          message += ' already has access.'

          enqueueSnackbar(message, { variant: 'warning' });
        }
      } else {
        throw new Error('Could Not Invite Viewers')
      }
    } catch (e) {
      console.error('Error fetching ', e);
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setEmailList('');
      setMessage('');
      setInProgress(false);
      setShowInviteList(false);
    }
  }

  const resendAllInvites = async () => {
    setCanResendAllInvites(false);

    try {
      let fetchData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({publisher_uuid: props.publisher_uuid})
      }

      let response = await fetch(process.env.REACT_APP_API_URL + '/api/resend_all_invites/' + props.publisher_uuid, fetchData);

      let result = await response.json();

      if (response.ok && result.type.toLowerCase() === 'success') {
        enqueueSnackbar(result.message, { variant: 'info' })
      } else {
        enqueueSnackbar(result.message, { variant: 'warning' })
      }
    } catch (e) {
      console.error('Could not resend invites - network error ', e);
      enqueueSnackbar('We encountered a network error - ' + e.message, { variant: 'error' })
    } finally {
      setCanResendAllInvites(props.authorized_users?.length > 0);
    }
  }

  // const createAnonViewer = async () => {
  //   let fetchData = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     credentials: "include",
  //     body: JSON.stringify({publisher_uuid: props.publisher_uuid})
  //   }
  //
  //   let response = await fetch(process.env.REACT_APP_API_URL + '/api/create_anonymous_viewer/' + props.publisher_uuid, fetchData);
  //
  //   let result = await response.json();
  //
  //   if (response.ok && result.type.toLowerCase() === 'success') {
  //     enqueueSnackbar(result.message, { variant: 'warning' });
  //     props.getAuthUsers(); // Update viewer list
  //   } else {
  //     enqueueSnackbar(result.message, { variant: 'warning' });
  //     props.getAuthUsers(); // Update viewer list
  //   }
  // }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} sm={4} align="center" className={classes.item}>
        <TextField
          multiline
          name="email_list"
          label="Invite Viewers"
          fullWidth
          value={emailList}
          onChange={e => setEmailList(e.target.value)}
          variant="outlined"
          minRows={5}
          maxRows={5}
        />
      </Grid>

      <Grid item xs={6} sm={5} align="center" className={classes.item}>
        <TextField
          multiline
          name="message"
          label="Send a message"
          fullWidth
          value={message}
          onChange={e => setMessage(e.target.value)}
          variant="outlined"
          minRows={5}
          maxRows={5}
        />
      </Grid>

      <Grid item xs={12} sm={3} align="center" className={classes.item}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowInviteList(true)}
          className={classes.verticalButtons}
        >
          Invite Viewers
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={resendAllInvites}
          disabled={canResendAllInvites}
          className={classes.verticalButtons}
        >
          Resend All Invites
        </Button>

        {/* Disable link creation
        <Button
          variant="contained"
          color="primary"
          className={classes.verticalButtons}
          onClick={createAnonViewer}
        >
          Create Link
        </Button> */}
      </Grid>

      <Dialog
        open={showInviteList}
        onClose={e => setShowInviteList(false)}
        aria-labelledby="review-emails-to-invite"
        aria-describedby="Review emails to invite"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          className={classes.dtitle}
        >
          Invite Viewers
        </DialogTitle>
        <DialogContent
          className={classes.dcontent}
        >
          <Typography align="center" className={props.cssClasses.sectionTitle}>
            Please review the list of emails.
          </Typography>

          {
            emailArray.map((item, i) => (
              <EmailListItem
                email={item}
                index={i}
                updateEmail={updateEmail}
                removeEmail={removeEmail}
              />
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button
            id = 'cancel'
            onClick = {e => setShowInviteList(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            id = 'invite'
            onClick = {inviteViewers}
            variant="contained"
            color="primary"
            disabled={!allowInvites}
          >
            {allowInvites ? 'Invite Viewers' : 'Invalid Emails'}
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>

    </Grid>
  );

}
