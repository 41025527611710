import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from '@mui/material/';
import SubscriptionSelect from '../subscription_select';

const useStyles = makeStyles((theme) => createStyles({
  selectButton: {
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: theme.spacing(2),
  },
  tab: {
    borderColor: theme.palette.primary.main,
    borderBottom: 'solid 4px',
    borderRadius: '8px',
  }
}));

export default function PurchaseSubscriptionDialog(props) {
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(false);

  // Close the modal
  const handleClose = () => {
    props.handleClose();
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
    >

      <DialogTitle
        className={props.cssClasses.dtitle}
      >
        Subscribe
      </DialogTitle>

      <DialogContent
        className={props.cssClasses.dcontent}
      >
        <SubscriptionSelect
          {...props}
          handleClose={handleClose}
        />
      </DialogContent>

      <DialogActions
        className={props.cssClasses.dactions}
      >
        <Button
          id = 'Close'
          onClick = {handleClose}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>

      <Backdrop open={inProgress} style={{zIndex:2001}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>

    </Dialog>
  );

}
