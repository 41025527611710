import Debug from "debug"; const debug = Debug("SS:VideoChat:Peer:Controls");
import { useMemo, useState, useEffect } from "react";

import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import PeerName from "../PeerName/PeerName";
import { SourceIdItem } from "../../Context/VideoChatProvider/Reducers/SourceIdReducer";
import useAudioTracks from "../../Context/CameraProvider/Hooks/useAudioTracks";
import useIsPeerMe from "../../Context/VideoChatProvider/Hooks/useIsPeerMe";
import { useMillicast } from "../../Context/MillicastProvider/MillicastProvider";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";


export default function PeerControls({ peer }: { peer: SourceIdItem }) {
  const [hasAudio, setHasAudio] = useState<boolean>(false)
  const { chatCollapsed } = useVideoChat()
  const { view } = useMillicast()

  const audioTracks = useAudioTracks(peer.mediaStream)
  const isMe = useIsPeerMe(peer)

  useEffect(() => {
    if (audioTracks && audioTracks.length > 0) {
      setHasAudio(true)
    } else {
      setHasAudio(false)
    }
  }, [audioTracks])

  const icon = useMemo(() => {
    if (isMe) {
      return;
    }
    if (view && !view.isActive()) {
      return;
    }

    return
    // TODO: We need a more reliable way to determine with audio
    // Maybe check the mediaStreamTrack.enabled ??
    // Icons are disabled for now
    // if (hasAudio) {
    //   return (<MicIcon color="primary" />)
    // } else {
    //   return (<MicOffIcon color="error" />)
    // }
  }, [hasAudio, isMe, view]);

  const renderer = useMemo(() => {
    return (
      <Grid
        container
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          borderRadius: "8px 8px 0px 0px",
        }}
      >

        <Grid sx={{ flex: 1 }}>
          <PeerName peer={peer} />
        </Grid>

      </Grid>
    );
  }, [chatCollapsed, icon])

  return renderer;
}
