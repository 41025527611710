import { ReactNode } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMediaQuery } from "@mui/material";

/**
 * This component should always be used inside an AppBox
 * Enclose children in <Grid> from Grid2
 * @returns
 */
export default function AppContentBox({ children }: { children: ReactNode }) {
  const isLandscape = useMediaQuery("(orientation:landscape)")

  return (
    <Grid
      sx={{
        width: "100%",
        flex: 1,
        flexWrap: "nowrap",
        overflow: "hidden",
        marginTop: 2,
        transition: "height 300ms",
      }}
      id="AppContentBox"
    >
      <Grid
        container
        sx={{
          width: "100%",
          height: "100%",
          flexDirection: {
            xs: isLandscape ? "row" : "column",
            md: "column"
          },
          flexWrap: "nowrap",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}
