export default function logToServer(logData) {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(
      process.env.REACT_APP_API_URL + '/api/errors', 
      JSON.stringify({
        page: window.location.pathname,
        ...logData
      }))
  }
}
