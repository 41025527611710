export interface VideoQuality {
  id: number,
  label: string,
  bitrate: number,
  width: number,
  resolution: number,
}

export const videoQualities: VideoQuality[] = [
  {
    id: 0,
    label: 'low',
    bitrate: 750,
    width: 960,
    resolution: 540,
  },
  {
    id: 1,
    label: 'Medium',
    bitrate: 1000,
    width: 960,
    resolution: 540
  },
  {
    id: 2,
    label: 'High',
    bitrate: 1500,
    width: 1280,
    resolution: 720
  },
  {
    id: 3,
    label: 'Great',
    bitrate: 2000,
    width: 1920,
    resolution: 1080
  }
]
