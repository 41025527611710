import { Typography } from "@mui/material";
import usePeerColor from "../../Context/VideoChatProvider/Hooks/usePeerColor";
import usePeerName from "../../Context/VideoChatProvider/Hooks/usePeerName";
import { SourceIdItem } from "../../Context/VideoChatProvider/Reducers/SourceIdReducer";

export default function PeerName({ peer }: { peer: SourceIdItem }) {
  const peerColor = usePeerColor(peer)
  const peerName = usePeerName(peer)

  return (
    <Typography
      sx={{
        fontSize: 9,
        paddingTop: 1,
        color: peerColor,
        width: "100%"
      }}
    >
      {peerName}
    </Typography>
  );
}
