import { createTheme } from '@mui/material';

export default function useDarkTheme() {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        dark: '#141517',
        default: '#141517',
        main: '#141517',
        paper: '#1F2125',
        subtle: '#1C1E22',
        light: '#2D3138',
        none: "rgba(0,0,0,0)"
      },
      primary: {
        main: '#238e4d',
        dark: '#134e2a',
        light: '#47b969',
      },
      secondary: {
        main: '#1f6989',
        dark: '#28364a',
        light: '#73ddff',
      },
      info: {
        main: '#e4f265',
        light: '#eefc6f',
        dark: '#c4d157'
      },
      success: {
        main: '#57a5c6',
        light: '#6fd2fc',
        dark: '#3f7a93'
      },
      error: {
        main: '#d32f2f',
      },
      warning: {
        main: '#ffc107',
      },
      cameras: {
        A: 'rgba(226, 29, 29, .7)',
        B: 'rgba(50, 171, 223, .7)',
        C: 'rgba(226, 226, 29, .7)',
        D: 'rgba(71, 185, 105, .7)'
      },
      text: {
        primary: '#F0F0F0',
        secondary: 'rgba(255, 255, 255, 0.6)',
        disabled: 'rgba(255, 255, 255, 0.4)',
        dark: '#111111'
      },
      chat: {
        local: "rgb(42,45,57)",
        remote: "rgb(54,56,52)",
      },
      shades: {
        extraLight: 'rgba(225, 225, 225, 1)',
        light: 'rgba(255, 255, 255, 0.85)',
        medium: 'rgba(255, 255, 255, 0.7)',
        dark: 'rgba(255, 255, 255, 0.4)',
        superDark: 'rgba(0,0,0,0.8)'
      },
      solids: {
        extraLight: 'rgba(245, 245, 245, 1)',
        light: 'rgba(175, 175, 175, 1)',
        medium: 'rgba(70, 70, 70, 1)',
        dark: 'rgba(40, 40, 40, 1)',
        superDark: 'rgba(12, 12, 12, 1)'
      },
      divider: 'rgba(255, 255, 255, .25)',
    },
    typography: {
      fontFamily: [
        'Verdana',
        'Roboto',
        '-apple-default'
      ].join(","),
      fontSize: 11,
      color: '#F0F0F0'
    },
    spacing: 2,
    components: {
      root: {
        styleOverrides: {
          display: 'flex',
          flex: 1
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            letterSpacing: '1px'
          }
        },
        defaultProps: {
          variant: 'contained'
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(31, 105, 137, 1)',
          },
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#1f2125",
            backgroundImage: "none"
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
            padding: '16px',
            border: '2px solid #172944'
          }
        }
      },
      MuiStepLabel: {
        styleOverrides: {
          vertical: {
            width: 'fit-content',
            margin: 'auto',
            padding: '8px 0'
          }
        }
      },
      MuiStepConnector: {
        styleOverrides: {
          vertical: {
            margin: 'auto',
            marginLeft: 'auto' // needed for margin:auto override to work
          }
        }
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom : 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
          }
        }
      },
      MuiFormControlLabel: { // Override positioning of permissions switches
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0
          }
        }
      },
    }
  });

  return theme;
}
