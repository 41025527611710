interface position {
    x: number,
    y: number
}

export default function drawRoundRect(canvasContext:CanvasRenderingContext2D, a:position, b:position, scaleX?:number, scaleY?:number) {
  // Defaults
  if (!scaleX) {scaleX = 1}
  if (!scaleY) {scaleY = 1}
  let radius = 10;

  let top: number,
    bottom: number,
    left: number,
    right: number;

  // Top
  if (a.x < b.x) {
    left = a.x * scaleX
    right = b.x * scaleX
  } else {
    left = b.x * scaleX
    right = a.x * scaleX
  }
  // Bottom
  if (a.y < b.y) {
    top = a.y * scaleY
    bottom = b.y * scaleY
  } else {
    top = b.y * scaleY
    bottom = a.y * scaleY
  }

  // Adjust radius for small boxes
  if (
    (bottom - top) < radius ||
    (right - left) < radius
  ) {
    radius = bottom - top < right - left ? (bottom - top) / 2 : (right - left) / 2
  }

  // Draw
  canvasContext.moveTo(
    left + radius,
    top,
  )
  canvasContext.lineTo(
    right - radius,
    top
  )
  canvasContext.arcTo(
    right,
    top,
    right,
    top + radius,
    radius
  )
  canvasContext.lineTo(
    right,
    bottom - radius,
  )
  canvasContext.arcTo(
    right,
    bottom,
    right - radius,
    bottom,
    radius
  )
  canvasContext.lineTo(
    left + radius,
    bottom,
  )
  canvasContext.arcTo(
    left,
    bottom,
    left,
    bottom - radius,
    radius
  )
  canvasContext.lineTo(
    left,
    top + radius
  )
  canvasContext.arcTo(
    left,
    top,
    left + radius,
    top,
    radius
  )
  canvasContext.stroke()
}
