import { useRef, useEffect, useMemo } from "react";
import { FaUser } from "react-icons/fa";
import Debug from "debug"; const debug = Debug("SS:VideoChat:PeerVideo");

import { Card, CardContent, CardMedia, Skeleton } from "@mui/material";
import { SourceIdItem } from "../../Context/VideoChatProvider/Reducers/SourceIdReducer";
import usePeerColor from "../../Context/VideoChatProvider/Hooks/usePeerColor";
import useEvents from "../../Context/Events/useEvents";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import useIsPeerMe from "../../Context/VideoChatProvider/Hooks/useIsPeerMe";
import PeerAvatar from "../PeerAvatar/PeerAvatar";
import useCamera from "../../Context/CameraProvider/useCamera";

export default function PeerVideo({ peer }: { peer: SourceIdItem }) {
  const ref = useRef<HTMLVideoElement>(null);

  const { 
    activeSpeaker,
    withVideo,
    isVideoEnabled
   } = useVideoChat()
   const { videoTrack } = useCamera()
  const isMe = useIsPeerMe(peer)
  const {
    dispatch
  } = useEvents()

  // Display remote peers
  useEffect(() => {
    if (isMe) { return }
    if (!peer) { return }
    if (!peer.mediaStream) { return }
    const tracks = peer.mediaStream.getVideoTracks();
    debug("Updating peer video", tracks, peer.mediaStream.active)
    if (ref.current && tracks.length > 0) {
      ref.current.srcObject = peer.mediaStream;
    } else if (ref.current) {
      ref.current.srcObject = null;
    }
    return () => {
      if (ref.current) {
        ref.current.srcObject = null;
      }
    };
  }, [peer, isMe]);
  
  useEffect(() => {
    if (!isMe) { return }
    debug("Updating peer video", videoTrack, isVideoEnabled)
    if (videoTrack && isVideoEnabled && ref.current) {
      ref.current.srcObject = new MediaStream([videoTrack])
    } else if (!videoTrack && ref.current) {
      ref.current.srcObject = null;
    }

    return () => {
      if (ref.current) {
        ref.current.srcObject = null;
      }
    }
  }, [videoTrack, isMe, isVideoEnabled]);

  useEffect(() => {
    const handleNewTrack = (track: MediaStreamTrack) => {
      debug("New Track", track, ref.current, peer.mediaStream.getVideoTracks())
      if (track && ref.current) {
        ref.current.srcObject = peer.mediaStream
      } else if (ref.current) {
        ref.current.srcObject = null;
      }
    }
  
    dispatch?.on("newTrack", handleNewTrack)
    return () => {
      dispatch?.off("newTrack", handleNewTrack)
    }
  }, [dispatch, peer])

  // Disable video tracks when they are off screen to save resources
  // Currently this disables our local mediastram too 
  // so all parties see black
  // useEffect(() => {
  //   if (
  //     !isMe &&
  //     peer && 
  //     peer.mediaStream 
  //   ) {
  //     const tracks = peer.mediaStream.getVideoTracks()
  //     if (
  //       tracks &&
  //       tracks.length > 0 &&
  //       activeSpeaker &&
  //       peer.sourceId === activeSpeaker
  //     ) {
  //       tracks[0].enabled = true
  //       debug("Enabling peer video", peer.sourceId, tracks)
  //     } else {
  //       tracks[0].enabled = false
  //       debug("Disabling peer video", peer.sourceId, tracks)
  //     }
  //   }
  // }, [activeSpeaker, peer, isMe])

  const renderer = useMemo(() => {
    let showVideo = false
    if (isMe) {
      if (withVideo && isVideoEnabled) { showVideo = true }
    } else {
      if (
        peer && 
        peer.mediaStream &&
        peer.mediaStream.active 
      ) { showVideo = true }
    }

    if (showVideo) {
      return (
        <CardMedia
          component="video"
          ref={ref}
          autoPlay={true}
          playsInline
          loop={true}
          controls={false}
          muted={true}
          sx={{
            height: 1,
            width: "auto",
          }}
        />
      )
    } else {
      return (
        <CardContent>
          <PeerAvatar />
        </CardContent>
      )
    }
  }, [peer, withVideo, isMe, isVideoEnabled])

  return (
    <Card
      elevation={4}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        backgroundColor: "background.paper",
        border: "1px solid",
        borderColor: (!isMe && activeSpeaker === peer.sourceId) ? "primary.main" : "background.dark",
      }}
    >
      {renderer}
    </Card>
  );
}
