import Debug from "debug";
const debug = Debug("SS:VideoChat:Provider");
import React, {
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useReducer,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import useVideoChatToken from "./Hooks/useVideoChatToken";
import SourceIdReducer, {
  SourceIdItem,
  SourceIdReducerAction,
} from "./Reducers/SourceIdReducer";
import { Members, PresenceChannel } from "pusher-js";

export enum ConnectionStatus {
  Disconnected,
  Loaded,
  Connecting,
  Connected,
  Disconnecting,
  Errored,
}

interface Props {
  publisherUuid?: string;
  userUuid: string;
  displayName: string;
  updateDisplayName: (nickName: string) => void;
  chatCollapsed: boolean;
  toggleChatCollapsed: () => void;
  members: Members;
  presenceRoom: PresenceChannel;
  children: ReactNode;
}

export const VideoChatContext = React.createContext<Context>(null!);

export function VideoChatProvider({
  publisherUuid,
  userUuid,
  displayName,
  updateDisplayName,
  chatCollapsed,
  toggleChatCollapsed,
  members,
  presenceRoom,
  children,
}: Props) {
  // Permissions
  const [isActive, setIsActive] = useState<boolean>(false);
  const [withAudio, setWithAudio] = useState<boolean>(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(true)
  const [withVideo, setWithVideo] = useState<boolean>(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true)


  // Connection Status
  const [pubConnectionStatus, setPubConnectionStatus] = useState<RTCPeerConnectionState>("new");
    const [pubReconnecting, setPubReconnecting] = useState<boolean>(false)
    const [subConnectionStatus, setSubConnectionStatus] = useState<RTCPeerConnectionState>("new");
    const [subReconnecting, setSubReconnecting] = useState<boolean>(false)

  const [sourceIdList, updateSourceIdList] = useReducer(
    SourceIdReducer,
    new Map<string, SourceIdItem>()
  );
  const vadList = useRef<Map<string, number>>(new Map());
  const [activeSpeaker, setActiveSpeaker] = useState<string>();

  const {
    accountId,
    streamName,
    pubToken,
    pubTokenId,
    subToken,
    subTokenId,
    canConnect,
    loadingAccess,
  } = useVideoChatToken(publisherUuid ? publisherUuid : userUuid);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (subConnectionStatus === "connected") {
      const interval = setInterval(() => {
        let loudestSpeaker = 0;
        let loudestSpeakerId;
        vadList.current.forEach((value, key) => {
          if (
            key !== userUuid && // Exclude ourselve
            value >= loudestSpeaker
          ) {
            loudestSpeaker = value;
            loudestSpeakerId = key;
          }
        });

        // We can set this without a check to see if it's changed
        // as a rerender will only be triggered if the speakerId changes
        if (loudestSpeakerId) {
          setActiveSpeaker(loudestSpeakerId);
        }
      }, 200);
      debug("VAD Interval Set", interval);

      return () => {
        debug("Cleaning up VAD interval", interval);
        clearInterval(interval);
      };
    } else if (interval) {
      debug("Clearing VAD interval");
      clearInterval(interval);
    }
  }, [subConnectionStatus, userUuid]);

  return (
    <VideoChatContext.Provider
      value={{
        // Local
        publisherUuid,
        userUuid,
        displayName,
        updateDisplayName,
        isActive,
        setIsActive,
        withAudio,
        setWithAudio,
        isAudioEnabled,
        setIsAudioEnabled,
        withVideo,
        setWithVideo,
        isVideoEnabled,
        setIsVideoEnabled,

        // Remote
        pubConnectionStatus,
        setPubConnectionStatus,
        pubReconnecting,
        setPubReconnecting,
        subConnectionStatus,
        setSubConnectionStatus,
        subReconnecting,
        setSubReconnecting,
        streamName,
        accountId,
        pubTokenId,
        pubToken,
        subTokenId,
        subToken,
        canConnect,
        loadingAccess,

        // Room
        sourceIdList,
        updateSourceIdList,
        vadList,
        activeSpeaker,
        setActiveSpeaker,
        chatCollapsed,
        toggleChatCollapsed,
        members,
        presenceRoom
      }}
    >
      {children}
    </VideoChatContext.Provider>
  );
}

interface Context {
  // Local
  publisherUuid?: string;
  userUuid: string;
  displayName: string;
  updateDisplayName: (nickName: string) => void;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  withAudio: boolean;
  setWithAudio: Dispatch<SetStateAction<boolean>>;
  isAudioEnabled: boolean;
  setIsAudioEnabled: Dispatch<SetStateAction<boolean>>;
  withVideo: boolean;
  setWithVideo: Dispatch<SetStateAction<boolean>>;
  isVideoEnabled: boolean;
  setIsVideoEnabled: Dispatch<SetStateAction<boolean>>;
  pubConnectionStatus: RTCPeerConnectionState;
  setPubConnectionStatus: Dispatch<RTCPeerConnectionState>;
  pubReconnecting: boolean;
  setPubReconnecting: Dispatch<boolean>;
  subConnectionStatus: RTCPeerConnectionState;
  setSubConnectionStatus: Dispatch<RTCPeerConnectionState>;
  subReconnecting: boolean;
  setSubReconnecting: Dispatch<boolean>;

  // Remote
  streamName: string;
  accountId: string;
  pubTokenId: string;
  pubToken: string;
  subTokenId: string;
  subToken: string;
  canConnect: boolean;
  loadingAccess: boolean;

  // Room
  sourceIdList: Map<string, SourceIdItem>;
  vadList: MutableRefObject<Map<string, number>>;
  activeSpeaker?: string;
  setActiveSpeaker: Dispatch<SetStateAction<string | undefined>>;
  updateSourceIdList: Dispatch<SourceIdReducerAction>;
  chatCollapsed: boolean;
  toggleChatCollapsed: () => void;
  members: Members;
  presenceRoom: PresenceChannel;
}

export function useVideoChat() {
  const context = useContext(VideoChatContext);
  if (!context) {
    console.error("No Video Chat Provider Available");
  }
  return context;
}
