import React, { useEffect, useMemo, useState } from "react";
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Debug from "debug";
const debug = Debug("SS:Passes:Dispaly");
import { Grid, Typography, Button } from "@mui/material/";
import formatExpiration from "../../../utils/format_expiration";
import useMaxViewers from "../Hooks/use_max_viewers";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      // padding: theme.spacing(2),
    },
  })
);

export default function DisplayActivePasses(props) {
  const classes = useStyles();
  const maxViewers = useMaxViewers({
    activeSubs: props.activeSubs,
    activePasses: props.activePasses,
  });
  const passViewers = useMaxViewers({
    activeSubs: [],
    activePasses: props.activePasses,
  });
  const [onlineViewers, setOnlineViewers] = useState(0);

  const sessionSubs = useMemo(() => {
    return props.activeSubs.filter(
      (sub) => sub.session_id_attached_to !== "account"
    );
  }, [props.activeSubs]);

  const accountSubs = useMemo(() => {
    return props.activeSubs.filter(
      (sub) => sub.session_id_attached_to === "account"
    );
  }, [props.activeSubs]);

  useEffect(() => {
    if (props.presenceRoom) {
      const handleMemberUpdate = () => {
        let memberCount = 0;
        props.presenceRoom.members?.each((member) => {
          if (
            member.info?.viewerType === "email" ||
            member.info?.viewerType === "web"
          ) {
            memberCount++;
          }
        });
        debug("New Online Viewer Count", memberCount, props.presenceRoom.members);
        setOnlineViewers(memberCount);
      };

      if (props.presenceRoom) {
        // This will set the initial online viewer count
        props.presenceRoom.bind("pusher:subscription_succeeded", handleMemberUpdate);

        // These will update the viewer count over time
        props.presenceRoom.bind("pusher:member_added", handleMemberUpdate);
        props.presenceRoom.bind("pusher:member_removed", handleMemberUpdate);

        return () => {
          props.presenceRoom?.unbind("pusher:subscription_succeeded", handleMemberUpdate);
          props.presenceRoom?.unbind("pusher:member_added", handleMemberUpdate);
          props.presenceRoom?.unbind("pusher:member_removed", handleMemberUpdate);
        };
      }
    }
  }, [props.presenceRoom, props.channel]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No active pass */}
            {props.activeSubs.length === 0 &&
              props.activePasses.length === 0 && (
                <Typography
                  variant="subtitle1"
                  className={props.cssClasses.alertText}
                >
                  NO ACTIVE PASS
                </Typography>
              )}

            {/* Has Active Pass */}
            {(props.activeSubs.length > 0 || props.activePasses.length > 0) && (
              <Typography variant="h6" className={props.cssClasses.alertText}>
                {maxViewers} Viewers (
                <Typography display="inline" color="primary">
                  {onlineViewers} online
                </Typography>
                )
              </Typography>
            )}
            {props.activePasses.length > 0 && (
              <Typography variant="subtitle2">
                {passViewers} Viewers Until{" "}
                {formatExpiration(props.activePasses[0].expires_on)}
              </Typography>
            )}
            <Grid container>
              <Grid flex={1}>
                {sessionSubs.length > 0 && (
                  <Typography variant="subtitle2">
                    {sessionSubs.length > 1
                      ? sessionSubs.length + " Subscriptions"
                      : sessionSubs[0].max_allowed_users + " on Subscription"}
                  </Typography>
                )}
              </Grid>

              <Grid flex={1}>
                {accountSubs.length > 0 && (
                  <Typography variant="subtitle2">
                    {accountSubs.length > 1
                      ? accountSubs.length + "Account Subscriptions"
                      : accountSubs[0].max_allowed_users + " on Account"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No Active Pass */}
            {!props.isBroadcastOnly &&
              props.activeSubs.length === 0 &&
              props.activePasses.length === 0 && (
                <Button
                  onClick={() => props.handleOpen("activate")}
                  variant="contained"
                  color="primary"
                >
                  ACTIVATE PASS
                </Button>
              )}

            {/* Has Active Pass */}
            {!props.isBroadcastOnly &&
              (props.activeSubs.length > 0 ||
                props.activePasses.length > 0) && (
                <Button
                  onClick={() => props.handleOpen("activate")}
                  variant="contained"
                  color="secondary"
                >
                  MANAGE ACCESS
                </Button>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
