import Debug from "debug"; import { createRef, Ref } from "react";
const debug = Debug("SS:ProjectsList");

export interface SourceIdItem {
  sourceId: string,
  audioTrackMids: string[],
  videoTrackMids: string[],
  mediaStream: MediaStream,
}

export enum SourceIdReducerActionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
  CLEAR = "CLEAR"
}

export interface SourceIdReducerAction {
  type: SourceIdReducerActionType,
  sources?: [{
    sourceId: string,
    data?: SourceIdItem
  }]
}

export default function SourceIdReducer(
  sourceIds: Map<string, SourceIdItem>,
  action: SourceIdReducerAction
) {
  switch (action.type) {
    // ADD new items
    case SourceIdReducerActionType.ADD: {
      debug("Adding SourceId to list", action.sources, sourceIds)
      if (action.sources && action.sources.length > 0) {
        for (let i = 0; i < action.sources.length; i++) {
          if (
            action.sources[i] &&
            action.sources[i].sourceId &&
            action.sources[i].data
          ) {
            sourceIds.set(action.sources[i].sourceId, action.sources[i].data!)
          } else {
            throw new Error("No sourceID data to add")
          }
        }
        debug("Updated sourceId list to", sourceIds)
        return new Map(sourceIds)
      } else {
        return sourceIds
      }
    }

    // Remove items
    case SourceIdReducerActionType.REMOVE: {
      debug("Removing SourceId from list", action.sources)
      if (action.sources && action.sources.length > 0) {
        for (let i = 0; i < action.sources.length; i++) {
          const sourceId = action.sources[i].sourceId
          if (!sourceId) {
            throw new Error("No sourceId to remove")
          }

          sourceIds.delete(sourceId)
        }
        return new Map(sourceIds)
      } else {
        return sourceIds
      }
    }

    // Right now this is redundant to ADD
    // It is reserved here for future updates 
    // So logic can be added to selectively apply the update
    // without a major refactoring of the app
    case SourceIdReducerActionType.UPDATE: {
      debug("Updating SourceId in list", action.sources)
      if (action.sources && action.sources.length > 0) {
        for (let i = 0; i < action.sources.length; i++) {
          const sourceId = action.sources[i].sourceId
          const data = action.sources[i].data
          if (!sourceId || !data) {
            throw new Error("No sourceID data to add")
          }
          sourceIds.set(sourceId, data)
        }
        return new Map(sourceIds)
      } else {
        return sourceIds
      }
    }

    case SourceIdReducerActionType.CLEAR: {
      return new Map()
    }

    default:
      return sourceIds
  }
}