import React, {
  ReactNode,
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
  Ref,
  RefObject
} from "react";
import {
  Publish,
  View,
  PublishConnectOptions,
  VideoCodec,
  ViewConnectOptions,
  Logger
} from "@millicast/sdk";
import usePublish from "./Hooks/usePublish";
import useView from "./Hooks/useView";
import { useVideoChat } from "../VideoChatProvider/VideoChatProvider";
import Debug from "debug"; const debug = Debug("SS:VideoChat:MillicastProvider");
import useCamera from "../CameraProvider/useCamera";
import { useSnackbar } from "notistack";
import useEvents from "../Events/useEvents";

interface Props {
  children: ReactNode;
}

export const MillicastContext = React.createContext<Context>(null!);

export function MillicastProvider({
  children
}: Props) {

  const avatarRef = useRef<HTMLCanvasElement>(null)

  const [connecting, setConnecting] = useState<boolean>(false);

  const {
    publisherUuid,
    userUuid,
    pubConnectionStatus,
    setPubConnectionStatus,
    subConnectionStatus,
    setSubConnectionStatus,
    withAudio,
    withVideo,
  } = useVideoChat()

  const {
    audioTrack,
    setAudioTrack,
    videoTrack,
    setVideoTrack,
    videoCodec,
    videoBitrate,
    stopAllMedia,
    supportedVideoCapabilities,
    scalabilityLayers
  } = useCamera()

  const { dispatch } = useEvents()

  const { enqueueSnackbar } = useSnackbar()

  const publish = usePublish()
  const view = useView()

  // Logger.setLevel(Logger.DEBUG)

  // Actions
  const createDummyVideo = () => {

    if (!avatarRef.current) { return }

    const ctx = avatarRef.current.getContext("2d")
    if (!ctx) {
      debug("No avatar canvas context available")
      return
    }

    try {
      const stream = avatarRef.current.captureStream()
      const tracks = stream.getVideoTracks()
      debug("Created dummy video stream", stream, stream.getVideoTracks(), stream.getVideoTracks()[0].readyState)

      if (tracks.length > 0) {
        return tracks[0]
      } else { return }
    } catch (error) {
      debug("Error creating dummy stream", error)
      return
    }
  }

  const createDummyAudio = () => {
    try {
      const context = new AudioContext()
      const osc = context.createOscillator()
      osc.frequency.value = 24000
      const dest = context.createMediaStreamDestination()
      
      osc.connect(dest)
      
      if (dest.stream) {
        const tracks = dest.stream.getAudioTracks()
        debug("Created dummy audio stream", dest.stream, tracks)
        if (tracks.length > 0) {
          return tracks[0]
        } else { return }
      } else { return }
    } catch (error) {
     debug("Error creating dummy audio stream", error) 
     return
    }
  }


  const join = useCallback(async () => {
    try {
      debug("Joining Video Chat audio? %s, video? %s, audioTrack %o, videoTrack %o", withAudio, withVideo, audioTrack, videoTrack, publish);

      setConnecting(true);

      let audio = audioTrack
      let video = videoTrack

      //  TODO: Create dummy audio track
      if (!audio) {
        audio = createDummyAudio()
        // if (audio) { setAudioTrack(audio) }
      }

      if (!video) {
        video = createDummyVideo()
        // if (video) { setVideoTrack(video) }
      }

      // Connect publish
      if (
        publish &&
        (audio || video)
      ) {
        if (!audio && !video) {
          throw new Error("No mediastreamtracks");
        }
        
        const tracks = []
        if (audio && audio.readyState === "live") { tracks.push(audio) }
        if (video && video.readyState === "live") { tracks.push(video) }
        if (tracks.length > 0) {
          debug("Publishing with tracks", tracks)

          const pubOpts: PublishConnectOptions = {
            sourceId: userUuid,
            stereo: false,
            dtx: false, // Test this as true for possible improved experience
            mediaStream: tracks,
            bandwidth: videoBitrate, // in Kbps
            codec: videoCodec as VideoCodec,
            // simulcast: true, // TODO: We may need to verify support for this
            // scalabilityMode: undefined,
            events: ["active", "inactive", "viewercount"],
            // priority: 0
          };
          if (scalabilityLayers && scalabilityLayers.length > 0) {
            pubOpts.scalabilityMode = scalabilityLayers[0]
          }

          try {
            await publish.connect(pubOpts)
            debug("Publish connected")
          } catch (error) {
            
            debug("Error publishing to room", error);
            enqueueSnackbar("We encountered an error while publishing");
            leave()
          }
        } else {
          debug("No tracks to publish with", tracks, audio, video)
        }
      }

      // Connect View
      // TODO: This fails if we are the first viewer in the room
      // as the stream has not finished publishing when we call this
      // It will auto reconnect once the stream publish succeeds
      if (view && !view.isActive()) {
        debug("Viewing Room");
        const viewOpts: ViewConnectOptions = {
          dtx: false, // Test this as true for possible improved experience
          disableVideo: false,
          disableAudio: false,
          // multiplexedAudioTracks: 3, // Test this on multi party calls
          // pinnedSourceId: ,
          excludedSourceIds: [userUuid], // Exclude ourselves
          events: [
            "active",
            "inactive",
            "stopped",
            "vad",
            "layers",
            "migrate",
            "viewercount",
            "updated"
          ],
          // layer: , // Disable for automatic selection from BWE
        };

        try {
          
          await view.connect(viewOpts)
          debug("View connected")
        } catch (error) {
          debug("Viewing connect error", error)
          // throw error
  
          if (error.message === "stream not being published") {
            debug("Stream is not being published")
            enqueueSnackbar("Nobody is in the room right now")
          } else {
            debug("Error joining room", error);
            enqueueSnackbar("We encountered an error.");
          }
        }

      }
    } catch (error: any) {
      debug("Error joining room", error);
      enqueueSnackbar("We encountered an error.");
    } finally {
      setConnecting(false)
    }
  }, [
    subConnectionStatus,
    publish,
    view,
    withAudio,
    withVideo,
    audioTrack,
    videoTrack,
    userUuid,
    videoBitrate,
    videoCodec,
    scalabilityLayers
  ]);

  const leave = useCallback(() => {
    debug("Leaving Chat", pubConnectionStatus, subConnectionStatus, publish?.isActive(), view?.isActive());

    try {
      // Disconnect
      if (publish) {
        debug("Stopping Publish");
        publish.stop();
        setPubConnectionStatus("closed")
      }
      if (view) {
        debug("Stopping View");
        view.stop();
        setSubConnectionStatus("closed")
      }

      // Stop User Media
      stopAllMedia()
    } catch (error) {
      debug("Error disconnecting", error);
      enqueueSnackbar("We encountered an unkown error");
    }
  }, [subConnectionStatus, publish, view, stopAllMedia]);

  const reconnect = useCallback(() => {
    debug("Reconnect");
    try {
      publish?.reconnect().catch(error => { throw error });
      view?.reconnect().catch(error => { throw error });
    } catch (error) {
      debug("Error reconnecting", error);
      leave();
      enqueueSnackbar("We encountered an error.  Please connect again.");
    }
  }, [subConnectionStatus, publish, view]);

  // Effects

  useEffect(() => {
    const handleNewTrack = (event: { track: MediaStreamTrack, streamId: string }) => {
      debug("New Track Event received", event, publish)
      if (!publish) { return }

      const newTrack = event.track
      if (!publish.isActive()) {
        if (view?.isActive()) {
          debug("Viewing but not publishing.  Connecting ....")
          join()
        } else {
          debug("Not connected.  Ignoring new track")
          return
        }
      }

      const peer = publish.webRTCPeer
      if (!peer) {
        debug("No peer to apply new track to")
        return
      }

      const tracks = peer.getTracks()
      const audioTracks = tracks?.filter(track => track.kind === "audio")
      const videoTracks = tracks?.filter(track => track.kind === "video")
      const hasAudio = (audioTracks && audioTracks.length > 0)
      const hasVideo = (videoTracks && videoTracks.length > 0)
      debug("Tracks", hasAudio, hasVideo, audioTracks, videoTracks)

      if (newTrack.kind === "audio" && hasAudio) {
        debug("Replacing audio track")
        peer.replaceTrack(newTrack)
      } else if (newTrack.kind === "video" && hasVideo) {
        debug("Replacing video track", peer, newTrack)
        peer.replaceTrack(newTrack)
      } else {
        // Stop and restart stream?
        debug("Stopping")
        publish.stop()
        debug("Joining again")
        join()
      }
    }

    dispatch?.on("newTrack", handleNewTrack)
    return (() => {
      dispatch?.off('newTrack', handleNewTrack)
    })
  }, [dispatch, publish]);

  useEffect(() => {
    debug("Codec Updated", publish?.isActive(), publish?.options, videoCodec)
    if (publish?.isActive()) {
      // Get current codec
      const curCod = (publish.options as PublishConnectOptions).codec
      if (curCod !== videoCodec) {
        debug("Change codec, disconnecting", curCod, videoCodec)
        publish.stop()
        debug("Reconnecting")
        join()
      }
    }
  }, [videoCodec, publish])

  useEffect(() => {
    try {
      debug("Update video bitrate", publish, videoBitrate)
      if (!publish) { return }
      if (!videoBitrate) { return }
      if (publish.isActive() && publish.webRTCPeer) {
        publish.webRTCPeer.updateBitrate(videoBitrate)
      }
    } catch (error) {
      debug("Error updating video bitrate")
    }
  }, [videoBitrate, publish])

  return (
    <MillicastContext.Provider
      value={{
        publish,
        view,
        join,
        leave,
        reconnect,
        connecting,
        avatarRef
      }}
    >
      {children}
    </MillicastContext.Provider>
  );
}

interface Context {
  publish?: Publish;
  view?: View;
  join: () => Promise<void>;
  leave: () => void;
  reconnect: () => void;
  connecting: boolean;
  avatarRef: RefObject<HTMLCanvasElement>;
}

export function useMillicast() {
  const context = useContext(MillicastContext);
  if (!context) {
    console.error("No Millicast Provider Available");
  }
  return context;
}
