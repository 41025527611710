import { useState, useEffect } from "react"
import { SourceIdItem } from "../Reducers/SourceIdReducer"
import { useVideoChat } from "../VideoChatProvider"
import Debug from "debug"; const debug = Debug("SS:VideoChat:useIsPeerMe");

export default function useIsPeerMe(peer: SourceIdItem) {
  const [isMe, setIsMe] = useState<boolean>(false)

  const {
    userUuid
  } = useVideoChat()

  useEffect(() => {
    if (peer && userUuid && peer.sourceId === userUuid) {
      setIsMe(true)
    } else {
      setIsMe(false)
    }
  }, [peer, userUuid])

  return isMe
}