import React, { useEffect, useState } from 'react';

// requires an array of passes as props

export default function useHasScheduledPass(props) {
  const [hasScheduledPass, setHasScheduledPass] = useState(false);

  useEffect(() => {
    if (props && props.length > 0) {
      let currentlyScheduledPass = props.find(
        pass =>
          (new Date(pass.begins_on) > Date.now() &&
          new Date(pass.expires_on) > Date.now())
      );
      setHasScheduledPass(currentlyScheduledPass ? true : false);
    } else {
      setHasScheduledPass(false);
    }
  }, [props]);

  return hasScheduledPass;
}
