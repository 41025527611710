import { ReactNode } from "react"

import Grid from "@mui/material/Unstable_Grid2/Grid2"

export default function AppBox({ children }: { children: ReactNode }) {

  //  Layout flexbox 
  //   Switch direction on landscape mobile to move app bar and chat
  //   TODO: Create isMobileLandscape hook
  //
  return (
    < Grid
      container
      direction="column"
      flexWrap="nowrap"
      sx={{
        height: "100%",
        overflow: "hidden"
      }}
      id="BodyBox"
    >
      {children}
    </Grid >
  )
}