import Debug from "debug"; const debug = Debug("SS:JoinScreen:MyVideoPreview");
import { useEffect, useRef } from "react";
import { Card, CardMedia, Skeleton } from "@mui/material/";
import useCamera from "../../../Context/CameraProvider/useCamera";
import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";
import { FaUser } from "react-icons/fa";
import PeerAvatar from "../../PeerAvatar/PeerAvatar";

export default function MyVideoPreview() {
  // State
  const ref = useRef<HTMLVideoElement>(null);

  const { withVideo } = useVideoChat();
  const { videoTrack } = useCamera();

  useEffect(() => {
    if (videoTrack && ref.current) {
      ref.current.srcObject = new MediaStream([videoTrack])
      debug("Setting srcObject with video tracks", videoTrack, ref.current.srcObject)
    } else if (!videoTrack && ref.current) {
      debug("No Video Track, nulling srcObject")
      ref.current.srcObject = null;
    }

    return () => {
      if (ref.current) {
        debug("Cleaning up, nulling srcObject")
        ref.current.srcObject = null;
      }
    }
  }, [videoTrack]);

  // Render
  return (
    <Card
      elevation={4}
      sx={{
        width: "100px",
        height: "100px",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        backgroundColor: "background.dark",
      }}
    >
      {videoTrack && withVideo && (
        <CardMedia
          component="video"
          ref={ref}
          autoPlay={true}
          playsInline
          loop={true}
          controls={false}
          muted={true}
          sx={{
            height: 1,
            width: "auto",
          }}
        />
      )}

      {!videoTrack && withVideo && (
        <PeerAvatar />
      )}

      {!withVideo && (
        <PeerAvatar />
      )}
    </Card>
  );
}
