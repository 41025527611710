import { ReactNode } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMediaQuery } from "@mui/material";

/**
 * This component should always be used inside an AppBox
 * Enclose children in <Grid> from Grid2
 * @returns
 */
export default function AppBottomBar({
  isVCOpen,
  children,
}: {
  isVCOpen: boolean;
  children: ReactNode;
}) {
  const isLandscape = useMediaQuery("(orientation:landscape)")

  return (
    <Grid
      sx={{
        width: {
          xs: isLandscape ? (isVCOpen ? "192px" : "128px") : "100%",
          md: "100%"
        },
        height: {
          xs: isLandscape ? "100%" : (isVCOpen ? "192px" : "96px"),
          md: isVCOpen ? "192px" : "96px"
        },
        marginTop: {
          xs: isLandscape ? 0 : 2,
          md: 2 
        },
        marginLeft: {
          xs: isLandscape ? 2 : 0,
          md: 0
        },
        borderRadius: {
          xs: 0,
          sm: 2,
          md: 4,
        },
        borderTop: {
          xs: isLandscape ? "0px" : "1px solid",
          md: "1px solid"
        },
        borderLeft: {
          xs: isLandscape ? "2px solid" : "0px",
          md: "0px"
        },
        borderColor: {
          xs: "secondary.dark",
          md: "secondary.dark"
        },
        overflow: "hidden",
      }}
    >
      <Grid
        sx={{
          flex: 0,
          height: {
            xs: isLandscape ? "100%" : (isVCOpen ? "192px" : "96px"),
            md: isVCOpen ? "192px" : "96px"
          },
          width: "100%",
          overflow: "hidden",
        }}
        id="AppBottomBar"
      >
        {children}
      </Grid>
    </Grid>
  );
}
