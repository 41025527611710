import { useState, useEffect } from "react"
import useEvents from "../../Events/useEvents"

export default function useAudioTracks(stream?: MediaStream) {
  const [tracks, setTracks] = useState<MediaStreamTrack[]>([])
  const { dispatch } = useEvents()

  useEffect(() => {
    if (!stream) { return }

    const aTracks = stream.getAudioTracks()
    setTracks(aTracks)

    const handleNewTrack = ({ track }: { track: MediaStreamTrack }) => {
      if (track.kind === "audio") {
        setTracks((prev) => prev.concat(track))
      }
    }

    const handleEnded = () => {
      // Sent when playback of the track ends (when the value readyState changes to ended), except when the track is ended by calling MediaStreamTrack.stop.
    };
    const handleMute = () => {
      // Sent to the MediaStreamTrack when the value of the muted property is changed to true, indicating that the track is unable to provide data temporarily (such as when the network is experiencing a service malfunction).
    };
    const handleUnmute = () => {
      // Sent to the track when data becomes available again, ending the muted state.
    };


    dispatch.on("newTrack", handleNewTrack)
    if (aTracks.length > 0) {
      aTracks[0].addEventListener("ended", handleEnded);
      aTracks[0].addEventListener("mute", handleMute);
      aTracks[0].addEventListener("unmute", handleUnmute);
    }

    return () => {
      dispatch.off("newTrack", handleNewTrack)
      if (aTracks[0]) {
        aTracks[0].removeEventListener("ended", handleEnded);
        aTracks[0].removeEventListener("mute", handleMute);
        aTracks[0].removeEventListener("unmute", handleUnmute);
      }
    }
  }, [stream, dispatch])

  return tracks
}