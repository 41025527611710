import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
LinearProgress,
Button
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import Pusher from 'pusher-js';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  centerBox: {
    position: "absolute",
    top: "40%",
    left: 0,
    width: "100%"
  },
  centerContent: {
    backgroundColor: "rgb(31,33,37,0.5)",
    margin: "auto",
    maxWidth: "500px",
    padding: theme.spacing(24),
    borderRadius: theme.spacing(12),
    textAlign: "center"
  },
  progress: {
    // width: "40%",
    margin: "auto",
    padding: theme.spacing(14)
  },
  videoBox: {
    opacity: 0.1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  video: {
    margin: "auto"
  }
}));

export default function InWaitingRoom(props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const startOver = () => {
    props.setIsWaiting(false);
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid className={classes.videoBox}>
        <video
          muted
          autoPlay
          controls={false}
          loop
          playsInline
          width="100%"
          height="100%"
          className={classes.video}
        >
          <source
            src="/videos/BrandLoop_Logo.mp4"
            type="video/mp4"
          />
        </video>
      </Grid>

      <Grid className={classes.centerBox}>
        <Grid className={classes.centerContent}>
          <Typography variant='h4'>
            Welcome {localStorage.getItem("ss_nickname")}
          </Typography>
          
          <br />
          
          <Typography
            variant='h5'
          >
            We are waiting for your request to be approved.  Please standby.
          </Typography>

          <Grid className={classes.progress}>
            <LinearProgress variant="indeterminate" color='primary' />
          </Grid>

          <Grid className={classes.progress}>
            <Typography variant='subtitle2'>
              Request ID: {props.user_uuid}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

}
